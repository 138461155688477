import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  MatchFragment,
  MatchSourceTypeEnum,
} from '../../../../graphql/generated';
import { platformIcons } from '../../../constants/platformIcons.constants';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { I18NextModule } from 'angular-i18next';

@Component({
  standalone: true,
  selector: 'app-match-source',
  templateUrl: './match-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, I18NextModule, NgClass, NgIf],
})
export class MatchSourceComponent {
  match = input.required<MatchFragment>();
  protected readonly platformsIconMap = platformIcons;
  protected readonly MatchSourceTypeEnum = MatchSourceTypeEnum;
}
