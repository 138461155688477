import { Component, input, output } from '@angular/core';
import {
  ButtonSolidComponent,
  SimpleSelectComponent,
  TagComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-overlay-wrapper',
  standalone: true,
  imports: [
    ButtonSolidComponent,
    I18NextModule,
    NgIf,
    SimpleSelectComponent,
    TagComponent,
  ],
  templateUrl: './overlay-wrapper.component.html',
  styleUrl: './overlay-wrapper.component.scss',
})
export class OverlayWrapperComponent {
  open = input.required<boolean>();
  close = output<boolean>();

  closeOverlay() {
    this.close.emit(true);
  }
}
