import { MatchArchiveReasonEnum } from '@libs/shared/enums/MatchArchiveReason.enum';
import i18next from 'i18next';

export const getMatchArchiveReasonsTextOptions = () => {
  return [
    {
      value: MatchArchiveReasonEnum.WE_CANCELLED,
      label: i18next.t('MatchArchiveReasonEnum.WE_CANCELLED') + ' 🫣',
    },
    {
      value: MatchArchiveReasonEnum.CLIENT_CANCELLED,
      label: i18next.t('MatchArchiveReasonEnum.CLIENT_CANCELLED') + ' 😭',
    },
    {
      value: MatchArchiveReasonEnum.TALENT_CANCELLED,
      label: i18next.t('MatchArchiveReasonEnum.TALENT_CANCELLED') + ' 😢',
    },
    {
      value: MatchArchiveReasonEnum.WE_FOUND_PLACEMENT,
      label: i18next.t('MatchArchiveReasonEnum.WE_FOUND_PLACEMENT') + ' 🤩',
    },
    {
      value: MatchArchiveReasonEnum.INCORRECTLY_RECORDED_OR_DUPLICATE,
      label:
        i18next.t('MatchArchiveReasonEnum.INCORRECTLY_RECORDED_OR_DUPLICATE') +
        ' 🤪',
    },
    // removed "for now"
    // {
    //   value: MatchArchiveReasonEnum.TALENT_DELETED,
    //   label: i18next.t('MatchArchiveReasonEnum.TALENT_DELETED') + ' 🫥',
    // },
    {
      value: MatchArchiveReasonEnum.OTHER,
      label: i18next.t('MatchArchiveReasonEnum.OTHER'),
    },
  ] as const;
};
