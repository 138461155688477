{
  "13thPaycheck": "13th month wage",
  "1DayToApprove": "1 day to approve",
  "1DayToTransfer": "1 day to transfer",
  "absence": "absence",
  "absenceTimespan": "Absence period",
  "absent": "Absence",
  "acceptedFileTypes": "png, jpg, pdf, doc, max. 2MB",
  "acceptTermsAndConditions": "I agree with the privacy policy",
  "accident": "Accident",
  "actions": "Actions",
  "active": "Active",
  "activeEmployees": "Active Employees",
  "activeEmployments": "Active Employments",
  "activeLimited": "Active from {{ from }} ({{ fromDays }}) to {{ until }} ({{ untilDays }})",
  "activeRuntime": "Active duration {{ from }} - {{ until }}",
  "activeSince": "Active since {{ from }} ({{ fromDays }})",
  "activeTill": "Active until {{ until }} ({{ untilDays }})",
  "addAddon": "Add add-on",
  "addAttachment": "Add Attachment",
  "addEntry": "add Entry",
  "addHoursRowNote": "Cost center or other remark",
  "addLanguage": "Add language",
  "addNote": "Add note",
  "addOn": "Add-on",
  "addOnSearch": "Add-on: Search",
  "addOnSearchMax": "Max",
  "addOnSearchPush": "Push",
  "addOnSearchTarget": "Target",
  "addReceipt": "Add receipt",
  "addRemarksLikeKeywords": "Add remarks such as special keywords, etc.",
  "address": "Address",
  "addTab": "Add tab",
  "adjustedBy": "Adjusted by",
  "adjustment": "Adjustment",
  "adjustmentNote": "Hint about the correction",
  "adjustSearchOrFilter": "Adjust your search/filter inputs or reset them.",
  "adjustSearchOrResetFilter": "Adjust the search / filter entries or reset them.",
  "advertisement": "Advertisement",
  "after": "After",
  "age": "Age",
  "ahvCard": "AHV card",
  "ahvNumber": "Social security number",
  "all": "All",
  "allBranches": "All branches",
  "allTalents": "All talents",
  "allRequiredFieldsAreFilled": "All necessary fields are filled",
  "allVacancies": "All vacancies",
  "alreadyImported": "already imported",
  "amountDaysWithoutHoursEntries": "Number of days without entries",
  "amountOfAbsences": "Number of absences",
  "applicationFormLink": "Application form link",
  "apply": "Apply",
  "approve": "approve",
  "approved": "approved",
  "approveDay": "approve day",
  "approveDays": "approve days",
  "approveDaysConfirmation": "Do you want to share entries?",
  "approveDaysHint": "After that the days can no longer be corrected",
  "archiveReason": "Reason for archiving",
  "areAllEntriesChecked": "Are all entries of this week checked?",
  "areAllEntriesComplete": "Are all entries of this week complete?",
  "areYouSure": "Are you sure?",
  "ascending": "Ascending",
  "assignment": "Contract",
  "attachment": "Attachment",
  "attention": "Attention",
  "authenticate": "Authenticate",
  "authentication": "Authentication",
  "autoDetectedAfterTheVacancyIsPublished": "Automatically detected after the vacancy is published.",
  "bankAccountNumber": "Account number",
  "bankingInfo": "Banking information",
  "bankingInfoUsageHint": "Your bank information will be required for payroll disbursement.",
  "before": "Before",
  "beingCompressed": "Being compressed...",
  "beingUploaded": "Being uploaded...",
  "benefit": "Benefit",
  "benefits": "Benefits",
  "billing": "Billing",
  "branch": "Branch",
  "branchVacancies": "Vacancies branch",
  "bulkEdit": "Multiple editing",
  "byArrangement": "by Arrangement",
  "byDate": "by Date",
  "calendarWeekX": "CW {{week}}",
  "call": "Call",
  "cancel": "Cancel",
  "talents": "Talents",
  "cannotBeCombined": "Currently, add-ons cannot be combined.",
  "changeCanNotBeRevertedAsContentIsIdentical": "The change cannot be reverted as it is identical to the current content.",
  "changeHistory": "Change history",
  "changelogActions": {
    "activated": "activated",
    "added": "added",
    "changed": "changed",
    "created": "created",
    "deactivated": "deactivated",
    "removed": "removed",
    "restored": "restored"
  },
  "changelogStatus": {
    "ARCHIVED": "archived <span class='text-m-b'>{{entryName}}</span>",
    "DELETED": "deleted <span class='text-m-b'>{{entryName}}</span>",
    "DRAFT": "created <span class='text-m-b'>{{entryName}}</span>",
    "LIVE": "published <span class='text-m-b'>{{entryName}}</span>",
    "SCHEDULED": "scheduled <span class='text-m-b'>{{entryName}}</span>"
  },
  "changelogHubBotActions": {
    "activated": "activated <span class='text-m-b'>{{entryName}}</span>",
    "added": "added <span class='text-m-b'>{{entryName}}</span>",
    "changed": "changed <span class='text-m-b'>{{entryName}}</span>",
    "created": "created <span class='text-m-b'>{{entryName}}</span>",
    "deactivated": "deactivated <span class='text-m-b'>{{entryName}}</span>",
    "removed": "removed <span class='text-m-b'>{{entryName}}</span>",
    "scheduled": "scheduled <span class='text-m-b'>{{entryName}}</span>"
  },
  "changePicture": "Change image",
  "changeReverted": "Change reset",
  "changes": "Changes",
  "changesApply": "Apply",
  "changeSelection": "Change",
  "changesPublished": "Changes published",
  "changeVacancy": "Change vacancy",
  "changeXVacancies": "Change {{count}} vacancies",
  "character": "Characters",
  "charged": "Charged",
  "checklist": "Checklist",
  "checkYourInput": "Check your input",
  "checkYourSearchInput": "Check your search input.",
  "choose": "Select",
  "chooseAbsence": "Select absence",
  "chooseAPlan": "Choose an plan",
  "chooseCompanyContact": "Choose contact",
  "chooseCompanyLocation": "Choose company/location",
  "chooseDate": "Select date",
  "chooseDateRange": "Select period",
  "chooseDocument": "Choose document",
  "chooseEmployment": "Choose employment",
  "chooseFile": "Select file",
  "chooseFiles": "Select files",
  "chooseFileType": "Select file type",
  "chooseFunction": "Choose function",
  "chooseType": "Select type",
  "city": "City",
  "civilOrigin": "Place of citizenship",
  "clearSearchHistory": "Clear search history",
  "close": "Close",
  "commission": "Commission",
  "commissionOverLimit": "Commission over limit",
  "commissionSplitDoesNotAddUpToTotal": "The sum of the entries does not match the commission",
  "commissionTotalWithoutTax": "Total without tax",
  "companies": "Companies",
  "company": "Company",
  "companyCreated": "Company created!",
  "companyDeleted": "Company deleted!",
  "companyDuplicatedSuccessfully": "Company successfully duplicated!",
  "companyLocation": "Company",
  "companySavedSuccessfully": "Company saved",
  "companySheetAlreadyOpen": "One company is already being edited",
  "companyShortName": "Company name (short form)",
  "confirmationEmailSent": "We have sent a confirmation email to <b>{{email}}</b>.",
  "confirmDelete": "Confirm deletion",
  "confirmDeleteTab": "Do you really want to delete the tab?",
  "confirmWithEnter": "Confirm with Enter ↵",
  "contact": "Contact",
  "contactOnTeams": "Teams",
  "contactPerson": "Contact Person",
  "contacts": "Contacts",
  "contactUs": "Contact us",
  "copy": "Copy",
  "copyLink": "Copy link",
  "copyToClipboard": "Copy to clipboard",
  "cost": "Cost",
  "country": "Country",
  "createAbsence": "Record absence",
  "createdAt": "Created at",
  "createNewUser": "Create new user",
  "createUser": "Create user",
  "creationDate": "Creation date",
  "criteria": "Criteria",
  "cropProfileImage": "Crop profile picture",
  "currentVersion": "Current version",
  "customer": "Customer",
  "dashboard": "Overview",
  "date": "Datum",
  "dateOfBirth": "Date of birth",
  "dayApprove": "Day approvals",
  "days": "days",
  "daySort": "sort days",
  "delete": "Delete",
  "deleteAll": "delete all",
  "deleteEntry": "delete entry",
  "deleteVacancy": "Delete vacancy?",
  "deleteVacancyConfirmationHint": "Do you really want to delete the vacancy?",
  "descending": "Descending",
  "description": "Description",
  "descriptionAndTitle": "Description / Title",
  "desiredAge": "Desired age",
  "details": "Details",
  "deviatingRecipient": "Deviating recipient",
  "directions": "Directions",
  "discardChanges": "Discard",
  "displayAbsence": "show absence",
  "displayInJobAdvertisement": "show in job ad",
  "displayReport": "show report",
  "documents": "Documents",
  "documentType": "Document type",
  "doNotUseEmailOrPhone": "Do not use email addresses or contact details",
  "doYouWantToRevertThisChange": "Do you want to reset this change?",
  "draft": "Draft",
  "driversLicense": "Driver's license",
  "dropDocumentHere": "Drop the document here",
  "dropDocumentsHere": "Drop documents here",
  "duplicate": "Duplicate",
  "duplicateVacancy": "Duplicate vacancy",
  "duration": "Duration",
  "edit": "Edit",
  "editAbsence": "edit absence",
  "editColumns": "Edit columns",
  "editCompany": "edit company",
  "editNote": "Edit note",
  "editReport": "edit report",
  "editTable": "Edit table",
  "editTabs": "Edit tabs",
  "editVacancies": "Edit vacancies",
  "editVacancy": "Edit vacancy",
  "editXCompanies": "edit {{amount}} companies",
  "editXVacancies": "edit {{amount}} vacancies",
  "education": "Education",
  "email": "email",
  "emailAddress": "Email address",
  "emailIsNowVerified": "Thank you, the email {{email}} is now verified",
  "emailSent": "Email sent",
  "employee": "Employee",
  "employeeResponsibility": "Responsibility",
  "employeeResponsibilityHint": "You will receive notifications only for the selected employees.",
  "employees": "Employees",
  "employment": "Employment",
  "employments": "Employments",
  "employmentSaved": "Employment saved",
  "emptyEmployeeData": "No data has been transferred yet",
  "emptyOption": "Empty option",
  "emptyTemplate": "Blank template",
  "emptyVacancy": "Empty vacancy",
  "end": "End",
  "endVacancy": "End vacancy",
  "english": "English",
  "enoughApplicantsReceived": "Enough applicants received",
  "enterAmount": "Enter amount",
  "enterJobDescription": "Enter job description",
  "enterReport": "upload report",
  "enterSearchTerm": "Enter search term",
  "enterUrl": "Enter URL",
  "enterWorkloadPercentage": "Enter workload percentage",
  "entries": "Entries",
  "entryDeleteConfirmation": "delete entry?",
  "entryDeleted": "Entry deleted",
  "entryDeleteHint": "The entry can not be restored.",
  "entryRemoved": "Entry removed",
  "error_description": {
    "accessExpired": "Unfortunately, the link used has expired",
    "accessExpiredHint": "Please click on login and then on Forgot Password to receive a new link",
    "linkOnlyValidOnce": "This link is only valid once",
    "linkOnlyValidOnceHint": "Please use your existing login data to sign in"
  },
  "publishExactJobLocationInsteadOfReplacement": "Use exact location instead of replacement",
  "publishExactJobLocation": "Use exact location",
  "existingCompanies": "Existing companies",
  "existingVacancies": "existing vacancies",
  "expenses": "Expenses",
  "export": "Export",
  "extendSearch": "Extend search",
  "fieldRequired": "Please fill in this mandatory field",
  "fieldsRequired": "Please fill in all required fields",
  "file": "File",
  "fileDeleteConfirmation": "Delete file?",
  "fileDeleteHint": "The file cannot be restored.",
  "fileRemoved": "File removed",
  "fileToLargeError": "File too large",
  "fileTooBig": "The file exceeds the upload limit of 5 Mb",
  "fileType": "File type",
  "fileUploadComplete": "File uploaded successfully",
  "filter": "Filter",
  "finishedRuntime": "Finished, duration {{ from }} - {{ until }}",
  "firstName": "First name",
  "french": "French",
  "from": "from",
  "fromValueCanNotBeGreaterThanToValue": "The 'from' value cannot be greater than the 'to' value",
  "galaxusBenefitDescription": "Galaxus Mobile mobile phone subscriptions: unlimited data for only <span class='text-l-b'> CHF 15 per month.</span> Save <span class='text-l-b'>  CHF 1 per subscription </span> for each family member. <br /> </br> Use our company code and subscribe now.",
  "generalError": "Error",
  "generalErrorText": "An unknown error has occurred. We apologize for this.",
  "german": "German",
  "goodDay": "Good Afternoon",
  "goodEvening": "Good Evening",
  "goodMorning": "Good Morning",
  "gotoLogin": "To the login",
  "great": "Great!",
  "hasAccountQuestion": "Already have an account?",
  "hasAllowedDataUsageAt": "Privacy policy accepted",
  "hasHandWrittenHours": "The hours handwritten recorded?",
  "hasNotBeenActivated": "Has not been activated",
  "hasNotBeenPublished": "Has not been published",
  "help": "help",
  "hideLocation": "Hide location",
  "hideSalary": "Salary hidden in the advertisement",
  "history": "History",
  "historyEntriesWillBeDisplayHere": "Changes will be displayed here.",
  "hour": "Hour",
  "hours": "hours",
  "hoursToBeApproved": "Hours to be approved",
  "iban": "IBAN",
  "imageCompressionError": "Image compression failed.",
  "immediately": "immediately",
  "import": "Import",
  "importCompany": "Import company",
  "importCompanyInstruction": "To add new companies or modify existing ones, the old UniBase must still be used.<br />The adjustments will be synchronized.",
  "importContact": "Import contact",
  "importContactInstruction": "To add new contacts or modify existing ones, the old UniBase must still be used.<br />The adjustments will be synchronized.",
  "iMyself": "Me",
  "inactive": "Inactive",
  "inactiveEmployees": "Inactive Employees",
  "inactiveEmployments": "Past Employments",
  "inactiveRuntime": "Inactive, duration {{ from }} - {{ until }}",
  "inactiveSince": "Inactive since {{ from }} ({{ fromDays }})",
  "info": "Info",
  "initialVacancyActivation": "Initial vacancy activation",
  "inputHours": "Enter hours",
  "intern": "Intern",
  "internalChannels": "Internal channels",
  "internNoteExample": "e.g. client wishes for a male talent",
  "invalidAccountOrCode": "Invalid account or code",
  "invalidEmail": "Please check your email address",
  "invalidHoursRow": "Please check the hours",
  "invalidIBAN": "Please check your IBAN",
  "invalidPhone": "Please check your phone number",
  "invalidTime": "Invalid time",
  "isoWeek": "CW",
  "italian": "Italian",
  "job": "Occupation",
  "JobAdvert": "Job advertisement",
  "jobCompany": "Company",
  "jobLocationAddressReplacement": "Work region",
  "jobNotificationRequired": "Job notification required",
  "jobTitle": "Job title",
  "jobTitleShown": "Displayed job title",
  "jobTitleShownDescription": "This title will be displayed in the advertisement.",
  "just_now": "Just now",
  "language": "Language",
  "languageRequirements": "Languages",
  "languages": "Languages",
  "last_changes_at": "Last change on {{timePast}}",
  "last_changes_hours_ago": "Last change {{timePast}} h ago",
  "last_changes_hours_ago_short": "{{timePast}} h ago",
  "last_changes_minutes_ago": "Last change {{timePast}} min ago",
  "last_changes_minutes_ago_short": "{{timePast}} min ago",
  "saving": "saving...",
  "lastName": "Last name",
  "lastUpdated": "Last updated",
  "lastUpdatedAt": "Last updated",
  "leadershipPosition": "Leadership position",
  "linkCopied": "Link copied",
  "linkInvalid": "Link invalid",
  "linkInvalidText": "The link used is invalid. Either the password has already been changed or the link has expired.",
  "linkToAdvert": "Link to advertisement",
  "linkToVacancy": "Link to vacancy",
  "list": "List",
  "location": "Location",
  "loggingIn": "Signing in...",
  "loggingOut": "Signing out...",
  "login": "Login",
  "logout": "Sign out",
  "manageEntries": "Manage entries",
  "manualSlots": "Manual slots",
  "personalSlots": "Personal slots",
  "manualSlotsInfoText": "Here you will find the links for the job platforms with manual slots.",
  "personalSlotsInfoText": "Here you will find the links to post them on your personal channels.",
  "externalChannels": "External channels",
  "maritalStatus": "Marital status",
  "MAX": "Max",
  "maxLengthCharacters": "This field can contain a maximum of {{max}} characters",
  "maxPlanTooltip": "Reach and targeted audience targeting",
  "maxValue": "Max value",
  "me": "Me",
  "microsoftTeams": "Open Teams",
  "minLengthCharacters": "This field must contain at least {{min}} characters",
  "minutes": "Minutes",
  "minutesShort": "min",
  "minValue": "Min value",
  "mipDuration": "MIP Duration",
  "missingAbsenceDuration": "Please indicate duration of absence",
  "missingAbsenceType": "Please select absence type",
  "missingAdjustmentDuration": "Please specify the value of the correction",
  "missingDuration": "Please specify duration.",
  "missingExpenseCost": "Please enter amount",
  "missingExpenseNote": "Please provide a description of the expenses",
  "missingFromTime": "Please enter start time",
  "missingToTime": "Please enter end time",
  "moneyAmount": "Amount",
  "month": "Month",
  "moreActions": "More actions",
  "multiEditFailed": "The vacancies could not be edited.",
  "mustServeMilitaryService": "Obligated for military service",
  "myVacancies": "My vacancies",
  "name": "Name",
  "nationality": "Nationality",
  "negativeDuration": "The duration of work in one day must not be negative.",
  "new": "New",
  "newCompany": "New company",
  "newContact": "New contact",
  "newDocuments": "New documents",
  "newVacancy": "New vacancy",
  "newTalent": "New talent",
  "nextMilitaryService": "Next military service",
  "nextPaymentIntendedAt": "next payment intended at",
  "no": "No",
  "noAbsenceTypeSelected": "Please choose an absence type",
  "noAccountQuestion": "No account yet?",
  "noChangesFound": "No changes found",
  "noCompanyFound": "Unfortunately, no company found",
  "unfortunatelyNoCompanyFound": "Unfortunately, no company found",
  "checkSearchInputsOrImportCompany": "Check your search inputs or import the company from the old UniBase.",
  "noContactFound": "Unfortunately, no contact found",
  "noDataSelected": "There is no data to be transferred in the Selected period",
  "noDataToTransfer": "There is currently no data to submit.",
  "noDocumentsFound": "There are no documents available",
  "noDocumentUploaded": "No document uploaded",
  "noEmailRecievedQuestion": "Did not receive an email?",
  "noEmployeeFound": "Unfortunately, we couldn't find any employees",
  "noEmployeesDisplayed": "No entries are displayed. If necessary, adjust the filter at \"Responsibility\".",
  "noEmployments": "No Employments available",
  "noEmploymentsFound": "Unfortunately, we couldn't find any employments.",
  "noEntries": "No Entries",
  "noEntriesInSearchHistory": "No entries in search history",
  "noJobNotificationRequired": "No job notification required",
  "nonCompeteAgreement": "Non-compete agreement",
  "noNegativeCommission": "No negative commission allowed",
  "noNewEmploymentsOrDocuments": "There are currently no active deployments or new documents.",
  "noNews": "No news.",
  "noReceiptUploaded": "No receipt uploaded",
  "noReportSelected": "please enter timespan and uploade report",
  "noSelectOptions": "No selection options",
  "note": "Note",
  "notes": "Notes",
  "nothingToDo": "There are no pending matters at the moment.",
  "noTimespanSelected": "Please select period",
  "online": "online",
  "onlyAfterPublishing": "Add-ons can only be added after the vacancy is published.",
  "oops": "Oops",
  "open": "open",
  "openHours": "open hours",
  "openMap": "Open map",
  "or": "or",
  "orderWithCosts": "Order with costs",
  "originalAd": "Original advertisement",
  "others": "Other",
  "overlappingHoursRows": "The hour entries of a day must not overlap",
  "own": "Own",
  "pageNotFound": "Unfortunately, we cannot find the page you are looking for.",
  "paid": "paid",
  "participate-now": "Take part now",
  "partTime": "Part time",
  "past": "Past",
  "pause": "Break",
  "paySlips": "Payroll",
  "permanentPosition": "Permanent position",
  "personalDetails": "Personal data",
  "personalDocuments": "Personal documents",
  "phone": "Phone number",
  "phoneNumber": "phone number",
  "phoneNumberMustStartWith+": "Please enter the area code of the telephone number (e.g., +41)",
  "placeOfBirth": "Place of birth",
  "platformLinks": "Platform links",
  "platformLinksOnlyAvailableForLiveVacancies": "Platform links are only available after the vacancy is published",
  "platforms": "Platforms",
  "pleaseFillAllTheRequiredFields": "Please fill all the required fields.",
  "pleaseCheckTheFollowingFields": "Please check the following fields!",
  "pleaseFillJobTitleFirst": "Please fill in job title first.",
  "pleaseResolveErrorsInOrderToSave": "Please resolve errors in order to save.",
  "pleaseSafeBeforeAddingAbsence": "Please save your changes before recording an absence",
  "pleaseSafeBeforeAddingExpenses": "Please save your changes before adding expenses",
  "pleaseSafeBeforeEditingAbsence": "Please save your changes before editing an absence",
  "pleaseSafeBeforeEditingExpenses": "Please save your changes before editing expenses",
  "pleaseSafeBeforeReportUpload": "Please save your changes before uploading a report",
  "pleaseSafeBeforeTransfer": "Please save your changes before submitting data",
  "pleaseSafeEntryBeforeUpload": "Please save the entry before uploading",
  "pleaseSelectDocumentType": "Please select a document type.",
  "pleaseSelectOneOfTheFollowingReasons": "Please select one of the following reasons.",
  "pleaseStandBy": "Please be patient for a moment.",
  "pleaseVerifyYourEmail": "Please verify your email address.",
  "politePleaseSafeBeforeAcceptingHours": "Please save the changes before you approve hours",
  "politePleaseSafeBeforeAcceptingReport": "Please save the changes before you approve the report",
  "politePleaseSafeBeforeRejectingHours": "Please save the changes before rejecting hours",
  "politePleaseSafeBeforeReportUpload": "Please save the changes before uploading a report",
  "position": "Position",
  "possiblyOther": "others if necessary",
  "possiblyPermit": "Approval if necessary",
  "preferredEmploymentType": "Preferred employment type",
  "preferredLocationRadiusKm": "Maximum distance",
  "preferredMaxCommutingTimeMinutes": "Maximum commuting time",
  "preferredWorkArrangement": "Preferred work arrangement",
  "prefersCommutingByBicycle": "Bicycle",
  "prefersCommutingByCar": "Car",
  "prefersCommutingByPublicTransport": "Public transport",
  "presentation": "representation",
  "preview": "Preview",
  "primaryAddressLocation": "Location",
  "primaryAddressStreet": "Street",
  "primaryAddressZip": "Postal code",
  "primaryEmail": "Primary email address",
  "primaryPhone": "Primary phone number",
  "professionalTitle": "Professional title",
  "profile": "Profile",
  "profileImageRemoved": "Profile picture removed",
  "profileImageSaved": "Profile picture saved",
  "promos": "Promotions",
  "promote": "Promote",
  "publicationChanged": "Last published",
  "publicationChangedAt": "Last published on",
  "publish": "Publish",
  "publishChanges": "Publish changes",
  "publishChangesInDays": "Publish in {{days}} days",
  "publishVacancy": "Publish vacancy",
  "publishVacancyConfirmationHint": "Do you really want to publish the vacancy?",
  "publishVacancyInDays": "Publish in {{days}} days",
  "PUSH": "Push",
  "pushPlanTooltip": "Placement on all relevant job platforms",
  "qualifications": "Qualifications",
  "readDocuments": "Documents read",
  "readyToPublish": "Ready to go live?",
  "reason": "Reason",
  "reasonForArchiving": "Reason for archiving",
  "redirectingToLogin": "Redirecting to login...",
  "referenceNumber": "Reference number",
  "register": "register",
  "registeredWithRav": "Registered with RAV",
  "registration": "Registration",
  "registrationCompleted": "Your registration has been successfully completed.",
  "reject": "Reject",
  "rejectDay": "reject day",
  "rejectDays": "reject days",
  "rejectDaysHint": "Do you want to specify a reason?",
  "rejectDaysReasonPlaceholder": "add reject reason",
  "rejected": "rejected",
  "rejectedInfoAt": "at",
  "rejectedInfoFrom": "rejected by",
  "religiousDenomination": "Religious denomination",
  "remark": "Remark",
  "remarksForPublishing": "Remarks for publishing",
  "removePicture": "Remove image",
  "rename": "Rename",
  "replace": "Replace",
  "report": "Report",
  "reportIsTransmittedDirectly": "Report is transmitted directly",
  "reportIsTransmittedDirectlyMessage": "Attention, the report is transmitted directly. This action cannot be undone. To make changes, you need to contact your advisor under 'Contacts'.",
  "reportState": "Data via report",
  "reportTimespan": "Rapport period",
  "resendEmail": "Send email again",
  "resendSms": "Send SMS again",
  "resetSearchOrFilter": "Reset search / filter",
  "residencePermit": "Residence permit",
  "residencePermitValidUntil": "Residence permit valid until",
  "responsibleContact": "Responsible contact",
  "responsibleEmployee": "Responsible Employee",
  "restore": "Restore",
  "restoredSuccessfully": "Successfully restored",
  "restoreHint": "Do you really want to restore the {{version}} verison? Later versions will be lost!",
  "restoreVersion": "Restore version",
  "revert": "Reset",
  "revertChange": "Reset change",
  "sadlyWeDidntFindVacancies": "Sadly, we didn't find any vacancies 🙈",
  "salary": "Salary",
  "salaryCanNotBeZero": "Salary cannot be empty or 0",
  "salaryCurrency": "Currency",
  "salaryExpectations": "Salary expectations",
  "salaryFix": "Fixed salary",
  "salaryHoursPerWeek": "Hours per week",
  "publishSalary": "Publish salary",
  "salaryPaymentsPerYear": "Payments per year",
  "salaryRangeFromTo": "Use Range: From - To",
  "salaryUnit": "Salary unit",
  "samsungBenefitConditions": "As a universaljob employee, you benefit from discounts on selected products and exciting bundle offers. All you have to do is click on the following button to access the internal Samsung shop. Happy shopping.",
  "save": "Save",
  "saveChanges": "Save",
  "saved": "saved",
  "savedTabs": "Saved tabs",
  "saveSearchQuery": "Save filter as standard",
  "scheduledToBePublished": "Waiting until {{ from }} ({{ fromDays }}), active from {{ from }} to {{ until }}",
  "scrollTop": "Scroll up",
  "search": "Search",
  "searchAddon": "Search",
  "searchTalents": "Search talents",
  "searchCompanyContactOptionsFooterInstruction": "Contact not on the list?",
  "searchCompanyContactPlaceholder": "Search by name, e-mail, phone number",
  "searchCompanyLocationOptionsFooterInstruction": "Company not on the list?",
  "searchCompanyLocationPlaceholder": "Search by name, street, postal code, city",
  "searchFilter": "Filter",
  "searchFilterReset": "Reset search/filter",
  "searchFor": "search for {{searchText}}",
  "searchIn": "search in {{field}}",
  "searchInDateOfBirth": "Search in date of birth",
  "searchInEmail": "Search in email",
  "searchInFirstName": "Search in first name",
  "searchInLastName": "Search in last name",
  "searchInLocation": "Search in location",
  "searchInPhone": "Search in phone number",
  "searchInStreet": "Search in street",
  "searchInTitle": "Search in title",
  "searchInVacancyNumber": "Search in vacancy number",
  "searchInWorkPlace": "Search in workplace",
  "searchInZip": "Search in ZIP code",
  "searchTermOrSelectCategory": "Enter search term or select category",
  "searchVacancies": "Search vacancies",
  "securityCode": "Security code",
  "selectBranch": "Select branch",
  "selectConsultant": "Select consultant",
  "selectDocumentType": "Select document type",
  "selectOptions": "Select options",
  "selectReason": "Select reason",
  "selectSort": "Select sort order",
  "selectValue": "Select",
  "sendApplication": "Apply",
  "sentEmailTo": "We have sent you an email to {{email}}.",
  "serverError": "Server error",
  "settings": "Settings",
  "share": "Share",
  "showAll": "Show all",
  "showAllDocuments": "Show all documents",
  "showAllEmployments": "Show all Employments",
  "showDetails": "show details",
  "showHours": "show hours",
  "showMap": "Show map",
  "showPlatformLinks": "Show platform links",
  "sick": "Sick",
  "sickness": "Sickness",
  "signOn": "login",
  "signOnWith": "Log in with {{type}}",
  "signUpWith": "Register with {{type}}",
  "since": "Since",
  "sinceArchived": "Since {{date}} ({{duration}} {{durationUnit}})",
  "sinceDaysPublished": "Since {{date}} ({{duration}} {{durationUnit}})",
  "sinceDeleted": "Since {{date}} ({{duration}} {{durationUnit}})",
  "skills": "Skills",
  "sorting": "Sorting",
  "specialistResponsibility": "Specialist responsibility",
  "spellingChecked": "Spelling checked?",
  "splitCommission": "Split commission",
  "splitWithNoone": "Split with no one",
  "staffConditions": "Employee condition",
  "start": "Start",
  "startAndEndTimeMustBeDifferent": "Start- und Endzeit müssen unterschiedlich sein",
  "startDate": "Start date",
  "wishDate": "Wish date",
  "statements": "Certificates",
  "statistics": "Statistik",
  "status": "Status",
  "stop": "Stop",
  "street": "Street",
  "streetAndNumber": "Street & Number",
  "streetPlusNumber": "Street + Number",
  "submit": "Submit",
  "submitDaysConfirmation": "Do you want to submit the entries?",
  "submitDaysHint": "After that, the entries can no longer be changed in the specified time period",
  "successfullySaved": "Successfully saved",
  "support": {
    "companyUser": {
      "adjust": "Adjust",
      "adjustText_1": "If the number of hours is incorrect, you can adjust them using a correction entry. Click on the pencil icon to modify the current entry. The original entry will still be visible with a strikethrough.",
      "adjustText_2": "The employee will see the correction, the modification date, and the name of the person who made the adjustment. You can correct not only time entries but also notes, expenses, absences, or reports.",
      "adjustText_3": "Correct the respective entry",
      "approveAndReject": "Approve and Reject",
      "approveText_1": "Once you have finished reviewing the employee's working hours, you can approve them for payment using the <span class='text-l-b'>Approval</span> button.",
      "approveText_2": "No further changes can be made after approval.",
      "generalQuestionsText": "For questions, please contact your universaljob contact, which you can find in the main menu under <span class='text-l-b'>Contact</span>.",
      "rejectText_1": "If there are still errors in the data or, for example, a required receipt is not attached, you can <span class='text-l-b'>reject</span> the hours so that the employee can provide the missing information.",
      "rejectText_2": "The employee can only edit the hours again if you reject them.",
      "reviewAndCorrect": "Review and Correct",
      "reviewAndCorrectText_1": "When an employee has finished recording their working hours and released them for review, they will be marked. Click on <span class='text-l-b'>Employees</span> in the main menu to see all <span class='text-l-b'>open releases</span>. To view the hours, click on <span class='text-l-b'>Show hours</span> for the respective employee.",
      "technicalQuestions": "Technical questions",
      "technicalQuestionsText": "For technical difficulties with hub.universaljob.ch or suggestions for improvement, you can directly contact the developers of hub.universaljob.ch:<br><br>intemp AG<br><a class='uj-underline' href='mailto:support@intemp.ch'>support@intemp.ch</a><br>+41 52 551 22 22"
    },
    "employee": {
      "absenceEditOrDelete": "Edit or delete absence",
      "absenceEditOrDeleteText": "Recorded absences are added to the respective day. The <span class='text-l-b'>Adjust absence</span> button becomes visible.",
      "absenceEditOrDeleteText_2": "Here, the absence can be modified or deleted. Related receipts can also be enlarged or deleted.",
      "absenceEditOrDeleteText_3": "If an absence has already been submitted, you can only open it to view the receipt, for example. Like all submitted entries, it cannot be edited anymore.",
      "add": "Add",
      "addHoursEntries": "Add hour entries",
      "addNote": "Add a new note",
      "addReceipt": "Add a new receipt",
      "breaks": "Breaks",
      "breaksText_1": "Breaks can be entered <span class='text-l-b'>manually</span> for an hour entry. They will be <span class='text-l-b'>deducted</span> from the total hours",
      "breaksText_2": "A break between two hour entries is <span class='text-l-b'>automatically</span> detected",
      "callOperation": "Call operation",
      "clickStartAndDatePeriod": "Click on the start date and then on the end date",
      "clickTwiceOnTheSameDay": "Click twice on the same date",
      "deleteEntry": "Delete the respective hour entry",
      "deleteReport": "Remove report",
      "deleteReportText_1": "If you want to remove a report or have uploaded the wrong document, please contact us for correction.",
      "editNote": "Edit a stored note",
      "editReceipt": "Edit and delete a stored receipt",
      "enterAbsences": "Enter absences",
      "enterAbsencesText": "In the corresponding operation, click on <span class='text-l-b'>Enter absence</span> in the right column (on smartphones, use the points menu next to <span class='text-l-b'>Submit days</span>).",
      "enterAbsencesText_2": "You can also add an absence by using the plus button on a specific day. The only difference is that the current day is already selected as the time period.",
      "enterExpenses": "Enter expenses",
      "enterExpensesText": "To enter expenses, click on the corresponding operation. Using the <span class='text-l-b'>plus symbol</span>, you can add an expense entry to the desired day. <br><br> Then provide a description and amount for the expense. You can capture a receipt using the camera icon.",
      "enterHours": "Enter hours",
      "enterHoursText_1": "To enter hours, click on <span class='text-l-b'>Operations</span> in the left main menu. Then select the desired operation from <span class='text-l-b'>Active Operations</span>.",
      "enterHoursText_2": "If you see the <span class='text-l-b'>Upload Report</span> button instead of <span class='text-l-b'>Enter hours</span> for the desired operation, it means that your employer does not have an account to digitally approve hours yet. Since work hours always need to be confirmed, you can upload signed reports in this case or ask your employer to request an account for digital hour verification.",
      "enterHoursText_3": "When you are in the hour entry section, you can expand the desired month to enter hours there. You will find an existing hour entry for each day, and you can add additional entries using the <span class='text-l-b'>plus symbol</span>.",
      "enterWorkReports": "Enter work reports",
      "generalQuestions": "General questions",
      "generalQuestionsText": "For questions, please contact your universaljob contact, which you can find in the main menu under <span class='text-l-b'>Contact</span>.",
      "hoursEntries": "Hour entries",
      "pointsMenuSmartphone": "Points menu on smartphone",
      "pointsMenuSmartphoneHint": "A note or receipt has been added here",
      "pointsMenuSmartphoneText": "In addition to the hour entry, there are additional editing functions on mobile",
      "receipt": "Receipt",
      "selectDay": "Select day",
      "selectSingleDay": "Select a single day",
      "sendReport": "Send report",
      "sendReportText_1": "In the corresponding operation, click on <span class='text-l-b'>Upload report</span> in the right column (on smartphones, use the points menu next to <span class='text-l-b'>Submit days</span>).",
      "sendReportText_2": "If you have a report, it should also include your <span class='text-l-b'>expenses</span>. Therefore, it is not possible to enter additional entries for days that have a report attached.",
      "sendReportText_3": "When you upload a report, it is directly transmitted to universaljob.",
      "setPeriod": "Set period",
      "submitData": "Submit",
      "submitReport": "Submit report",
      "submitReportText_1": "Once you have recorded all hours, absences, and expenses up to a certain point, you can send the recorded data to your employer for review using the <span class='text-l-b'>Submit</span> button.<br><br> Select a time until when you want to submit the data and then click <span class='text-l-b'>Submit days</span>.",
      "submitReportText_2": "Once you have submitted the data, you can no longer edit it! If there are still errors in the data, your employer needs to reject the hours to release them for editing again.",
      "technicalQuestions": "Technical questions",
      "technicalQuestionsText": "For technical difficulties with hub.universaljob.ch or suggestions for improvement, you can directly contact the developers of hub.universaljob.ch:<br><br>intemp AG<br><a class='uj-underline' href='mailto:support1@intemp.ch'>support@intemp.ch</a><br>+41 52 551 22 22",
      "uploadReport": "Upload report",
      "uploadReportText_1": "You can select the report period and upload the corresponding document using the <span class='text-l-b'>Upload report</span> button."
    }
  },
  "switchToFixedEmployment": "Switch to fixed employment or adjust the values",
  "switchToFixedSalary": "Switch to fixed salary or adjust the values",
  "switzerland": "Switzerland",
  "TARGET": "Target",
  "targetPlanTooltip": "Google and social media advertising",
  "templates": "Templates",
  "temporary": "Temporary",
  "testString": "Example test string",
  "tiles": "Tiles",
  "timespan": "Period",
  "title": "Title",
  "to": "to",
  "toBeApproved": "waiting for approval",
  "toBranchPage": "Go to branch page",
  "toSearch": "Search",
  "total": "Total",
  "totalExpenses": "Total Expenses",
  "totalHours": "Total Hours",
  "totalPriceExclTax": "Total excl. VAT",
  "totalRequired": "The duration of work in one day can not be zero",
  "transferDays": "Transmit data",
  "transferDaysUntil": "Transmit days until",
  "transferSuccess": "Data transmitted",
  "translatedPayrollLanguages": {
    "ALB": "Albanian",
    "BUL": "Bulgarian",
    "DES": "German",
    "ENU": "English",
    "FRS": "French",
    "HRV": "Croatian",
    "ITS": "Italian",
    "POL": "Polish",
    "POR": "Portuguese",
    "RUM": "Romanian",
    "SLO": "Slovak",
    "SPA": "Spanish",
    "SRP": "Serbian",
    "TUR": "Turkish"
  },
  "tryAgain": "Try again",
  "type": "Type",
  "uj-post-to-win-first-price": "1st prize: CHF 500",
  "uj-post-to-win-second-price": "2nd prize: CHF 300",
  "uj-post-to-win-third-price": "3rd prize: CHF 200",
  "uj-post-to-win-title": "Win one of the following prizes each month!",
  "ujWebsite": "UJ Website",
  "ujWebsiteLinkCopied": "Link copied",
  "unpublishedChanges": "Unpublished changes",
  "unsavedChangesConfirmation": "Do you want to discard or save the changes?",
  "unsavedChangesCustomerConfirmation": "Do you want to discard or save these changes?",
  "unsavedChangesCustomerTitle": "Correction made",
  "unsavedChangesTitle": "Changes made",
  "unschedule": "Lift blocking period",
  "updated": "Updated",
  "upload": "Upload",
  "uploadAbsenceSuccessful": "The absence was recorded",
  "uploadAttachment": "Upload attachment",
  "uploadedWrongRapport": "If you want to change this repeat, please write us an e-mail.",
  "uploadError": "Upload cancelled, please try again.",
  "uploadExpensesSuccessful": "Expenses have been created",
  "uploadFile": "Upload file",
  "uploadFilesHint": "Please upload the following documents if you have not already submitted them to us:",
  "uploadMedicalCertificate": "Upload medical certificate",
  "uploadPaymentReceipt": "Upload payment receipt",
  "uploadPicture": "Upload image",
  "uploadReport": "Upload rapport",
  "uploadReportSuccessful": "Report successfully uploaded",
  "userManagement": "User",
  "users": "User",
  "vacancies": "Vacancies",
  "vacanciesSavedSuccessfully": "Vacancies saved",
  "vacancy": "Vacancy",
  "vacancyArchived": "Vacancy archived",
  "vacancyBranch": "Branch",
  "vacancyChangelogActions": {
    "activated": "activated <span class='text-m-b'>vacancy</span>",
    "added": "added <span class='text-m-b'>{{entryName}}</span>",
    "archived": "archived <span class='text-m-b'>vacancy</span>",
    "changed": "changed <span class='text-m-b'>{{entryName}}</span>",
    "created": "created <span class='text-m-b'>{{entryName}}</span>",
    "deactivated": "deactivated <span class='text-m-b'>vacancy</span>",
    "deleted": "deleted <span class='text-m-b'>vacancy</span>",
    "published": "published <span class='text-m-b'>vacancy</span>",
    "removed": "removed <span class='text-m-b'>{{entryName}}</span>",
    "restored": "restored <span class='text-m-b'>vacancy</span>",
    "scheduled": "scheduled <span class='text-m-b'>vacancy</span>",
    "unscheduled": "lifted blocking period for <span class='text-m-b'>vacancy</span>",
    "generated": "generated <span class='text-m-b'>vacancynumber</span>"
  },
  "vacancyChangelogEntry": {
    "nestedEntry": "has <span class='text-m-b'>{{entryName}}</span> in <span class='text-m-b'>{{parentName}}</span> {{action}}",
    "singleEntry": "has <span class='text-m-b'>{{entryName}}</span> {{action}}"
  },
  "vacancyCreated": "Vacancy created!",
  "vacancyDeleted": "Vacancy deleted!",
  "vacancyDuplicatedSuccessfully": "Vacancy successfully duplicated!",
  "vacancyHasBlockingPromotion": "Vacancy has a blocking promotion",
  "vacancyNr": "Vacancy no.",
  "vacancyNumber": "Vacancy number",
  "vacancyNumberCopied": "Vacancy number copied",
  "vacancyPublishedSuccessfully": "Vacancy successfully published",
  "vacancySavedSuccessfully": "Vacancy saved",
  "vacancySheetAlreadyOpen": "One vacancy is already being edited",
  "vacancyStatusEnum": {
    "ARCHIVED": "Archived",
    "DELETED": "Deleted",
    "DRAFT": "Draft",
    "LIVE": "Active"
  },
  "UniBaseXVacancyChannelConfigurationStatusEnum": {
    "PENDING": "Pending",
    "RUNNING": "Running",
    "COMPLETED": "Completed",
    "EXPIRED": "Expired",
    "REQUESTED": "waiting for approval"
  },
  "vacancyTitle": "Vacancy title",
  "vacation": "Vacation",
  "vacationReferred": "Vacation taken",
  "verificationEmail": "An error occurred while checking yours.",
  "verificationEmailResend": "Email has been resent",
  "verificationMessage": "Please verify your identity.",
  "verificationSms": "We have sent a message to <b>{{number}}</b> with the code to activate your account.",
  "verifyUser": "An error occurred while checking yours.",
  "version": "Version",
  "versionRestoredSuccessfully": "Version successfully restored",
  "versions": "Versions",
  "view": "View",
  "viewCompany": "View company",
  "viewVacancy": "View vacancy",
  "vitrineTV": "Showcase TV",
  "wageStatements": "Wage statements",
  "waitingUntil": "Until {{date}} ({{duration}} {{durationUnit}})",
  "welcome": "Welcome",
  "welcomeAt": "Welcome to",
  "whatDoesTheCompanyDoPlaceHolder": "Briefly describe the company and what makes it an attractive employer.",
  "whatDoiGetPlaceHolder": "Name other benefits that make your company a unique and attractive workplace.",
  "whatDoiNeedToBeSuccessfulPlaceHolder": "Describe the talent's requirements, such as desired professional experience, qualifications, and skills.",
  "whatIsMyFunctionPlaceHolder": "Describe the exact tasks and areas of responsibility of the position so that potential applicants understand what makes this position attractive.",
  "willBePublishedAfter": "Will be activated after vacancy is published",
  "willNotBePublished": "Will not be activated",
  "workArrangement": "Work arrangement",
  "workAvailability": "Availability",
  "workDurationMustBeGreaterThanPauseDuration": "Die Arbeitsdauer muss grösser sein als die Pausendauer",
  "workload": "Workload",
  "workloadCanNotBeZero": "Workload percentage cannot be 0",
  "workloadFlexible": "Flexible workload",
  "workloadFrom": "Workload of",
  "workPlace": "Site",
  "exactWorkPlace": "Exact workplace",
  "workRegion": "Work region",
  "write": "Write",
  "writeEmail": "write Email",
  "xDaysToApprove": "{{amount}} days to approve",
  "xDaysToTransfer": "{{amount}} days to transfer",
  "xVacanciesCouldNotBeUpdated": "{{count}} vacancies could not be updated",
  "xVacanciesUpdated": "{{count}} vacancies updated",
  "year": "Year",
  "years": "Years",
  "yes": "Yes",
  "yourContactAtUniversalJob": "Your contact at universaljob",
  "zip": "Zip",
  "zwischenverdienste": "Interim earnings",
  "cannotReachServerAttemtingToReconnect": "Reconnecting. Please wait a moment.",
  "genderAddition": "(m/f/d)",
  "hide-preview": "Hide preview",
  "editSavedFilter": "Edit saved filter",
  "editTemporaryFilter": "Edit temporary filter",
  "showSearchHistory": "Show search history",
  "searchInJobCompany": "Search in company",
  "searchInContact": "Search in contact",
  "promotion": "Promotion",
  "regionOrAproximateLocation": "Region or approximate location",
  "months": "Months",
  "seconds": "Seconds",
  "validationVacancyTitleMaxLength": "Shorten your job title if possible",
  "validationVacancyTitleDoubledTermsProvidedByOtherFields": "Duplicate content: remove percentage or gender indications",
  "validationVacancyTitleBannedFillerWordsOrCharacters": "Do not use 'filler words' such as: immediate, wanted, urgent, or special characters like: «!», «:», «*»",
  "validationVacancyTitleIsEmpty": "Please enter a job designation",
  "youAreOffline": "You are offline",
  "pageIsReloading": "Page is reloading",
  "useFromTo": "use from - to",
  "employmentContract": "Employment contract",
  "employmentStart": "Employment start",
  "employmentEnd": "Employment end",
  "stats": "Statistics",
  "trackedActionType": {
    "VACANCY_VIEWED": "Views",
    "VACANCY_APPLICATION": "Applications",
    "VACANCY_APPLICATION_FORM_OPENED": "Application form opened",
    "VACANCY_TVSCREEN_QR_CODE_SCANNED": "TV QR code scanned"
  },
  "perChannel": "per channel",
  "applicationToViewRatio": "Application to view ratio",
  "tvScreen": "TV-Screen",
  "sinceRelativeDate": "since {{date}} ({{relativeDate}})",
  "contractTerm": "Contract term",
  "whatMakesTheCompanyTitle": "What makes the company?",
  "whatAreMyBenefits": "What are my benefits?",
  "whatDoINeedTitle": "What do I need to be successful?",
  "whatIsMyFunctionTitle": "What is my function?",
  "totalViewed": "Views",
  "totalApplications": "Applications",
  "prioBoost": "Prio Boost",
  "editSlots": "Edit slots",
  "prioBoostDescription": "You can boost up to {{max}} of your vacancies on the job platforms 🚀.",
  "prioBoostSlotGuidance": "Currently you have the following vacancies prioritized:",
  "onlyResponsibleCanChangeChannelConfig": "Only the responsible employee can change the channel configuration",
  "onlyUsersOfSameBranchCanExtendAddons": "Only employees of the same branch can extend addons.",
  "onlyUserOfSameBranchCanChannelConfig": "Only employees of the same branch can change the channel configuration.",
  "onlyUsersOfSameBranchCanAddAddons": "Only employees of the same branch can add addons.",
  "maxSlotsPerUserReached": "You have reached the maximum number of slots",
  "maxSlotsTotalReached": "You have reached the maximum number of slots",
  "warningChannelsWithMaxRunningAndPendingSlotsPerUserWillBeDeactivated": "Warning: The active Prio-Boost will be deactivated automatically when the responsible employee is changed.",
  "currentChannelStatus": "Promotion: Status",
  "currentChannelUuid": "Promotion: Channel",
  "Options": "Options",
  "searchVideoActiveWarning": "The start date is set by our marketing as soon as the video production date is known.",
  "onlyOrderVideoIfReady": "Only order a search with video option if the video is ready and you have received the «Go» from Sina, so that the start date can be met. Because all channels must be advertised simultaneously to achieve maximum reach.",
  "extendSearchAddon": "Extend last add-on",
  "SearchPlanFeaturePoint": {
    "TalentExclusivity": "Talent exclusivity",
    "ActiveSearchers": "Active searchers",
    "PassiveSearchers": "Passive searchers",
    "RecruitingViaJobPlatforms": "Recruiting via job platforms",
    "RecruitingViaSocialMedia": "Recruiting via social media"
  },
  "VIDEOFeaturePoint": {
    "coversTheBroadestSearchWithMax": "Covers the broadest search with «MAX»",
    "strengthenEmployerAttractiveness": "Strengthen employer attractiveness",
    "completeVideoProductionWithProfessionals": "Complete video production with professionals",
    "authenticAndProfessional": "Authentic and professional",
    "modernAppearanceAndMoreVisibility": "Modern appearance and more visibility"
  },
  "openInNewTab": "Open in new tab",
  "searchAddonWarningVacancyArchiveTitle": "End search early?",
  "searchAddonWarningVacancyArchiveMessage": "The booked search is still active until {{ until }} ({{ untilDays }} days). Early termination will not be refunded. Do you really want to end it?",
  "pioBoostWarningVacancyArchiveMessage": "The vacancy will be removed from the Prio Boost slot",
  "deactivationNotes": "Notes for termination",
  "deactivationNotesPlaceholder": "Remarks such as the reason for early termination.",
  "channelRequested": "waiting for approval - desired date: {{ from }}",
  "addonAcceptRequest": "Activate add-on",
  "resume": "Resume",
  "journey": "Journey",
  "addChild": "Add child",
  "talent": "Talent",
  "talentCreated": "Talent created",
  "personalData": "Personal data",
  "availability": "Availability",
  "preferences": "Preferences",
  "dataProtection": "Data protection",
  "yearOld": "Years old",
  "add": "Add",
  "yearOfBirth": "Year of birth",
  "childAllowance": "Child allowance",
  "successfullyAdded": "Successfully added",
  "successfullyRemoved": "Successfully removed",
  "successfullyUpdated": "Successfully updated",
  "hasNoChildAllowance": "Has no child allowance",
  "loadMore": "Load more",
  "onlyShowingXEntries": "Only showing {{count}} entries",
  "areYouSureYouWantToDeleteThisEntry": "Are you sure you want to delete this entry?",
  "validUntil": "Valid until",
  "talentStatusWillChangeAsSoonPermitPresent": "As soon as a permit is present, the talent's status will change to 'Open to work'.",
  "chooseCategory": "Choose category",
  "vehicleAvailable": "Vehicle available",
  "chooseReligiousDenomination": "Choose religious denomination",
  "chooseMaritalStatus": "Choose marital status",
  "telephone": "Telephone",
  "addNumber": "Add number",
  "otherNumbers": "Other numbers",
  "primaryPhoneUpdated": "Primary phone number set",
  "primaryEmailUpdated": "Primary email set",
  "willBeYourPrimaryPhone": "will be set as primary number",
  "primary": "Primary",
  "otherEmails": "Other emails",
  "addEmail": "Add email",
  "addresses": "Addresses",
  "addAddress": "Add address",
  "children": "Children",
  "addUrl": "Add URL",
  "invalidUrl": "The URL is invalid, check your input",
  "availabilityWillBeDisplayAtInterviewAndSynced": "Availabilities will be displayed in the interview and synced",
  "availableForWorkAt": "Available from",
  "selectDate": "Select date",
  "registeredWithRAV": "Registered with RAV",
  "nextService": "Next service",
  "nextMilitaryServiceAt": "Next military service",
  "addRemark": "Add remark",
  "availabilityNote": "Availability note",
  "talentChangelogActions": {
    "added": "added <span class='text-m-b'>{{entryName}}</span>",
    "changed": "changed <span class='text-m-b'>{{entryName}}</span>",
    "created": "created <span class='text-m-b'>{{entryName}}</span>",
    "removed": "removed <span class='text-m-b'>{{entryName}}</span>",
    "deleted": "deleted <span class='text-m-b'>talent</span>"
  },
  "talentChangelogEntry": {
    "nestedEntry": "added <span class='text-m-b'>{{entryName}}</span> in <span class='text-m-b'>{{parentName}}</span> {{action}}",
    "singleEntry": "added <span class='text-m-b'>{{entryName}}</span> {{action}}",
    "customEntry": {
      "CREATION": "created <span class='text-m-b'>talent</span>",
      "HAS_ACCEPTED_DATA_PRIVACY_POLICY": "Talent accepted data privacy policy.",
      "HAS_SENT_DATA_PRIVACY_POLICY_EMAIL": "sent data privacy email to <span class='text-m-b'>{{sentTo}}</span>"
    }
  },
  "setTalentToBlackList": "Add talent to blacklist",
  "attentionOnlyUserWithPermissionUndoBlacklist": "Attention: only users with permission can undo the blacklist status.",
  "confirm": "Confirm",
  "preferencesWillBeDisplayAtInterviewAndSynced": "Preferences will be displayed in the interview and synced.",
  "targetJob": "Target job",
  "addJob": "Add job",
  "workModel": "Work model",
  "salaryExpectation": "Salary expectation",
  "addLocation": "Add location",
  "enterRoute": "Enter route",
  "preferredLocation": "Preferred location",
  "radius": "Radius",
  "transportation": "Transportation",
  "commutingByPublicTransport": "Public transport",
  "commutingByCar": "Car",
  "commutingByBicycle": "Bicycle",
  "maxCommutingTimeMinutes": "Max commuting time (minutes)",
  "addDuration": "Add duration",
  "talentLifeCycle": "Talent lifecycle",
  "availability_until": "Available until {{date}} ({{days}} days)",
  "data_deletion_notice": "According to Swiss data protection regulations, we are required to delete all personal data of {{firstName}} {{lastName}} after 6 months.",
  "data_deletion_notice_notification_message": "{{firstName}} {{lastName}} will be notified 4 weeks before the deadline and has the option to extend the period.",
  "dataPrivacyPolicy": "Data privacy policy",
  "no_email_for_privacy_policy": "No email available to send data privacy policy.",
  "privacy_policy_not_accepted_will_be_deleted": "If the data privacy policy is not accepted by <span class='text-m-b'> {{date}} ({{days}} days) </span>, the talent will be deleted.",
  "privacy_policy_accepted": "Accepted data privacy policy on {{date}}.",
  "privacy_policy_not_accepted": "Data privacy policy not accepted yet.",
  "send_privacy_policy_email": "Send data privacy email",
  "privacy_policy_email_sent_log": "<span class='text-m-b'>{{name}}</span> sent an email to <span class='text-m-b'> {{email}} </span>.",
  "alreadyAccepted": "Data privacy policy already accepted",
  "invalidToken": "Invalid token",
  "privacyPolicyAccepted": "Data privacy policy successfully accepted.",
  "dataPrivacyPolicyNotAccepted": "Data privacy not accepted",
  "talentNotFound": "Talent not found",
  "matching": "Matching",
  "source": "source",
  "sources": "sources",
  "addLeadsAndSource": "Add lead / source",
  "recommendation": "Recommendation",
  "addRecommendation": "Add recommendation",
  "internalNotes": "Internal notes",
  "addNotes": "Add notes",
  "talentNumber": "Talent number",
  "talentNumberCopied": "Talent number copied",
  "salutation": "Salutation",
  "hasVehicle": "Vehicle available",
  "phones": "Phones",
  "emails": "Emails",
  "employedUntil": "Employed until",
  "employedSince": "Employed since",
  "zipCodeIsInvalid": "The entered ZIP code appears to be invalid or a P.O. box. Please correct the ZIP code.",
  "publishedWorkLocation": "Published work location",
  "noTalentsFound": "No talents found",
  "createdBy": "Created by",
  "lastModifiedBy": "Last modified by",
  "searchInCompany": "Search in Company",
  "newDocumentsAvailable": "{{count}} new documents available",
  "thereAreNewDocumentsAvailableForTalent": "There are {{count}} new documents for this talent",
  "activeDocuments": "Active Documents",
  "generatingPages": "Generating pages for {{file}}",
  "savingFile": "Saving file {{file}}",
  "savingPages": "Saving pages for {{file}}",
  "archivedDocuments": "Archived Documents",
  "originalFiles": "Original Files",
  "showDocuments": "Show Documents",
  "actives": "Actives",
  "unchecked": "Unchecked",
  "originals": "Originals",
  "loadPdf": "Load PDF",
  "uploadDocument": "Upload Document",
  "uploadingFile": "Uploading file {{file}}",
  "error": "Error",
  "downloading": "Downloading",
  "favoritesWillBeUsedByDefaultDuringRecommendation": "Favorites will be used by default during recommendation",
  "moveUp": "Move Up",
  "moveDown": "Move Down",
  "unfavorite": "Unfavorite",
  "makeFavorite": "Make Favorite",
  "download": "Download",
  "archive": "Archive",
  "removeDocument": "Remove Document",
  "areYouSureYouWantToRemoveDocument": "Are you sure you want to permanently remove the document?",
  "page": "Page",
  "addedToFavorites": "Added to favorites",
  "removedFromFavorites": "Removed from favorites",
  "movedToActiveDocuments": "Moved to active documents",
  "files": "files",
  "pages": "pages",
  "ujInternalDescription": "Internal job postings for searching for new employees at universaljob.",
  "thisDocumentHasAlreadyBeenUploaded": "This document has already been uploaded",
  "emailAlreadyExists": "Email already exists",
  "staffRoles": "Staff role",
  "markAsUnread": "Mark as unread",
  "successfully": {
    "archived": "Archived",
    "markedAsUnread": "Marked as unread",
    "restored": "Restored"
  },
  "progress": "Progress",
  "moveToProcessStep": "Moved to {{processStep}}",
  "rating": "Rating",
  "noRating": "No rating",
  "archiveMatch": "Archive match",
  "imgPreviewIsGenerating": "generating preview",
  "phoneAlreadyExists": "Phone already exists",
  "blackListedReason": "blacklisted reason",
  "employmentCompany": "Employment company",
  "targetJobTitle": "Target job",
  "showWorkloadInPublicTitle": "Show workload in job title",
  "sendCancelation": "Send cancelation",
  "youFormal": "You",
  "youInformal": "You",
  "formalSalutation": {
    "MALE": "Dear Mr. {{lastName}}",
    "FEMALE": "Dear Ms. {{lastName}}",
    "OTHER": "Hello {{firstName}} {{lastName}}"
  },
  "informalSalutation": {
    "MALE": "Dear {{firstName}}",
    "FEMALE": "Dear {{firstName}}",
    "OTHER": "Hi {{firstName}}"
  },
  "jobApplicantCancellationBlind": {
    "subject": "Rejection of Application",
    "paragraphsFormal": [
      "Thank you for your application and your interest in our services. We have carefully reviewed your documents, but unfortunately, we currently do not have a suitable offer for you.",
      "We will gladly keep your documents on file and contact you as soon as a suitable position becomes available.",
      "In the meantime, we wish you much success and hope that a suitable opportunity arises soon!"
    ],
    "paragraphsInformal": [
      "Thank you for your application and your interest in our services. We have carefully reviewed your documents, but unfortunately, we do not currently have a suitable offer for you.",
      "We will gladly keep your documents on file and contact you as soon as a suitable position becomes available.",
      "Until then, we wish you much success and look forward to when a suitable opportunity arises!"
    ]
  },
  "jobApplicantCancellation": {
    "subject": "Rejection of Application «{{vacancyTitle}}»",
    "paragraphsInformal": {
      "WE_CANCELED": [
        "Thank you for applying for the position of {{vacancyTitle}}. We have carefully reviewed your documents and greatly appreciate your interest in the position. Unfortunately, we must inform you that we have decided to move forward with another candidate for this position.",
        "We will gladly keep your documents on file and contact you as soon as we have a suitable position available.",
        "Until then, we wish you much success and hope that a suitable opportunity arises soon!"
      ],
      "CLIENT_CANCELLED": [
        "Following our recommendation for the position of {{vacancyTitle}} at {{companyLegalName}}, we received feedback from our client. After careful consideration, they have unfortunately decided to fill the position with another candidate.",
        "We will be happy to contact you as soon as we have a new suitable position or further feedback for you.",
        "Until then, we wish you much success and hope that a suitable opportunity arises soon!"
      ]
    },
    "paragraphsFormal": {
      "WE_CANCELED": [
        "Thank you for applying for the position of {{vacancyTitle}}. We have carefully reviewed your documents and greatly appreciate your interest in the position. Unfortunately, we must inform you that we have decided to proceed with another candidate for this role.",
        "We will gladly keep your documents on file and contact you as soon as we have a suitable position available.",
        "In the meantime, we wish you much success and hope that a suitable opportunity comes your way soon!"
      ],
      "CLIENT_CANCELLED": [
        "Following our recommendation for the position of {{vacancyTitle}} at {{companyLegalName}}, we received feedback from our client. After careful consideration, they have unfortunately decided to fill the position with another candidate.",
        "We will be happy to contact you as soon as we have a new suitable position or further feedback for you.",
        "In the meantime, we wish you much success and hope that a suitable opportunity arises soon!"
      ]
    }
  },
  "updatedAt": "updated at",
  "time": {
    "minuteAgo": "1 minute ago",
    "minutesAgo": "{{count}} minutes ago",
    "hourAgo": "1 hour ago",
    "hoursAgo": "{{count}} hours ago",
    "dayAgo": "1 day ago",
    "daysAgo": "{{count}} days ago",
    "monthAgo": "1 month ago",
    "monthsAgo": "{{count}} months ago",
    "yearAgo": "1 year ago",
    "yearsAgo": "{{count}} years ago"
  },
  "vacancyStatus": "Vacancy status"
}
