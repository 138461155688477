import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  output,
  signal,
} from '@angular/core';

/*
 * This directive checks if the element (target) is visible on the screen
 * or is obstructed by another element by periodically checking if top Element is part of target.
 */
@Directive({
  selector: '[appVisibilityCheck]',
  exportAs: 'visibilityCheck',
  standalone: true,
})
export class VisibilityCheckDirective implements OnDestroy, AfterViewInit {
  constructor(private el: ElementRef) {}

  private interval: any;
  public coverageChanged = output<boolean>();
  private wasCovered = signal<boolean | undefined>(undefined);

  ngAfterViewInit() {
    this.interval = setInterval(() => {
      this.checkVisibility();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  private checkVisibility() {
    const target = this.el.nativeElement;
    const targetRect = target.getBoundingClientRect();
    const topElement = document.elementFromPoint(
      targetRect.left + targetRect.width / 2,
      targetRect.top + targetRect.height / 2,
    );

    if (!topElement) {
      return;
    }

    const isCovered = !(topElement && target.contains(topElement));
    if (this.wasCovered() !== isCovered) {
      this.wasCovered.set(isCovered);
      this.coverageChanged.emit(isCovered);
    }
  }
}
