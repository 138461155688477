import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  model,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AccordionComponent,
  ButtonLinkComponent,
  ButtonOutlineComponent,
  ButtonTextComponent,
  InfoboxComponent,
  ProgressBarComponent,
  ToastService,
} from '@intemp/unijob-ui2';
import { generateTitleFromFilename } from '@libs/shared/helpers/generateTitleFromFilename';
import { sleep } from '@libs/shared/helpers/sleep';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  ArrayActionEnum,
  TalentFragment,
  TalentUpdateGQL,
} from '../../../../graphql/generated';
import { UserService } from '../../../../models/shared/user/user.service';
import { AnchorPoint } from '../../anchor-navigation/anchor-navigation.component';
import { AnchorNavigationModule } from '../../anchor-navigation/anchor-navigation.module';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../global-sheets/global-sheets.service';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { TalentDocumentCardComponent } from './talent-document-card/talent-document-card.component';

interface UploadSession {
  fileName: string;
  uploadProgress: number;
  generatingPagesProgress?: number;
  uploadSub: Subscription | undefined;
  uploadPhase:
    | 'uploadingFile'
    | 'savingFile'
    | 'generatingPages'
    | 'savingPages'
    | 'done';
  errorMessage?: string;
}
@Component({
  standalone: true,
  selector: 'app-talent-documents',
  templateUrl: './talent-documents.component.html',
  styleUrls: ['./talent-documents.component.scss'],
  imports: [
    I18NextModule,
    SharedDefaultModule,
    TalentDocumentCardComponent,
    AnchorNavigationModule,
    AccordionComponent,
    ButtonOutlineComponent,
    CdkDropList,
    CdkDrag,
    ProgressBarComponent,
    ButtonTextComponent,
    ButtonLinkComponent,
    InfoboxComponent,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentDocumentsComponent {
  dragover($event: DragEvent) {
    $event.preventDefault();
  }
  Object = Object;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private talentUpdateGql: TalentUpdateGQL,
    private toastService: ToastService,
    private globalSheetsService: GlobalSheetsService,
  ) {
    effect(() => {
      const uploadDocument = this.openSheetUploadDocument();
      if (uploadDocument) {
        this.triggerUploadDocument();
      }
    });
  }

  readonly talent = model.required<TalentFragment>();
  readonly activePages = computed(() =>
    this.talent().pages.filter((page) => page.active),
  );
  readonly archivedPages = computed(() =>
    this.talent().pages.filter((page) => !page.active),
  );
  readonly unreadPages = computed(() =>
    this.talent().pages.filter((page) => !page.read && page.active),
  );

  i18next = inject(I18NextPipe);

  protected uploads = signal<{ [key: string]: UploadSession }>({});
  protected selectedPageUuids = signal<string[]>([]);

  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });
  openSheetOverlayAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) =>
        sheet.type === GlobalSheetTypeEnum.TALENT_EDIT &&
        sheet.uuid === this.talent().uuid,
    );
    return sheet?.overlayAction;
  });
  openSheetOverlayUuid = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) =>
        sheet.type === GlobalSheetTypeEnum.TALENT_EDIT &&
        sheet.uuid === this.talent().uuid,
    );
    return sheet?.overlayUuid;
  });

  @ViewChild('fileInput') fileInput!: ElementRef;

  openSheetActions = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) =>
        sheet.type === GlobalSheetTypeEnum.TALENT_EDIT &&
        sheet.uuid === this.talent().uuid,
    );
    return sheet?.action;
  });

  openSheetUploadDocument = computed(() => {
    return this.openSheetActions() === 'uploadDocument';
  });

  triggerUploadDocument() {
    const fileInput = this.fileInput?.nativeElement as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    } else {
      console.error('fileInput not available');
    }
  }

  // click on a page will select it
  public activePageClicked(
    event: MouseEvent,
    page: TalentFragment['pages'][number],
  ) {
    this.pageClicked(event, page, true);
  }

  public inactivePageClicked(
    event: MouseEvent,
    page: TalentFragment['pages'][number],
  ) {
    this.pageClicked(event, page, false);
  }

  private pageClicked(
    event: MouseEvent,
    page: TalentFragment['pages'][number],
    active: boolean,
  ) {
    if (event.shiftKey && this.selectedPageUuids().length > 0) {
      // removes selections that happen when shift clicking
      const selection = window.getSelection();
      selection?.removeAllRanges();

      const selectedPage = this.talent().pages.find((page) =>
        this.selectedPageUuids().includes(page.uuid),
      );
      if (!selectedPage) {
        return;
      }
      if (active) {
        const selectedPageIndex = this.activePages().indexOf(selectedPage);
        const clickedPageIndex = this.activePages().indexOf(page);
        const minIndex = Math.min(selectedPageIndex, clickedPageIndex);
        const maxIndex = Math.max(selectedPageIndex, clickedPageIndex);
        this.selectedPageUuids.set(
          this.activePages()
            .slice(minIndex, maxIndex + 1)
            .map((page) => page.uuid),
        );
      } else {
        const selectedPageIndex = this.archivedPages().indexOf(selectedPage);
        const clickedPageIndex = this.archivedPages().indexOf(page);
        const minIndex = Math.min(selectedPageIndex, clickedPageIndex);
        const maxIndex = Math.max(selectedPageIndex, clickedPageIndex);
        this.selectedPageUuids.set(
          this.archivedPages()
            .slice(minIndex, maxIndex + 1)
            .map((page) => page.uuid),
        );
      }
    } else if (event.metaKey || event.ctrlKey) {
      if (this.selectedPageUuids().includes(page.uuid)) {
        this.selectedPageUuids.set(
          this.selectedPageUuids().filter((uuid) => uuid !== page.uuid),
        );
      } else {
        this.selectedPageUuids.set([...this.selectedPageUuids(), page.uuid]);
      }
    } else if (this.selectedPageUuids().includes(page.uuid)) {
      this.selectedPageUuids.set([]);
    } else {
      this.selectedPageUuids.set([page.uuid]);
    }
  }

  public previewPage(page: TalentFragment['pages'][number]) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.TALENT_EDIT,
        uuid: this.talent().uuid,
      },
      'overlayUuid',
      page.uuid,
    );
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.TALENT_EDIT,
        uuid: this.talent().uuid,
      },
      'overlayAction',
      page.active ? 'active' : 'inactive',
    );
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.TALENT_EDIT,
        uuid: this.talent().uuid,
      },
      'action',
      'preview',
    );
  }

  public goToAndOpenInactivePages() {
    const archivedPagesAccordion = document.getElementById(
      'archivedPagesAccordion' + this.talent().uuid,
    );
    if (archivedPagesAccordion) {
      archivedPagesAccordion.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public openFirstUnreadPage() {
    this.setAccordionOpen('activePages', true, true);
    const firstUnreadPage = this.unreadPages()[0];
    this.previewPage(firstUnreadPage);
  }

  public openAccordions = signal<{ [key: string]: boolean }>({
    activePages: true,
    archivedPages: false,
    files: false,
  });

  async setAccordionOpen(anchor: string, open: boolean, scroll = false) {
    const wasOpen = this.openAccordions()[anchor];
    this.openAccordions.set({
      ...this.openAccordions(),
      [anchor]: open,
    });
    if (scroll) {
      const element = document.getElementById(anchor);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        if (!wasOpen) {
          await sleep(150);
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }

  openAccordionIfCollapsed = async (anchor: AnchorPoint) => {
    this.setAccordionOpen(anchor.id, true, true);
  };

  getFileArray(files: FileList | null): File[] {
    return files ? Array.from(files) : [];
  }

  async onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = this.getFileArray(input.files);
    await Promise.allSettled(files.map((file) => this.uploadToServer(file)));
  }

  archivedPagesListDrop(
    event: CdkDragDrop<string[], string[], TalentFragment['pages'][number]>,
  ) {
    this.droppedPages(event, false);
  }

  activePagesListDrop(
    event: CdkDragDrop<string[], string[], TalentFragment['pages'][number]>,
  ) {
    this.droppedPages(event, true);
  }

  droppedPages(
    event: CdkDragDrop<string[], string[], TalentFragment['pages'][number]>,
    active: boolean,
  ) {
    // cdk will specify relative index, we need the actual index in the talent.pages array instead
    const currentItemAtLocation = this.activePages()[event.currentIndex];
    const actualIndex = this.talent().pages.findIndex(
      (page) => page.uuid === currentItemAtLocation.uuid,
    );

    let updatedPages: TalentFragment['pages'];

    if (this.selectedPageUuids().length > 0) {
      updatedPages = this.optimisticallyUpdateMultiplePages(actualIndex);
      this.talentUpdateGql
        .mutate({
          input: {
            uuid: this.talent().uuid,
            pages: this.selectedPageUuids().map((uuid, index) => ({
              uuid,
              type: ArrayActionEnum.CHANGED,
              active,
              moveToIndex: actualIndex, // TODO: sorting within selected items not stable
            })),
          },
        })
        .subscribe();
    } else {
      // Update single page
      updatedPages = this.optimisticallyUpdateSinglePage(
        event.item.data,
        actualIndex,
      );
      this.talentUpdateGql
        .mutate({
          input: {
            uuid: this.talent().uuid,
            pages: [
              {
                uuid: event.item.data.uuid,
                type: ArrayActionEnum.CHANGED,
                moveToIndex: actualIndex,
                active,
              },
            ],
          },
        })
        .subscribe();
    }
    this.updateTalentPages(updatedPages);
  }

  private optimisticallyUpdateMultiplePages(
    targetIndex: number,
  ): TalentFragment['pages'] {
    const updatedPages = [...this.talent().pages];
    const selectedPages = updatedPages.filter((page) =>
      this.selectedPageUuids().includes(page.uuid),
    );

    // Remove selected pages from their current positions
    selectedPages.forEach((page) => {
      const index = updatedPages.findIndex((p) => p.uuid === page.uuid);
      updatedPages.splice(index, 1);
    });

    // Insert selected pages at the target index
    updatedPages.splice(targetIndex, 0, ...selectedPages);

    return updatedPages;
  }

  private optimisticallyUpdateSinglePage(
    page: TalentFragment['pages'][number],
    targetIndex: number,
  ): TalentFragment['pages'] {
    const updatedPages = [...this.talent().pages];
    const currentIndex = updatedPages.findIndex((p) => p.uuid === page.uuid);

    // Remove the page from its current position
    updatedPages.splice(currentIndex, 1);

    // Insert the page at the target index
    updatedPages.splice(targetIndex, 0, { ...page });
    return updatedPages;
  }

  private updateTalentPages(pages: TalentFragment['pages']) {
    this.talent.update((talent) => ({
      ...talent,
      pages,
    }));
  }

  toggleFavorite(page: TalentFragment['pages'][number], set = !page.favorite) {
    if (set === page.favorite) {
      return;
    }
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              favorite: !page.favorite,
            },
          ],
        },
      })
      .subscribe();
    if (set === true) {
      this.toastService.makeToast({
        message: this.i18next.transform('addedToFavorites'),
        type: 'SUCCESS',
        duration: 3000,
      });
    } else {
      this.toastService.makeToast({
        message: this.i18next.transform('removedFromFavorites'),
        type: 'INFO',
        duration: 3000,
      });
    }
  }

  makePageActive(page: TalentFragment['pages'][number]) {
    // move it to the end of the active pages
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              moveToIndex: this.activePages().length - 1,
              active: true,
              read: true,
            },
          ],
        },
      })
      .subscribe();

    this.toastService.makeToast({
      message: this.i18next.transform('movedToActiveDocuments'),
      type: 'INFO',
      duration: 3000,
    });
  }

  archivePage(page: TalentFragment['pages'][number]) {
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              active: false,
            },
          ],
        },
      })
      .subscribe();
  }

  markUnread(page: TalentFragment['pages'][number]) {
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              read: false,
            },
          ],
        },
      })
      .subscribe();
  }

  // only active pages can be moved by button
  movePageUp(page: TalentFragment['pages'][number]) {
    const currentIndex = this.talent().pages.indexOf(page);
    if (currentIndex > 0) {
      this.talentUpdateGql
        .mutate({
          input: {
            uuid: this.talent().uuid,
            pages: [
              {
                uuid: page.uuid,
                type: ArrayActionEnum.CHANGED,
                moveToIndex: currentIndex - 1,
              },
            ],
          },
        })
        .subscribe();
    }
  }

  // only active pages can be moved by button
  movePageDown(page: TalentFragment['pages'][number]) {
    const currentIndex = this.talent().pages.indexOf(page);
    if (currentIndex < this.talent().pages.length - 1) {
      this.talentUpdateGql
        .mutate({
          input: {
            uuid: this.talent().uuid,
            pages: [
              {
                uuid: page.uuid,
                type: ArrayActionEnum.CHANGED,
                moveToIndex: currentIndex + 1,
              },
            ],
          },
        })
        .subscribe();
    }
  }

  titleChanged(newTitle: string, page: TalentFragment['pages'][number]) {
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              title: newTitle,
            },
          ],
        },
      })
      .subscribe();
  }

  onUploadDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const files = Array.from(event.dataTransfer.files);
      files.forEach((file) => this.uploadToServer(file));
      event.dataTransfer.clearData();
    }
  }

  cancelUpload(fileName: string) {
    const uploadSession = this.uploads()[fileName];
    if (uploadSession) {
      uploadSession.uploadSub?.unsubscribe();
      this.resetUpload(fileName);
    }
  }

  resetUpload(fileName: string) {
    const currentUploads = this.uploads();
    delete currentUploads[fileName];
    this.uploads.set({ ...currentUploads });

    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private handleUploadError(error: HttpErrorResponse) {
    let userMessage = 'An error occurred';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('A client-side or network error occurred:', error.error);
      userMessage = 'A client-side or network error occurred';
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
      if (error.error?.message && typeof error.error.message === 'string') {
        userMessage = error.error.message;
      }
    }
    return userMessage;
  }

  async uploadToServer(file: File) {
    const fileName = file.name;

    const formData = new FormData();
    formData.append('file', file);

    this.uploads.set({
      ...this.uploads(),
      [fileName]: {
        fileName,
        uploadProgress: 1,
        generatingPagesProgress: 0,
        uploadSub: undefined,
        uploadPhase: 'uploadingFile',
      },
    });

    const upload$ = this.http
      .post<{ uuid: string }>(
        environment.mediaUrl + '/unlinked-document/upload',
        formData,
        {
          headers: {
            Authorization: `Bearer ${await this.userService.getAuthToken()}`,
            context: 'talentFiles',
          },
          reportProgress: true,
          observe: 'events',
        },
      )
      .pipe(
        catchError((error) => {
          const message = this.handleUploadError(error);
          this.uploads.set({
            ...this.uploads(),
            [fileName]: {
              fileName,
              uploadProgress: this.uploads()[fileName].uploadProgress,
              generatingPagesProgress: 0,
              uploadSub: undefined,
              uploadPhase: 'uploadingFile',
              errorMessage: message,
            },
          });
          return throwError(
            () => new Error('Upload failed, stopping further execution.'),
          );
        }),
        finalize(() => {
          const hasErrorMessage = Object.values(this.uploads()).some(
            (upl) => upl.errorMessage,
          );
          if (!hasErrorMessage && this.fileInput) {
            this.fileInput.nativeElement.value = '';
          }
        }),
      );

    const uploadSub = upload$.subscribe(async (event) => {
      if (event.type == HttpEventType.UploadProgress) {
        const progress = Math.round(100 * (event.loaded / (event.total ?? 1)));
        if (progress > 0) {
          this.uploads.set({
            ...this.uploads(),
            [fileName]: {
              fileName,
              uploadProgress: progress,
              generatingPagesProgress: 0,
              uploadSub,
              uploadPhase: 'uploadingFile',
            },
          });
        }
      } else if (event.type === HttpEventType.Response) {
        const fileMediaObjectUuid = event?.body?.uuid;
        if (!fileMediaObjectUuid) {
          throw new Error('No uuid returned from server');
        }

        // 2. generate pages
        this.uploads.set({
          ...this.uploads(),
          [fileName]: {
            fileName,
            uploadProgress: 100,
            generatingPagesProgress: 1,
            uploadSub,
            uploadPhase: 'generatingPages',
          },
        });
        // simulate generatingPagesProgress
        const generatingPagesIntervalSimulation = setInterval(() => {
          const simulatedProgress =
            (this.uploads()?.[fileName]?.generatingPagesProgress ?? 0) + 1;
          if (simulatedProgress >= 100) {
            clearInterval(generatingPagesIntervalSimulation);
          }
          this.uploads.set({
            ...this.uploads(),
            [fileName]: {
              fileName,
              uploadProgress: 100,
              generatingPagesProgress: simulatedProgress,
              uploadSub,
              uploadPhase: 'generatingPages',
            },
          });
        }, 200);
        const generatePagesRequest$ = this.http.post<{
          source: { uuid: string };
          pages: { uuid: string }[];
        }>(
          environment.mediaUrl +
            `/unlinked-document/generate-pdf-pages/${fileMediaObjectUuid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await this.userService.getAuthToken()}`,
              context: 'talentPages',
            },
          },
        );
        generatePagesRequest$.subscribe({
          next: (response) => {
            clearInterval(generatingPagesIntervalSimulation);
            // 3. save to files
            this.uploads.set({
              ...this.uploads(),
              [fileName]: {
                fileName,
                uploadProgress: 100,
                generatingPagesProgress: 100,
                uploadSub,
                uploadPhase: 'savingFile',
              },
            });
            this.talentUpdateGql
              .mutate({
                input: {
                  uuid: this.talent().uuid,
                  files: [
                    {
                      uuid: '',
                      title: fileName,
                      type: ArrayActionEnum.ADDED,
                      mediaObjectUuid: fileMediaObjectUuid,
                    },
                  ],
                },
              })
              .subscribe({
                next: (result) => {
                  const file = result.data?.talentUpdate?.files?.find(
                    (file) => file.mediaObject.uuid === fileMediaObjectUuid,
                  );
                  if (!file) {
                    // this means file was silently deduplicated (aka file already uploaded)
                    this.uploads.set({
                      ...this.uploads(),
                      [fileName]: {
                        fileName,
                        uploadProgress: this.uploads()[fileName].uploadProgress,
                        generatingPagesProgress:
                          this.uploads()[fileName].generatingPagesProgress,
                        uploadSub,
                        uploadPhase: 'savingFile',
                        errorMessage: this.i18next.transform(
                          'thisDocumentHasAlreadyBeenUploaded',
                        ),
                      },
                    });
                    return throwError(
                      () =>
                        new Error(
                          'File already uploaded, stopping further execution.',
                        ),
                    );
                  }

                  // 4. save pages
                  const pageUuids = response.pages.map((page) => page.uuid);
                  this.talentUpdateGql
                    .mutate({
                      input: {
                        uuid: this.talent().uuid,
                        pages: pageUuids.map((pageUuid, index) => ({
                          uuid: '',
                          title: generateTitleFromFilename(fileName),
                          type: ArrayActionEnum.ADDED,
                          mediaObjectUuid: pageUuid,
                          talentFileUuid: file.uuid,
                          page: index + 1,
                          read: true,
                        })),
                      },
                    })
                    .subscribe({
                      next: () => {
                        this.uploads.set({
                          ...this.uploads(),
                          [fileName]: {
                            fileName,
                            uploadProgress:
                              this.uploads()[fileName].uploadProgress,
                            generatingPagesProgress:
                              this.uploads()[fileName].generatingPagesProgress,
                            uploadSub,
                            uploadPhase: 'done',
                          },
                        });
                      },
                      error: (error) => {
                        const message = this.handleUploadError(error);
                        this.uploads.set({
                          ...this.uploads(),
                          [fileName]: {
                            fileName,
                            uploadProgress:
                              this.uploads()[fileName].uploadProgress,
                            generatingPagesProgress:
                              this.uploads()[fileName].generatingPagesProgress,
                            uploadSub,
                            uploadPhase: 'savingPages',
                            errorMessage: message,
                          },
                        });
                      },
                    });
                },
                error: (error) => {
                  const message = this.handleUploadError(error);
                  this.uploads.set({
                    ...this.uploads(),
                    [fileName]: {
                      fileName,
                      uploadProgress: this.uploads()[fileName].uploadProgress,
                      generatingPagesProgress:
                        this.uploads()[fileName].generatingPagesProgress,
                      uploadSub,
                      uploadPhase: 'done',
                      errorMessage: message,
                    },
                  });
                },
              });
          },
          error: (error) => {
            clearInterval(generatingPagesIntervalSimulation);
            const message = this.handleUploadError(error);
            this.uploads.set({
              ...this.uploads(),
              [fileName]: {
                fileName,
                uploadProgress: this.uploads()[fileName].uploadProgress,
                generatingPagesProgress:
                  this.uploads()[fileName].generatingPagesProgress,
                uploadSub,
                uploadPhase: 'generatingPages',
                errorMessage: message,
              },
            });
          },
        });
      }
    });
  }
}
