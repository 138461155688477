import {
  ChangelogFieldDisplayType,
  TalentHistoryEntryFragment,
  TalentSortEnum,
  TalentTableColumnIdEnum,
  TalentUpdateInput,
} from '../../graphql/generated';
import { SortKeyWithColumnLabel } from '../../shared/generic-modules/advanced-data-list/advanced-data-view.types';
import { unijobIcon } from '@intemp/unijob-ui2/particle/icon/unijob-icon.model';
import { WritableSignal } from '@angular/core';

export type TableColumns = TalentTableColumnIdEnum;
export type ColumnSortKeys = Partial<Record<TableColumns, TalentSortEnum>>;
export type TalentColumnTitles = Record<TalentTableColumnIdEnum, string>;

export type TalentSortKeyWithColumnLabel = SortKeyWithColumnLabel & {
  value: TalentSortEnum;
  label: string;
};

export enum TalentTabEnum {
  PROFILE = 'profile',
  DOCUMENTS = 'documents',
}

export type PreparedTalentChangeLog = NonNullable<
  Omit<TalentHistoryEntryFragment, 'mutation'>
> & {
  fieldName?: keyof TalentUpdateInput;
  fieldLabel?: string;
  groupName?: string;
  groupLabel?: string;
  displayedPreviousValue?: string;
  displayedCurrentValue?: string;
  mutationFieldType?: ChangelogFieldDisplayType;
  contentIsIdentical?: boolean;
  hideRevertButton?: boolean;
  action?: ChangelogState;
  mutation: {
    version: number;
    mutatedAt: string;
    input: TalentUpdateInput;
  } | null;
};

export type ChangelogState =
  | 'added'
  | 'created'
  | 'changed'
  | 'removed'
  | 'activated'
  | 'deactivated'
  | 'restored'
  | 'published'
  | 'archived'
  | 'scheduled'
  | 'unscheduled'
  | 'generated';

export type TalentAllowedAction = {
  label: string;
  triggerAction: string;
  shortLabel?: string;
  icon?: unijobIcon;
  action: () => void;
  disabled?: boolean;
  disabledReason?: WritableSignal<string>;
};

export type StructuredTalentAllowedActions = {
  secondaryActions: TalentAllowedAction[];
  primaryActions: TalentAllowedAction[];
  sheetActions: TalentAllowedAction[];
};
