import {
  ChangelogFieldDisplayType,
  TalentDriversLicenseInput,
  TalentFragment,
  TalentHistoryEntryFragment,
  TalentUpdateInput,
} from '../../../../graphql/generated';
import { PreparedTalentChangeLog } from '../../../../pages/talents/talent.types';
import { getCurrentPreviousValue } from './getCurrentPreviousValue';
import { getChangelogDisplayFunctions } from './display-functions';
import { TalentArrayFields } from '../../talent-sheet/talent-sheet.types';
import { resolveChangelogAction } from './resolveChangelogAction';
import { revertArrayResultIsIdentical } from './revertArrayResultIsIdentical';
import { canRevertChangelog } from './canRevertChangelog';
import { CountriesService } from '../../../../core/services/countries.service';
import {
  getChangelogFieldLabel,
  getChangelogGroupLabel,
} from './getChangelogFieldLabel';

export function prepareTalentChangLogForArrayField(
  entry: TalentHistoryEntryFragment,
  fieldName: keyof TalentArrayFields,
  talent: TalentFragment,
  input: TalentUpdateInput,
  countriesService: CountriesService,
): PreparedTalentChangeLog {
  const inputValue = input?.[fieldName as keyof TalentArrayFields]?.[0];
  if (!inputValue) {
    console.error(
      `should not happen - inputValue is expected to be there for ${fieldName}`,
    );
    return {
      ...entry,
      fieldName,
    };
  }

  const { currentValue, previousValue } = getCurrentPreviousValue(
    inputValue as TalentDriversLicenseInput,
    entry.revertInput?.[fieldName]?.[0] as TalentDriversLicenseInput,
    //@ts-ignore - inputValue is a union
    getChangelogDisplayFunctions(countriesService)[fieldName],
  );

  const action = resolveChangelogAction(currentValue, previousValue);

  return {
    ...entry,
    fieldName,
    fieldLabel: getChangelogFieldLabel(fieldName),
    groupLabel: getChangelogGroupLabel(fieldName),
    displayedCurrentValue: currentValue,
    displayedPreviousValue: previousValue,
    revertInput: entry.revertInput,
    action,
    mutationFieldType: ChangelogFieldDisplayType.STRING,
    contentIsIdentical: revertArrayResultIsIdentical(
      fieldName,
      entry,
      talent[fieldName],
    ),
    hideRevertButton: !canRevertChangelog(fieldName, action),
  };
}
