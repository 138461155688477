import { Component, inject } from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { TalentChildrenComponent } from '../talent-personal-data/talent-children/talent-children.component';
import { TalentSourcesComponent } from './talent-sources/talent-sources.component';
import { TalentRecommendationsComponent } from './talent-recommendations/talent-recommendations.component';
import { TalentInternalNoteComponent } from './talent-internal-note/talent-internal-note.component';
import { TalentStatusIndicatorComponent } from '../talent-status-indicator/talent-status-indicator.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { TooltipModule } from '@intemp/unijob-ui';
import { TalentFormEditService } from '../services/talentFormEdit.service';
import { environment } from '../../../../../environments/environment';
import {
  ButtonOutlineComponent,
  PseudoInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-intern',
  templateUrl: './talent-intern.component.html',
  imports: [
    I18NextModule,
    SharedDefaultModule,
    TalentChildrenComponent,
    TalentSourcesComponent,
    TalentRecommendationsComponent,
    TalentInternalNoteComponent,
    TalentStatusIndicatorComponent,
    TooltipModule,
    ButtonOutlineComponent,
    PseudoInputComponent,
  ],
})
export class TalentInternComponent {
  formService = inject(TalentFormEditService);
  i18next = inject(I18NextPipe);
  clipboard = inject(Clipboard);
  toastService = inject(ToastService);

  copyTalentNumberToClipboard(uuid: string) {
    this.clipboard.copy(uuid);
    this.toastService.makeToast({
      type: 'SUCCESS',
      message: this.i18next.transform('talentNumberCopied'),
      duration: 6000,
    });
  }

  protected readonly environment = environment;
}
