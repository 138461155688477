import { WorkAvailabilityEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getAvailabilityOptions = () => {
  return [
    {
      value: WorkAvailabilityEnum.ACTIVE,
      label: i18next.t('WorkAvailabilityEnum.ACTIVE'),
    },
    {
      value: WorkAvailabilityEnum.IN_EMPLOYMENT,
      label: i18next.t('WorkAvailabilityEnum.IN_EMPLOYMENT'),
    },
    {
      value: WorkAvailabilityEnum.PLACED,
      label: i18next.t('WorkAvailabilityEnum.PLACED'),
    },
    {
      value: WorkAvailabilityEnum.PASSIVE,
      label: i18next.t('WorkAvailabilityEnum.PASSIVE'),
    },
    {
      value: WorkAvailabilityEnum.BLACKLISTED,
      label: i18next.t('WorkAvailabilityEnum.BLACKLISTED'),
    },
  ];
};
