import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { randomId } from '../../helpers/functions/randomId';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../global-sheets/global-sheets.service';
import { VacanciesService } from '../../../models/unibase/vacancies/vacancies.service';
import { firstValueFrom } from 'rxjs';
import { TalentsService } from '../../../core/services/talents/talents.service';
import { UserService } from '../../../models/shared/user/user.service';
import { unijobIcon } from '@intemp/unijob-ui2/particle/icon/unijob-icon.model';
import { MatchesService } from '../../../core/services/matches/matches.service';

export enum GlobalAddActions {
  CREATE_VACANCY = 'CREATE_VACANCY',
  CREATE_TALENT = 'CREATE_TALENT',
}

export type AllowedGlobalAddActions = Set<GlobalAddActions>;

export interface GlobalAddAction {
  label: string;
  iconName?: unijobIcon;
  cyAttribute: string;
  action: () => void;
}

@Component({
  selector: 'app-global-add',
  templateUrl: './global-add.component.html',
  styleUrls: ['./global-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAddComponent implements OnInit {
  @Input() allowedActions: AllowedGlobalAddActions =
    new Set<GlobalAddActions>();

  addMenuId = randomId();
  popupMenuActions: GlobalAddAction[] = [];

  constructor(
    private globalSheetsService: GlobalSheetsService,
    private vacanciesService: VacanciesService,
    private talentsService: TalentsService,
    private matchesService: MatchesService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.popupMenuActions = [];
    this.allowedActions.forEach((allowedAction) => {
      let action: GlobalAddAction | undefined = undefined;
      switch (allowedAction) {
        case GlobalAddActions.CREATE_VACANCY:
          if (!this.userService.canLoadPages?.VACANCIES) break;
          action = {
            label: 'newVacancy',
            cyAttribute: 'new-vacancy-button',
            action: async () => {
              const vacancy = await firstValueFrom(
                this.vacanciesService.createVacancy$(
                  await this.vacanciesService.getDefaultValues(),
                ),
              );
              this.globalSheetsService.openGlobalSheet({
                type: GlobalSheetTypeEnum.VACANCY_EDIT,
                uuid: vacancy.uuid,
              });
            },
          };
          break;
        // case GlobalAddActions.CREATE_TALENT:
        //   if (!this.userService.canLoadPages?.TALENTS) break;
        //   action = {
        //     label: 'newTalent',
        //     cyAttribute: 'new-talent-button',
        //     action: async () => {
        //       const talent = await firstValueFrom(
        //         this.talentsService.createTalent$(),
        //       );
        //       this.globalSheetsService.openGlobalSheet({
        //         type: GlobalSheetTypeEnum.TALENT_EDIT,
        //         uuid: talent.uuid,
        //         action: 'profile',
        //       });
        //     },
        //   };
        //   break;
      }
      if (action) this.popupMenuActions.push(action);
    });
  }
}
