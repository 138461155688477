import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CheckboxModule,
  ContextMenuModule,
  DatePickerModule,
  ErrorHintModule,
  InfoBoxModule,
  InputTextareaModule,
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
  SwitchModule,
  TabModule,
  TooltipModule,
  UjSelectInputModule,
  WysiwygModule,
} from '@intemp/unijob-ui';
import { AnchorNavigationModule } from '../anchor-navigation/anchor-navigation.module';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { VacancyCompanyLocationSelectModule } from './company-location-select/vacancy-company-location-select.module';
import { VacancyCompanyContactSelectModule } from './contact-location-select/vacancy-company-contact-select.module';
import { VacancyDetailComponent } from './vacancy-detail.component';
import { VacancyDetailCustomerFormComponent } from './vacancy-detail-customer-form/vacancy-detail-customer-form.component';
import { VacancyDetailAdvertisementComponent } from './vacancy-detail-advertisement/vacancy-detail-advertisement.component';
import { VacancyDetailCriteriaComponent } from './vacancy-detail-criteria/vacancy-detail-criteria.component';
import { VacancyDetailInternComponent } from './vacancy-detail-intern/vacancy-detail-intern.component';
import { VacancyLanguageRequirementRowComponent } from './vacancy-language-requirment-row/vacancy-language-requirement-row.component';
import { VacancyPublishSheetComponent } from './vacancy-publish-sheet/vacancy-publish-sheet.component';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { VacancyArchiveSheetComponent } from './vacancy-archive-sheet/vacancy-archive-sheet.component';
import { VacancySelectArchiveReasonComponent } from './vacancy-select-archive-reason/vacancy-select-archive-reason.component';
import { VacancyResponsibilityModule } from '../vacancy-responsibility/vacancy-responsibility.module';
import { BigSelectModule } from '../big-select/big-select.module';
import { CommissionInputModule } from '../commission-input/commission-input.module';
import { VacancySalaryInputModule } from '../vacancy-salary-input/vacancy-salary-input.module';
import { VacanciesStatusIndicatorModule } from '../vacancies-status-indicator/vacancies-status-indicator.module';
import { VacancyChangelogModule } from '../vacancy-changelog/vacancy-changelog.module';
import { UserInfoContextMenuModule } from '../user/user-info-context-menu/user-Info-context-menu.module';
import { LetDirective } from '@ngrx/component';
import { VacancyPromoteModule } from '../vacancy-promote/vacancy-promote.module';
import { VacancyStatsModule } from '../vacancy-stats/vacancy-stats.module';
import {
  AccordionComponent,
  ButtonLinkComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  FormFieldComponent,
  IconTagComponent,
  InputContainerComponent,
  ListItemComponent,
  ModalComponent,
  PseudoInputComponent,
  SelectComponent,
  SelectionListComponent,
  SimpleSelectComponent,
  TagComponent,
  TextInputComponent,
  TextSelectedItemComponent,
} from '@intemp/unijob-ui2';
import { CurrentUserAvatarComponent } from '../../components/current-user-avatar/current-user-avatar.component';
import { VacancyWebsitePreviewComponent } from './vacancy-website-preview/vacancy-website-preview.component';
import { OverlayWrapperComponent } from '../overlay-wrapper/overlay-wrapper.component';

@NgModule({
  declarations: [
    VacancyDetailComponent,
    VacancyDetailCustomerFormComponent,
    VacancyDetailAdvertisementComponent,
    VacancyDetailCriteriaComponent,
    VacancyDetailInternComponent,
    VacancyLanguageRequirementRowComponent,
    VacancyPublishSheetComponent,
    VacancyArchiveSheetComponent,
    VacancySelectArchiveReasonComponent,
  ],
  exports: [VacancyDetailComponent],
  imports: [
    VacancyResponsibilityModule,
    SharedDefaultModule,
    SheetModule,
    TabModule,
    OverflowGradientModule,
    FormsModule,
    AnchorNavigationModule,
    DatePickerModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    SwitchModule,
    WysiwygModule,
    UjSelectInputModule,
    VacancyCompanyLocationSelectModule,
    VacancyCompanyContactSelectModule,
    RadioButtonModule,
    InfoBoxModule,
    ContextMenuModule,
    UserAvatarModule,
    TooltipModule,
    InputTextareaModule,
    BigSelectModule,
    ErrorHintModule,
    CommissionInputModule,
    VacancySalaryInputModule,
    VacanciesStatusIndicatorModule,
    VacancyChangelogModule,
    UserInfoContextMenuModule,
    LetDirective,
    VacancyPromoteModule,
    VacancyStatsModule,
    SimpleSelectComponent,
    SelectComponent,
    SelectionListComponent,
    TextSelectedItemComponent,
    ListItemComponent,
    CurrentUserAvatarComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ButtonLinkComponent,
    IconTagComponent,
    TagComponent,
    ModalComponent,
    TextInputComponent,
    InputContainerComponent,
    PseudoInputComponent,
    FormFieldComponent,
    AccordionComponent,
    VacancyWebsitePreviewComponent,
    OverlayWrapperComponent,
  ],
})
export class VacancyDetailModule {}
