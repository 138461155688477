import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  FormFieldComponent,
  SimpleSelectComponent,
} from '@intemp/unijob-ui2';
import { DatePipe, NgIf } from '@angular/common';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import {
  InfoBoxModule,
  InputTextareaModule,
  SheetModule,
  SheetService,
} from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatchFragment,
  MatchStatusEnum,
  SexEnum,
} from '../../../../graphql/generated';
import { getMatchArchiveReasonsTextOptions } from '../helpers/getMatchArchiveReasonsTextOptions';
import { MatchesService } from '../../../../core/services/matches/matches.service';
import { MatchArchiveReasonEnum } from '@libs/shared/enums/MatchArchiveReason.enum';
import { toSignal } from '@angular/core/rxjs-interop';
import { formControlValue$ } from '../../../helpers/functions/formControlValues';

@Component({
  standalone: true,
  selector: 'app-match-archive-sheet',
  templateUrl: './match-archive-sheet.component.html',
  imports: [
    ButtonSolidComponent,
    DatePipe,
    I18NextModule,
    InfoBoxModule,
    InputTextareaModule,
    NgIf,
    SharedDefaultModule,
    SheetModule,
    ReactiveFormsModule,
    SimpleSelectComponent,
    FormFieldComponent,
    ButtonOutlineComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchArchiveSheetComponent {
  matchesService = inject(MatchesService);
  sheetService = inject(SheetService);
  i18next = inject(I18NextPipe);

  sheetId = input.required<string>();
  match = input<MatchFragment | undefined | null>(null);
  sheetClosed = output<boolean>();
  MatchArchiveReasonEnum = MatchArchiveReasonEnum;

  mailToLink = computed(() => {
    const email = this.match()?.talent?.emails?.[0];
    if (!email) {
      return false;
    }
    if (
      !(
        this.archiveReasonSignal() === MatchArchiveReasonEnum.WE_CANCELLED ||
        this.archiveReasonSignal() === MatchArchiveReasonEnum.CLIENT_CANCELLED
      )
    ) {
      return false;
    }

    let subject = '';
    if (this.match()?.vacancy) {
      subject = encodeURIComponent(
        this.i18next.transform('jobApplicantCancellation.subject', {
          vacancyTitle: this.match()?.vacancy?.title,
        }),
      );
    } else {
      subject = encodeURIComponent(
        this.i18next.transform('jobApplicantCancellationBlind.subject'),
      );
    }

    let salutation = '';

    const sex = this.match()?.talent?.sex ?? SexEnum.OTHER;
    if (this.formalitySignal() === 'formal') {
      salutation = this.i18next.transform(`formalSalutation.${sex}`, {
        firstName: this.match()?.talent?.firstName,
        lastName: this.match()?.talent?.lastName,
      });
    } else {
      salutation = this.i18next.transform(`informalSalutation.${sex}`, {
        firstName: this.match()?.talent?.firstName,
      });
    }

    let body = '';
    if (this.formalitySignal() === 'formal' && !this.match()?.vacancy) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellationBlind.paragraphsFormal',
            {
              joinArrays: '\n\n',
            },
          ),
      );
    } else if (
      this.formalitySignal() === 'informal' &&
      !this.match()?.vacancy
    ) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellationBlind.paragraphsInformal',
            {
              joinArrays: '\n\n',
            },
          ),
      );
    } else if (
      this.formalitySignal() === 'formal' &&
      this.archiveReasonSignal() === MatchArchiveReasonEnum.WE_CANCELLED
    ) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellation.paragraphsFormal.WE_CANCELED',
            {
              joinArrays: '\n\n',
              vacancyTitle: this.match()?.vacancy?.title,
            },
          ),
      );
    } else if (
      this.formalitySignal() === 'informal' &&
      this.archiveReasonSignal() === MatchArchiveReasonEnum.WE_CANCELLED
    ) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellation.paragraphsInformal.WE_CANCELED',
            {
              joinArrays: '\n\n',
              vacancyTitle: this.match()?.vacancy?.title,
            },
          ),
      );
    } else if (
      this.formalitySignal() === 'formal' &&
      this.archiveReasonSignal() === MatchArchiveReasonEnum.CLIENT_CANCELLED
    ) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellation.paragraphsFormal.CLIENT_CANCELLED',
            {
              joinArrays: '\n\n',
              vacancyTitle: this.match()?.vacancy?.title,
              companyLegalName:
                this.match()?.company?.companyLocation?.companyReference
                  ?.legalName,
            },
          ),
      );
    } else if (
      this.formalitySignal() === 'informal' &&
      this.archiveReasonSignal() === MatchArchiveReasonEnum.CLIENT_CANCELLED
    ) {
      body = encodeURIComponent(
        salutation +
          '\n\n' +
          this.i18next.transform(
            'jobApplicantCancellation.paragraphsInformal.CLIENT_CANCELLED',
            {
              joinArrays: '\n\n',
              vacancyTitle: this.match()?.vacancy?.title,
              companyLegalName:
                this.match()?.company?.companyLocation?.companyReference
                  ?.legalName,
            },
          ),
      );
    }

    return `mailto:${email}.ch?subject=${subject}&body=${body}`;
  });

  archiveReason = new FormControl<MatchArchiveReasonEnum>(
    MatchArchiveReasonEnum.WE_CANCELLED,
  );
  archiveReasonSignal = toSignal(formControlValue$(this.archiveReason));
  formality = new FormControl<'formal' | 'informal'>('informal');
  formalitySignal = toSignal(formControlValue$(this.formality));
  formalityOptions = [
    { value: 'informal', label: this.i18next.transform('youInformal') },
    { value: 'formal', label: this.i18next.transform('youFormal') },
  ];
  formGroup = new FormGroup({
    archiveReason: this.archiveReason,
  });

  archiveReasonsOptions = getMatchArchiveReasonsTextOptions();

  archiveMatch() {
    const matchUuid = this.match()?.uuid;
    if (!matchUuid) {
      console.error('Match UUID is missing');
      return;
    }
    this.matchesService.archiveMatch({
      uuid: matchUuid,
      archiveReason: this.archiveReason.value,
      status: MatchStatusEnum.ARCHIVED,
    });
    this.closeSheet();
  }

  closeSheet() {
    this.sheetService.close(this.sheetId());
    this.sheetClosed.emit(true);
  }
}
