import { Component } from '@angular/core';
import { SharedDefaultModule } from '../../modules/shared-default/shared-default.module';
import { IconComponent } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-img-loader-spinner',
  templateUrl: './img-loader-spinner.component.html',
  styleUrls: ['./img-loader-spinner.component.css'],
  standalone: true,
  imports: [SharedDefaultModule, IconComponent],
})
export class SpinnerComponent {}
