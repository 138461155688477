import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponsiveService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, combineLatest, debounceTime, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import {
  LanguageProficiencyEnum,
  UniBaseXVacancyUpdateInput,
  VacancyDetailUniBaseXVacancyFragment,
  VacancyEmploymentTypeEnum,
  VacancyLanguageRequirement,
  VacancyPositionTypeEnum,
  VacancyPublishableFieldsEnum,
} from '../../../../graphql/generated';
import { VacanciesService } from '../../../../models/unibase/vacancies/vacancies.service';
import { getTranslatedVacancyLocaleOptions } from '../../../../pages/vacancies/helpers/getTranslatedVacancyLocaleOptions';
import { clampValue } from '../../../../shared/helpers/functions/clampValue';
import { randomId } from '../../../../shared/helpers/functions/randomId';
import { LanguageService } from '../../../../core/services/language.service';
import { disableFormGroup } from '../../../helpers/functions/disableFormGroup';
import { findMismatches } from '../../../helpers/functions/findMismatches';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { parseValueToNumber } from '../../../helpers/functions/parseValueToNumber';
import { patchFormControl } from '../../../helpers/functions/patchFormControl';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { customValidators } from '../../shared-forms/customValidators';
import { VacancySalaryInputComponent } from '../../vacancy-salary-input/vacancy-salary-input.component';
import {
  SubmitVacancyUpdateInput,
  VacancyCriteriaItemFormData,
} from '../../../../pages/vacancies/vacancy.types';
import { defaultLanguageEntryID } from '../constants/defaultLanguageEntryID.constants';
import { FocusTrackerDirective } from '../focusTracker.directive';
import { getActiveLanguageAsVacancyLanguage } from '../helpers/getActiveLanguageAsVacancyLanguage';
import { validateFormControl } from '../helpers/validateFormControl';
import { LanguagesFormGroup } from '../vacancy-form';
import { WritableDeep } from 'type-fest';
import { isBefore, isSameDay } from 'date-fns';
import { getEmploymentTypeTextOptions } from '../helpers/getEmploymentTypeTextOptions';
import { getEmploymentTermTextOptions } from '../helpers/getEmploymentTermTextOptions';
import { getPositionTypeTextOptions } from '../helpers/getPositionTypeTextOptions';
import { getLanguageRequirementsTextOptions } from '../helpers/getLanguageRequirementsTextOptions';
import { TextOption } from '@intemp/unijob-ui2';

@Component({
  selector: 'app-vacancy-detail-criteria',
  templateUrl: './vacancy-detail-criteria.component.html',
})
export class VacancyDetailCriteriaComponent implements OnInit, OnChanges {
  @Input() vacancy?: VacancyDetailUniBaseXVacancyFragment;
  @Input({ required: true }) criteriaData!: VacancyCriteriaItemFormData;
  @Input({ required: true }) formRef!: FormGroup;
  @Input() formIsDisabled = false;
  @Input() isNewVacancy = false;

  workloadFlexible = new FormControl<boolean | null>(null);
  employmentType = new FormControl<VacancyEmploymentTypeEnum | null>(null);
  employmentStart = new FormControl<Date[] | null>(null);
  employmentEnd = new FormControl<Date[] | null>(null);
  fixedTerm = new FormControl<boolean>(false);
  workloadPercentageMin = new FormControl<number | string | null>(null);
  workloadPercentageMax = new FormControl<number | string | null>(null);
  workloadPercentage = new FormControl<number | string | null>(null);
  positionType = new FormControl<VacancyPositionTypeEnum | null>(null);
  languageRequirements = new FormArray<LanguagesFormGroup>([]);
  languageToAdd = new FormControl<string | undefined>(undefined);
  showWorkloadInPublicTitle = new FormControl<boolean | null>(null);

  VacancyPublishableFieldsEnum = VacancyPublishableFieldsEnum;
  hasChanges(field: VacancyPublishableFieldsEnum) {
    return this.vacancy?.unpublishedChanges?.includes(field);
  }

  formGroup = new FormGroup({
    workloadFlexible: this.workloadFlexible,
    employmentType: this.employmentType,
    employmentStart: this.employmentStart,
    employmentEnd: this.employmentEnd,
    fixedTerm: this.fixedTerm,
    workloadPercentageMin: this.workloadPercentageMin,
    workloadPercentageMax: this.workloadPercentageMax,
    workloadPercentage: this.workloadPercentage,
    positionType: this.positionType,
    languageRequirements: this.languageRequirements,
    languageToAdd: this.languageToAdd,
    showWorkloadInPublicTitle: this.showWorkloadInPublicTitle,
  });

  autoSaved: { [key: string]: boolean } = {};

  employmentTypeOptions = getEmploymentTypeTextOptions(this.i18nPipe);

  employmentTermOptions = getEmploymentTermTextOptions(this.i18nPipe);

  positionTypeOptions = getPositionTypeTextOptions(this.i18nPipe);

  allOptions = getTranslatedVacancyLocaleOptions(
    getActiveLanguageAsVacancyLanguage(this.languageService),
  ).map(({ value, name }) => ({ value, label: name }));
  languageOptions: TextOption<string>[] = this.allOptions;

  languageProficiencyEnumOptions = getLanguageRequirementsTextOptions(
    this.i18nPipe,
  );

  addLanguageOptions: { value: string; label: string }[] = [];
  selectedLanguages: string[] = [];

  @Output() triggerAutoSave = new EventEmitter<
    Omit<UniBaseXVacancyUpdateInput, 'vacancyUuid'>
  >();

  isSmDown$ = this.responsiveService.isSmDown$;

  programmaticUpdate = new BehaviorSubject<boolean>(false);
  hintErrorMessages: Record<string, string> = {};

  keysThatNeedToBeParsedToNumber: { [key: string]: boolean } = {
    workloadPercentageMin: true,
    workloadPercentageMax: true,
    workloadPercentage: true,
  };

  @ViewChildren(FocusTrackerDirective)
  focusTrackers!: QueryList<FocusTrackerDirective>;

  @ViewChild(VacancySalaryInputComponent)
  salaryComponent?: VacancySalaryInputComponent;

  destroyed$ = new Subject<void>();

  constructor(
    private responsiveService: ResponsiveService,
    private languageService: LanguageService,
    private vacanciesService: VacanciesService,
    private i18nPipe: I18NextPipe,
  ) {}

  async ngOnInit() {
    this.updateFormValues();
    this.subscribeFormChanges();
    this.validateInputs();

    this.languageToAdd.valueChanges.subscribe((value) => {
      if (value) {
        this.addLanguage();
        this.languageToAdd.setValue(undefined);
        this.languageToAdd.reset();
        this.filterLanguageOptions();
      }
    });
    this.formRef.setControl('criteria', this.formGroup);
  }

  validateInputs() {
    combineLatest([
      this.workloadFlexible.valueChanges.pipe(
        startWith(this.workloadFlexible.value),
      ),
      this.workloadPercentageMin.valueChanges.pipe(
        startWith(this.workloadPercentageMin.value),
      ),
      this.workloadPercentageMax.valueChanges.pipe(
        startWith(this.workloadPercentageMax.value),
      ),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([flexible, min, max]) => {
        delete this.hintErrorMessages['workloadPercentage'];
        if (!min && !max) {
          return;
        }
        if (flexible) {
          const parsedMin = parseValueToNumber(min);
          const parsedMax = parseValueToNumber(max);

          if (parsedMin === parsedMax) {
            this.hintErrorMessages['workloadPercentage'] =
              'switchToFixedEmployment';
          } else if (parsedMin && parsedMax && parsedMin > parsedMax) {
            this.hintErrorMessages['workloadPercentage'] =
              'fromValueCanNotBeGreaterThanToValue';
          } else if (parsedMin === 0 || parsedMax === 0) {
            this.hintErrorMessages['workloadPercentage'] =
              'workloadCanNotBeZero';
          } else {
            delete this.hintErrorMessages['workloadPercentage'];
          }
        }
      });

    combineLatest([
      this.employmentStart.valueChanges.pipe(
        startWith(this.employmentStart.value),
      ),
      this.employmentEnd.valueChanges.pipe(startWith(this.employmentEnd.value)),
      this.fixedTerm.valueChanges.pipe(startWith(this.fixedTerm.value)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([startDates, endDates, fixedTerm]) => {
        delete this.hintErrorMessages['employmentContract'];
        if (!fixedTerm) return;
        const start = startDates?.[0];
        const end = endDates?.[0];

        if (start && end) {
          const startIsSameOrBefore = this.startIsSameOrBefore(start, end);
          if (!startIsSameOrBefore) {
            this.hintErrorMessages['employmentContract'] =
              'startDateCanNotBeGreaterThanEndDate';
          } else {
            delete this.hintErrorMessages['employmentContract'];
          }
        }
      });
  }

  startIsSameOrBefore(start: Date, end: Date) {
    return isBefore(start, end) || isSameDay(start, end);
  }

  updateFormValues() {
    const {
      workloadFlexible,
      workloadPercentageMin,
      workloadPercentageMax,
      workloadPercentage,
      languageRequirements,
      employmentType,
      positionType,
      employmentStart,
      employmentEnd,
      fixedTerm,
      showWorkloadInPublicTitle,
    } = this.criteriaData;

    this.formGroup.patchValue({
      workloadFlexible,
      workloadPercentageMin,
      workloadPercentageMax,
      workloadPercentage,
      employmentType,
      positionType,
      fixedTerm,
      showWorkloadInPublicTitle,
    });

    if (employmentStart) {
      this.employmentStart.setValue([employmentStart]);
    }
    if (employmentEnd) {
      this.employmentEnd.setValue([employmentEnd]);
    }

    if (languageRequirements?.length) {
      languageRequirements.forEach((languageRequirement) => {
        this.addLanguageRequirementToForm(languageRequirement);
      });
      this.languageRequirements.markAsPristine();
      this.filterLanguageOptions();
    } else if (this.isNewVacancy) {
      this.addLanguageRequirementToForm({
        language: getActiveLanguageAsVacancyLanguage(this.languageService),
        proficiency: LanguageProficiencyEnum.C2,
        uuid: defaultLanguageEntryID,
      });
      this.languageRequirements.markAsPristine();
    }
    this.filterLanguageOptions();

    if (this.formIsDisabled) {
      disableFormGroup(this.formGroup);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.firstChange &&
      changes.criteriaData?.previousValue &&
      changes.criteriaData?.currentValue
    ) {
      this.patchFormValuesOnChange(
        changes.criteriaData.previousValue,
        changes.criteriaData.currentValue,
      );
    }

    if (changes.formIsDisabled) {
      if (changes.formIsDisabled.currentValue) {
        disableFormGroup(this.formGroup);
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }
  }

  patchFormValuesOnChange(
    previous: VacancyCriteriaItemFormData,
    current: VacancyCriteriaItemFormData,
  ) {
    const differences = findMismatches(previous, current);
    if (!differences.length) return;

    this.programmaticUpdate.next(true);

    for (const key of differences) {
      const control = this.formGroup.get(key);
      const areNotSame =
        control?.value !== current[key as keyof VacancyCriteriaItemFormData];
      const isLanguageRequirement = new RegExp(/(languageRequirements)/).test(
        key,
      );
      const isFocused = this.focusTrackers.find((f) => f.targetID === key)
        ?.isFocused$.value;

      if (isFocused) continue;

      if (isLanguageRequirement) {
        this.patchLanguageRequirements(
          key,
          current['languageRequirements'] || [],
        );
      } else if (control && areNotSame) {
        patchFormControl(
          control,
          current[key as keyof VacancyCriteriaItemFormData],
        );
      }
    }
    this.programmaticUpdate.next(false);
  }

  patchLanguageRequirements(
    key: string,
    newLanguageRequirements: readonly VacancyLanguageRequirement[],
  ) {
    const keys = key.split('.');
    const index = Number(key.split('.')[1]);
    const formGroup = this.languageRequirements.at(index);
    if (formGroup && index !== undefined && newLanguageRequirements[index]) {
      if (
        keys.includes('language') &&
        formGroup.value.language !== newLanguageRequirements[index].language
      ) {
        patchFormControl(
          formGroup.controls.language,
          newLanguageRequirements[index].language,
        );
      }
      if (
        keys.includes('proficiency') &&
        formGroup.value.proficiency !==
          newLanguageRequirements[index].proficiency
      ) {
        patchFormControl(
          formGroup.controls.proficiency,
          newLanguageRequirements[index].proficiency,
        );
      }
    } else if (!formGroup && index && newLanguageRequirements[index]) {
      this.addLanguageRequirementToForm({
        language: newLanguageRequirements[index].language,
        proficiency: newLanguageRequirements[index].proficiency,
        uuid: newLanguageRequirements[index].uuid,
      });
      this.filterLanguageOptions();
    } else {
      if (this.languageRequirements.length === newLanguageRequirements.length)
        return;
      this.languageRequirements.controls.forEach((control, idx) => {
        const language = newLanguageRequirements.find(
          (language) => language.uuid === control.value.uuid,
        );
        if (!language) {
          this.languageRequirements.removeAt(idx);
        }
      });
      this.filterLanguageOptions();
    }
  }

  addLanguageRequirementToForm(
    languageRequirement: VacancyLanguageRequirement,
  ) {
    const formGroup = new FormGroup({
      language: new FormControl<string | null>(null),
      proficiency: new FormControl<string | null>(null),
      uuid: new FormControl<string | null>(languageRequirement.uuid),
    });

    const selectedLanguageOption = this.languageOptions.find(
      (option) => option.value === languageRequirement.language,
    )?.value;

    formGroup.controls.language.setValue(selectedLanguageOption || null);

    const selectedLanguageProficiencyOption =
      this.languageProficiencyEnumOptions.find(
        (option) =>
          option.value ===
          LanguageProficiencyEnum[languageRequirement.proficiency],
      )?.value;

    formGroup.controls.proficiency.setValue(
      selectedLanguageProficiencyOption || null,
    );
    this.languageRequirements.push(formGroup);
  }

  filterLanguageOptions() {
    this.selectedLanguages = this.languageRequirements.controls.map(
      (control) => control.value.language,
    );
    this.addLanguageOptions = this.languageOptions.filter(
      (option) => !this.selectedLanguages.includes(option.value),
    );
  }

  addLanguage() {
    const language = this.languageToAdd.value;
    if (language) {
      this.addLanguageRequirementToForm({
        language: language,
        proficiency: LanguageProficiencyEnum.C2,
        uuid: randomId(),
      });
      this.languageRequirements.markAsDirty();
    }
  }

  subscribeFormChanges() {
    this.formGroup.valueChanges
      .pipe(
        filter(() => !this.programmaticUpdate.value),
        debounceTime(saveDebounceMs),
      )
      .subscribe((controls) => {
        const keysToExclude = ['languageToAdd'];
        getObjectKeys(controls).forEach((key) => {
          if (keysToExclude.includes(key)) return;
          const control = this.formGroup.controls[key];
          if (control instanceof FormControl && control.dirty) {
            this.submitInputFormControl(control, key);
          } else if (control instanceof FormArray && control.dirty) {
            if (key === 'languageRequirements') {
              this.submitInputFormArray(control);
            }
          }
        });
      });
  }

  submitInputFormControl(control: FormControl, key: string) {
    let submitDataInput: SubmitVacancyUpdateInput = {};
    if (key === 'employmentStart' || key === 'employmentEnd') {
      const dates = control.value as Date[];
      submitDataInput = {
        [key]: dates.length > 0 ? control.value[0] : null,
      };
    } else {
      submitDataInput = {
        [key]: this.keysThatNeedToBeParsedToNumber[key]
          ? control.value !== null
            ? parseValueToNumber(control.value)
            : null
          : control.value,
      };
    }

    control.markAsPristine();
    this.triggerSave(submitDataInput);
  }

  submitInputFormArray(control: FormArray) {
    const submitDataInput: WritableDeep<SubmitVacancyUpdateInput> = {
      languageRequirements: [],
    };
    const oldLanguagesLength = this.criteriaData.languageRequirements?.length;
    const formLanguagesLength = this.languageRequirements.controls?.length;

    const isNewLanguage = formLanguagesLength > (oldLanguagesLength || 0);

    if (isNewLanguage) {
      const lastLanguageRequirementItem = control.controls[
        control.length - 1
      ] as FormGroup;
      submitDataInput.languageRequirements?.push({
        uuid: lastLanguageRequirementItem.controls.uuid.value,
        language: lastLanguageRequirementItem.controls.language.value,
        proficiency: lastLanguageRequirementItem.controls.proficiency.value,
      });
    } else {
      const formLanguages = control.controls.filter(
        (formGroup) => formGroup.dirty,
      ) as LanguagesFormGroup[];
      submitDataInput.languageRequirements = formLanguages.map(
        (formGroup: LanguagesFormGroup) => {
          const language = formGroup.controls.language.value;
          const proficiency = formGroup.controls.proficiency.value;
          const uuid = formGroup.controls.uuid.value;
          return {
            uuid: uuid ?? randomId(),
            language: language,
            proficiency:
              LanguageProficiencyEnum[
                proficiency as keyof typeof LanguageProficiencyEnum
              ],
          };
        },
      );
    }

    control.markAsPristine();
    this.triggerSave(submitDataInput);
  }

  removeEntry(index: number) {
    const control = this.languageRequirements.at(index);
    const languageToDelete = control.value;
    const submitDataInput: SubmitVacancyUpdateInput = {
      languageRequirements: [
        {
          uuid: languageToDelete.uuid,
          language: languageToDelete.language,
          proficiency: languageToDelete.proficiency,
          delete: true,
        },
      ],
    };
    control.markAsPristine();
    this.triggerSave(submitDataInput);
    this.languageRequirements.removeAt(index);
    this.filterLanguageOptions();
  }

  triggerSave(submitDataInput: SubmitVacancyUpdateInput) {
    this.triggerAutoSave.emit(submitDataInput);
    this.markAsAutoSaved(submitDataInput);
  }

  markAsAutoSaved(submitDataInput: SubmitVacancyUpdateInput) {
    Object.keys(submitDataInput).forEach((key) => {
      this.autoSaved[key] = true;
    });
  }

  public enablePublishedValidation() {
    const { isEmpty } = customValidators;
    this.employmentType.addValidators([isEmpty]);

    this.workloadPercentage.setValidators([]);
    this.workloadPercentageMin.setValidators([]);
    this.workloadPercentageMax.setValidators([]);
    this.employmentStart.setValidators([]);
    this.employmentEnd.setValidators([]);

    const fixedTerm = this.fixedTerm.value;
    if (fixedTerm) {
      const start = this.employmentStart.value?.[0];
      const end = this.employmentEnd.value?.[0];
      if (start && end) {
        const startIsSameOrBefore = this.startIsSameOrBefore(start, end);
        if (!startIsSameOrBefore) {
          this.employmentStart.addValidators([() => ({ invalid: true })]);
        } else {
          this.employmentStart.setErrors(null);
        }
      }
    }

    if (!this.workloadFlexible.value) {
      this.workloadPercentage.addValidators([isEmpty]);
    } else {
      this.workloadPercentageMin.addValidators([
        isEmpty,
        Validators.min(5),
        Validators.max(99),
      ]);
      this.workloadPercentageMax.addValidators([
        isEmpty,
        Validators.min(5),
        Validators.max(100),
      ]);
    }

    if (this.languageRequirements.length === 0) {
      this.languageToAdd.addValidators([isEmpty]);
    } else {
      this.languageToAdd.setErrors(null);
      this.languageToAdd.setValidators([]);
    }

    this.salaryComponent?.enablePublishedValidation();
    this.salaryComponent?.validateForm();
  }

  public validateForm() {
    const controlKeys = getObjectKeys(this.formGroup.controls);
    for (const key of controlKeys) {
      const control = this.formGroup.controls[key];
      if (!control || (control.validator === null && control.valid)) continue;
      validateFormControl(control);
    }

    this.salaryComponent?.validateForPublishing();
  }

  clampPercentageValue(
    controlName: keyof typeof this.formGroup.controls,
    event: any,
  ) {
    const value = clampValue(event.target.value, 0, 100, true);
    this.formGroup.get(controlName)?.setValue(value);
  }
}
