import {
  AfterViewInit,
  Component,
  inject,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DatePickerModule,
  InputTextModule,
  SwitchModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SimpleSelectComponent, TextInputComponent } from '@intemp/unijob-ui2';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { VacancySalaryInputModule } from '../../../vacancy-salary-input/vacancy-salary-input.module';
import { FocusTrackerDirective } from '../../../vacancy-detail/focusTracker.directive';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import { clampValue } from '../../../../helpers/functions/clampValue';
import { getEmploymentTypeTextOptions } from '../../../vacancy-detail/helpers/getEmploymentTypeTextOptions';

@Component({
  standalone: true,
  selector: 'app-talent-preferences-employment',
  templateUrl: './talent-preferences-employment.component.html',
  imports: [
    I18NextModule,
    SharedDefaultModule,
    SimpleSelectComponent,
    ReactiveFormsModule,
    DatePickerModule,
    InputTextModule,
    SwitchModule,
    VacancySalaryInputModule,
    UjSelectInputModule,
    TextInputComponent,
  ],
})
export class TalentPreferencesEmploymentComponent implements AfterViewInit {
  i18nPipe = inject(I18NextPipe);
  formService = inject(TalentFormEditService);

  workloadFlexible = this.formService.workloadFlexible;
  employmentType = this.formService.employmentType;
  workloadPercentage = this.formService.workloadPercentage;
  workloadPercentageMax = this.formService.workloadPercentageMax;
  workloadPercentageMin = this.formService.workloadPercentageMin;

  hintErrorMessages: Record<string, string> = {};

  @Input() formIsDisabled = false;

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  employmentTypeOptions = getEmploymentTypeTextOptions(this.i18nPipe);

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  clampPercentageValue(
    controlName: keyof typeof this.formService.jobPreferences.controls,
    event: any,
  ) {
    const value = clampValue(event.target.value, 0, 100, true);
    this.formService.jobPreferences.get(controlName)?.setValue(value);
  }
}
