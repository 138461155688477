<div
  [formGroup]="formGroup"
  class="uj-flex uj-flex-col uj-gap-f">
  <!-- jobTitle -->
  <div>
    <div>
      <ui-library-form-field
        [label]="'jobTitle' | i18next | firstUppercase"
        [labelFor]="'jobTitle'"
        [dot]="hasChanges(VacancyPublishableFieldsEnum.title)"
        required
        fullWidth>
        <input
          #inputEl
          ui-library-text-input
          id="jobTitle"
          focusTracker="title"
          formControlName="title"
          [placeholder]="'enterJobDescription' | i18next"
          [feedback]="titleControl.errors ? 'invalid' : 'none'"
          type="text"
          data-cy="vacancy-job-title-input" />
      </ui-library-form-field>
      <div class="uj-flex uj-gap-b uj-justify-end uj-items-center uj-mt-b">
        <span
          class="text-s"
          [class.uj-text-ui-negative]="titleIsTooLong()"
          >{{ "character" | i18next }}
          {{
            formGroup.controls.title.value?.length + " / " + maxTitleLength
          }}</span
        >
        <div>
          <unijob-icon
            size="12"
            [class.uj-text-ui-positive]="!titleIsTooLong()"
            [class.uj-text-ui-negative]="titleIsTooLong()"
            icon="smiley"></unijob-icon>
        </div>
      </div>
      <div
        class="uj-mb-d"
        *ngIf="titleControl.invalid">
        <strong class="uj-text-ui-negative uj-flex">
          <unijob-icon
            icon="alert_triangle"
            size="12">
          </unijob-icon>
          <span class="text-s-b uj-ml-b">{{ "checkYourInput" | i18next }}</span>
        </strong>
        <ul class="uj-list-disc uj-pl-f">
          <li
            *ngIf="titleControl.hasError('isEmpty')"
            class="uj-text-ui-negative text-s uj-list-item">
            {{ "validationVacancyTitleIsEmpty" | i18next }}
          </li>
          <li
            *ngIf="titleControl.hasError('titleBannedTerms')"
            class="uj-text-ui-negative text-s uj-list-item">
            {{
              "validationVacancyTitleDoubledTermsProvidedByOtherFields"
                | i18next
            }}
          </li>
          <li
            *ngIf="titleControl.hasError('bannedFillerWordsOrCharacters')"
            class="uj-text-ui-negative text-s uj-list-item">
            {{
              "validationVacancyTitleBannedFillerWordsOrCharacters" | i18next
            }}
          </li>
        </ul>
      </div>
    </div>
    <div class="uj-flex uj-flex-col uj-gap-b uj-pt-b">
      <div class="uj-flex uj-gap-b uj-items-center">
        <ui-library-form-field
          [label]="'jobTitleShown' | i18next | firstUppercase">
        </ui-library-form-field>
        <unijob-icon
          icon="info_circle"
          [tooltip]="'jobTitleShownDescription' | i18next"
          size="20"></unijob-icon>
      </div>
      <span
        class="uj-px-d uj-py-c uj-bg-surface-grape uj-font-semi-bold uj-leading-6 uj-rounded-b"
        *ngIf="
          formGroup.controls.title.value?.length;
          else pleaseFillJobTitleFirst
        "
        >{{ formGroup.controls.title.value }}
        <ng-container
          *ngIf="uniBaseXVacancy?.workloadFlexible; else workloadFix">
          {{
            (uniBaseXVacancy?.workloadPercentageMin ?? "#") +
              " - " +
              (uniBaseXVacancy?.workloadPercentageMax ?? "#") +
              "%"
          }}
        </ng-container>
        <ng-template #workloadFix>
          <ng-container
            *ngIf="uniBaseXVacancy?.workloadPercentage as workLoadPercentage">
            {{ (workLoadPercentage ?? "#") + "%" }}
          </ng-container>
        </ng-template>
        {{
          "genderAddition"
            | i18next: { lng: uniBaseXVacancy?.language?.toLowerCase() }
        }}
      </span>
      <ng-template #pleaseFillJobTitleFirst>
        <span class="uj-px-d uj-py-c uj-bg-surface-grape uj-leading-6">{{
          "↑" + ("pleaseFillJobTitleFirst" | i18next)
        }}</span>
      </ng-template>
    </div>
  </div>
  <!-- functionText -->
  <ui-library-form-field
    [label]="'whatIsMyFunctionTitle' | i18next | firstUppercase"
    [labelFor]="'whatIsMyFunctionTitle'"
    [dot]="hasChanges(VacancyPublishableFieldsEnum.functionText)"
    required
    fullWidth>
    <unijob-wysiwyg
      focusTracker="functionText"
      formControlName="functionText"
      [linkPlaceholder]="
        'whatIsMyFunctionPlaceHolder' | i18next
      "></unijob-wysiwyg>
  </ui-library-form-field>
  <!-- requirementsText -->
  <ui-library-form-field
    [label]="'whatDoINeedTitle' | i18next | firstUppercase"
    [labelFor]="'requirementsText'"
    [dot]="hasChanges(VacancyPublishableFieldsEnum.requirementsText)"
    required
    fullWidth>
    <unijob-wysiwyg
      id="requirementsText"
      focusTracker="requirementsText"
      formControlName="requirementsText"
      [linkPlaceholder]="
        'whatDoiNeedToBeSuccessfulPlaceHolder' | i18next
      "></unijob-wysiwyg>
  </ui-library-form-field>

  <!-- benefitsText -->
  <ui-library-form-field
    [label]="'whatAreMyBenefits' | i18next | firstUppercase"
    [labelFor]="'benefitsText'"
    [dot]="hasChanges(VacancyPublishableFieldsEnum.benefitsText)"
    fullWidth>
    <unijob-wysiwyg
      id="benefitsText"
      focusTracker="benefitsText"
      formControlName="benefitsText"
      [linkPlaceholder]="'whatDoiGetPlaceHolder' | i18next"></unijob-wysiwyg>
  </ui-library-form-field>

  <!-- employerDescriptionText -->
  <div>
    <ui-library-form-field
      [label]="'whatMakesTheCompanyTitle' | i18next | firstUppercase"
      [labelFor]="'employerDescriptionText'"
      [dot]="hasChanges(VacancyPublishableFieldsEnum.employerDescriptionText)"
      fullWidth>
      <unijob-wysiwyg
        id="employerDescriptionText"
        focusTracker="employerDescriptionText"
        formControlName="employerDescriptionText"
        [linkPlaceholder]="
          'whatDoesTheCompanyDoPlaceHolder' | i18next
        "></unijob-wysiwyg>
    </ui-library-form-field>
    <div
      class="uj-flex uj-text-icon-warning-1 uj-mt-b"
      *ngIf="showEmployerDescriptionTextHint">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <span class="text-s-b uj-ml-b uj-mr-a"
        >{{ "checkYourInput" | i18next }}: </span
      ><span class="text-s">{{ "doNotUseEmailOrPhone" | i18next }}</span>
    </div>
  </div>

  <!-- professionalTitle currently not active  -->
  <div
    class="uj-flex uj-flex-col uj-gap-b"
    *ngIf="false">
    <span class="text-m-b">{{ "professionalTitle" | i18next }}</span>
    <div
      *ngIf="true; else autoDetectedAfterTheVacancyIsPublished"
      class="uj-flex uj-gap-c uj-overflow-auto uj-whitespace-nowrap">
      <mark
        ui-library-tag
        [class.inverted]="false">
        Polymechaniker:in EFZ
      </mark>
      <mark
        ui-library-tag
        color="secondary-light">
        Polymechaniker:in EFZ
      </mark>
      <mark
        ui-library-tag
        color="secondary-light">
        Polymechaniker:in EFZ
      </mark>
      <mark
        ui-library-tag
        color="secondary-light">
        Polymechaniker:in EFZ
      </mark>
    </div>
    <ng-template #autoDetectedAfterTheVacancyIsPublished>
      <span>{{ "autoDetectedAfterTheVacancyIsPublished" | i18next }}</span>
    </ng-template>
  </div>
</div>
