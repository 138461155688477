import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CheckboxModule,
  ContextMenuModule,
  DatePickerModule,
  ErrorHintModule,
  InfoBoxModule,
  InputTextareaModule,
  InputTextModule,
  OverflowGradientModule,
  RadioButtonModule,
  SheetModule,
  SwitchModule,
  TabModule,
  TooltipModule,
  UjSelectInputModule,
  WysiwygModule,
} from '@intemp/unijob-ui';
import { LetDirective } from '@ngrx/component';
import { AnchorNavigationModule } from '../anchor-navigation/anchor-navigation.module';
import { BigSelectModule } from '../big-select/big-select.module';
import { VacancyChangelogModule } from '../vacancy-changelog/vacancy-changelog.module';
import { CommissionInputModule } from '../commission-input/commission-input.module';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserInfoContextMenuModule } from '../user/user-info-context-menu/user-Info-context-menu.module';
import { VacanciesStatusIndicatorModule } from '../vacancies-status-indicator/vacancies-status-indicator.module';
import { VacancyResponsibilityModule } from '../vacancy-responsibility/vacancy-responsibility.module';
import { VacancySalaryInputModule } from '../vacancy-salary-input/vacancy-salary-input.module';
import { VacancyPromoteComponent } from './vacancy-promote.component';
import { ChannelBoxComponent } from './channel-box/channel-box.component';
import { AddonChannelComponent } from './addon-channel/addon-channel.component';
import { VacancyPromoteSearchAddonSheetComponent } from './vacancy-promote-search-addon-sheet/vacancy-promote-search-addon-sheet.component';
import {
  AccordionComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  CardComponent,
  IconComponent,
} from '@intemp/unijob-ui2';
import { TalentPersonalDataComponent } from '../talent-sheet/talent-personal-data/talent-personal-data.component';

@NgModule({
  declarations: [
    VacancyPromoteComponent,
    ChannelBoxComponent,
    AddonChannelComponent,
    VacancyPromoteSearchAddonSheetComponent,
  ],
  exports: [VacancyPromoteComponent],
  imports: [
    VacancyResponsibilityModule,
    SharedDefaultModule,
    SheetModule,
    TabModule,
    OverflowGradientModule,
    FormsModule,
    AnchorNavigationModule,
    DatePickerModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    SwitchModule,
    WysiwygModule,
    UjSelectInputModule,
    RadioButtonModule,
    InfoBoxModule,
    ContextMenuModule,
    UserAvatarModule,
    TooltipModule,
    InputTextareaModule,
    BigSelectModule,
    ErrorHintModule,
    CommissionInputModule,
    VacancySalaryInputModule,
    VacanciesStatusIndicatorModule,
    VacancyChangelogModule,
    UserInfoContextMenuModule,
    LetDirective,
    IconComponent,
    ButtonOutlineComponent,
    ButtonSolidComponent,
    ButtonTextComponent,
    AccordionComponent,
    TalentPersonalDataComponent,
    CardComponent,
  ],
})
export class VacancyPromoteModule {}
