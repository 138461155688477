import { Component, computed, inject, input, signal } from '@angular/core';
import { VacancyDetailFragment } from '../../../../graphql/generated';
import { AsyncPipe, NgIf } from '@angular/common';
import { VacanciesService } from '../../../../models/unibase/vacancies/vacancies.service';
import { ButtonSolidComponent } from '@intemp/unijob-ui2';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { DomSanitizer } from '@angular/platform-browser';
import { setTimeout$ } from '@intemp/unijob-ui';

@Component({
  standalone: true,
  selector: 'app-vacancy-website-preview',
  templateUrl: './vacancy-website-preview.component.html',
  styleUrls: ['./vacancy-website-preview.component.scss'],
  imports: [AsyncPipe, NgIf, ButtonSolidComponent, SharedDefaultModule],
})
export class VacancyWebsitePreviewComponent {
  //services
  vacanciesService = inject(VacanciesService);
  domSanitizer = inject(DomSanitizer);

  vacancy = input.required<VacancyDetailFragment>();

  vacancyPreviewUrl = computed(() => {
    return this.vacanciesService.getVacancyPreviewUrlByVacancy(this.vacancy());
  });

  vacancyPreviewSafeUrl = computed(() => {
    const url = this.vacancyPreviewUrl();
    if (!url) return;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  });

  isLoadingPreview = signal<boolean>(false);

  startLoadingPreview() {
    // this must be done in the next render after the preview is shown
    // because the iframe always emits a loaded event before it even gets the src should load
    setTimeout$(() => {
      this.isLoadingPreview.set(true);
    });
  }

  iframeLoadingCompleted() {
    // adds a bit of loadingSpinner time because it takes a bit until angular is actually ready
    setTimeout$(() => {
      this.isLoadingPreview.set(false);
    }, 200);
    // this.isLoadingPreview = false;
  }
}
