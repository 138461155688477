<div
  class="file-preview"
  appVisibilityCheck
  (coverageChanged)="handleCoverageChange($event)">
  <div
    class="header"
    *ngIf="showHeader()">
    <div class="header-content">
      <div class="header-left">
        @if (mode() === "inactive") {
          <span
            color="grey"
            ui-library-tag
            fontWeight="bold"
            >{{ "inactive" | i18next }}</span
          >
        }
      </div>
      <div class="header-center">
        <div
          class="file-preview-controls"
          *ngIf="false">
          <button
            ui-library-button-solid
            positionInGroup="start"
            corner="round"
            iconOnly="zoom_minus"
            color="secondary-dark"></button>
          <button
            ui-library-button-solid
            positionInGroup="center"
            corner="round"
            iconOnly="full_page"
            color="secondary-dark"></button>
          <button
            ui-library-button-solid
            positionInGroup="end"
            corner="round"
            iconOnly="zoom_plus"
            color="secondary-dark"></button>
        </div>
      </div>
      <div class="header-right"></div>
    </div>
  </div>
  <div class="pages">
    @for (
      page of shownPages();
      track page.uuid + page.favorite.toString() + page.active.toString()
    ) {
      <div
        [id]="page.uuid"
        class="page">
        <div class="document-preview__group">
          <div class="document-preview__document">
            <pdf-viewer
              *ngIf="loadedPdfs().has(page.uuid)"
              [original-size]="false"
              [fit-to-page]="true"
              [show-all]="false"
              [src]="getDocumentDownloadSrc(page)"
              [render-text]="true"
              [render-text-mode]="2"
              [zoom-scale]="'page-width'"
              [autoresize]="true"
              (after-load-complete)="markLoadedPdfReady(page)"
              [class]="
                'pdf-viewer' +
                (loadedAndReadyPdfs().has(page.uuid)
                  ? ' pdf-viewer--ready'
                  : '')
              "></pdf-viewer>
            <img
              #lazyImage
              [src]="getDocumentPreviewSrc(page)"
              [attr.data-page-uuid]="page.uuid"
              [width]="page.mediaObject.metadata?.width"
              [height]="page.mediaObject.metadata?.height"
              loading="lazy" />
            <button
              *ngIf="!loadedPdfs().has(page.uuid)"
              size="S"
              class="pdf-loader-button"
              ui-library-button-solid
              color="secondary-dark"
              iconLeft="file_document_eye_preview"
              (click)="loadPdf(page)">
              {{ "loadPdf" | i18next }}
            </button>
            <app-loading-spinner position="static"></app-loading-spinner>
            <div
              *ngIf="page.favorite"
              class="document-preview__favorite">
              <ui-library-icon
                icon="star_filled"
                size="16"
                color="warning-1"></ui-library-icon>
            </div>
          </div>
        </div>
      </div>
    } @empty {
      <span class="font-light title-m uj-text-text-white">{{
        "noDocumentUploaded" | i18next
      }}</span>
    }
  </div>
</div>
