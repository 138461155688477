<div class="uj-flex uj-flex-col uj-gap-d">
  <app-match-talent-card [match]="match()" />
  <app-match-vacancy-card
    *ngIf="match().vacancy"
    [match]="match()" />
  <app-match-company-card
    *ngIf="match().company"
    [match]="match()" />
  <app-match-responsible-contact-card
    *ngIf="match().company?.companyContact"
    [match]="match()" />
</div>
<div
  *ngIf="match().vacancy || match().company"
  class="uj-absolute uj-top-0 uj-h-full uj-w-[12px] uj-left-[-12px] uj-overflow-hidden">
  <div
    class="rectangle"
    [style]="{
      'margin-top':
        talentCardMeasurement().offsetTop +
        talentCardMeasurement().height / 2 +
        'px',
      height:
        talentCardMeasurement().height +
        talentCardMeasurement().offsetTop +
        13 +
        'px',
    }"></div>
  <div
    *ngIf="match().vacancy"
    class="rectangle"
    [style]="{
      height: vacancyCardHeight() + 13 + 'px',
    }"></div>
  <div
    *ngIf="match().company"
    class="rectangle"
    [style]="{
      height: vacancyCardHeight() + 13 + 'px',
    }"></div>
</div>
