<div
  [formGroup]="formGroup"
  class="uj-flex uj-flex-col uj-gap-f">
  <div class="uj-flex uj-flex-col uj-gap-c">
    <ui-library-form-field
      [label]="'employment' | i18next | firstUppercase"
      required
      labelFor="employmentType"
      [hint]="hintErrorMessages.workloadPercentage | i18next"
      [dot]="
        hasChanges(VacancyPublishableFieldsEnum.workloadFlexible) ||
        hasChanges(VacancyPublishableFieldsEnum.workloadFlexible) ||
        hasChanges(VacancyPublishableFieldsEnum.workloadPercentage) ||
        hasChanges(VacancyPublishableFieldsEnum.workloadPercentageMin) ||
        hasChanges(VacancyPublishableFieldsEnum.workloadPercentageMax) ||
        hasChanges(VacancyPublishableFieldsEnum.employmentType) ||
        hasChanges(VacancyPublishableFieldsEnum.employmentStart) ||
        hasChanges(VacancyPublishableFieldsEnum.employmentEnd) ||
        hasChanges(VacancyPublishableFieldsEnum.fixedTerm)
      ">
      <div
        class="uj-flex uj-flex-col sm:uj-flex-row sm:uj-justify-evenly uj-gap-c">
        <ui-library-simple-select
          id="employmentType"
          class="uj-grow"
          [options]="employmentTypeOptions"
          size="M"
          [placeholderText]="'chooseEmployment' | i18next"
          design="outline"
          backgroundColor="white"
          formControlName="employmentType">
        </ui-library-simple-select>
        <ui-library-input-container>
          <ng-container *ngIf="!!workloadFlexible.value">
            <input
              ui-library-text-input
              [feedback]="workloadPercentageMin.errors ? 'invalid' : 'none'"
              type="number"
              min="1"
              max="100"
              focusTracker="workloadPercentageMin"
              formControlName="workloadPercentageMin"
              positionInGroup="start-seamless"
              [placeholder]="'min' | i18next"
              (input)="clampPercentageValue('workloadPercentageMin', $event)" />
            <div
              ui-library-pseudo-input
              class="uj-flex-shrink-0"
              positionInGroup="center-seamless"
              [feedback]="
                workloadPercentageMin.errors && workloadPercentageMax.errors
                  ? 'invalid'
                  : 'none'
              "
              [isDisabled]="formIsDisabled"
              bordered>
              —
            </div>
            <input
              ui-library-text-input
              [feedback]="workloadPercentageMax.errors ? 'invalid' : 'none'"
              type="number"
              min="1"
              max="100"
              focusTracker="workloadPercentageMax"
              formControlName="workloadPercentageMax"
              [placeholder]="'max' | i18next"
              (input)="clampPercentageValue('workloadPercentageMax', $event)"
              positionInGroup="center-seamless" />
          </ng-container>
          <input
            ui-library-text-input
            [feedback]="workloadPercentage.errors ? 'invalid' : 'none'"
            class="!uj-w-[160px] uj-shrink-0"
            type="number"
            min="1"
            max="100"
            *ngIf="!workloadFlexible.value"
            focusTracker="workloadPercentage"
            formControlName="workloadPercentage"
            [placeholder]="'enterWorkloadPercentage' | i18next"
            (input)="clampPercentageValue('workloadPercentage', $event)"
            positionInGroup="start-seamless" />
          <div
            ui-library-pseudo-input
            positionInGroup="end-seamless"
            class="uj-shrink-0"
            [feedback]="
              workloadPercentage.errors || workloadPercentageMax.errors
                ? 'invalid'
                : 'none'
            "
            [isDisabled]="formIsDisabled"
            bordered>
            %
          </div>
        </ui-library-input-container>
      </div>
    </ui-library-form-field>
    <unijob-switch
      focusTracker="workloadFlexible"
      formControlName="workloadFlexible"
      [label]="'useFromTo' | i18next"
      round></unijob-switch>
    <unijob-switch
      focusTracker="showWorkloadInPublicTitle"
      formControlName="showWorkloadInPublicTitle"
      [label]="'showWorkloadInPublicTitle' | i18next"
      round></unijob-switch>
    <div class="uj-flex uj-flex-col md:uj-flex-row uj-gap-c">
      <ui-library-simple-select
        class="uj-flex-1 md:uj-min-w-[271px]"
        [options]="employmentTermOptions"
        size="M"
        design="outline"
        [formControl]="fixedTerm"
        backgroundColor="white">
      </ui-library-simple-select>
      <div
        class="uj-flex uj-flex-1"
        [ngClass]="{
          'uj-outline uj-outline-1 uj-outline-offset-2 uj-outline-ui-negative uj-rounded-a uj-mt-a':
            hintErrorMessages.employmentContract,
        }">
        <unijob-date-picker
          class="uj-flex-1"
          formControlName="employmentStart"
          mode="single"
          format="d.m.Y"
          [placeholder]="'start' | i18next"
          [showInvalidState]="true">
        </unijob-date-picker>
        <unijob-date-picker
          *ngIf="fixedTerm.value"
          class="uj-flex-1"
          format="d.m.Y"
          [placeholder]="'end' | i18next"
          mode="single"
          formControlName="employmentEnd"
          [showInvalidState]="true"></unijob-date-picker>
      </div>
    </div>
    <div
      class="uj-flex uj-text-ui-negative uj-items-center uj-gap-a"
      *ngIf="hintErrorMessages.employmentContract">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <span class="text-s">{{
        hintErrorMessages.employmentContract | i18next
      }}</span>
    </div>
  </div>
  <div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-b">
    <label class="input-label uj-relative uj-flex uj-items-center"
      >{{ "position" | i18next | firstUppercase }}
      <ng-container
        *ngIf="hasChanges(VacancyPublishableFieldsEnum.positionType)">
        <div class="uj-absolute -uj-left-d unijob-dot"></div>
      </ng-container>
    </label>
    <ui-library-simple-select
      class="uj-grow"
      size="M"
      [options]="positionTypeOptions"
      [placeholderText]="'chooseFunction' | i18next"
      design="outline"
      formControlName="positionType"
      backgroundColor="white">
    </ui-library-simple-select>
  </div>
  <app-vacancy-salary-input
    [formRef]="formRef"
    (triggerAutoSave)="triggerAutoSave.next($event)"
    [formIsDisabled]="formIsDisabled"
    [criteriaData]="criteriaData"
    [vacancy]="vacancy"></app-vacancy-salary-input>

  <div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-b">
    <label class="input-label uj-relative uj-flex uj-items-center"
      >{{ "languages" | i18next | firstUppercase
      }}<span class="red-text">*</span>
      <ng-container
        *ngIf="hasChanges(VacancyPublishableFieldsEnum.languageRequirements)">
        <div class="uj-absolute -uj-left-d unijob-dot"></div>
      </ng-container>
    </label>
    <div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-c">
      <ng-container formArrayName="languageRequirements">
        <ng-container
          *ngFor="
            let languageForm of languageRequirements.controls;
            let i = index
          ">
          <app-vacancy-language-requirement-row
            [selectedLanguages]="selectedLanguages"
            [languageOptions]="languageOptions"
            [languageForm]="languageForm"
            [formRef]="languageRequirements"
            [languageProficiencyEnumOptions]="languageProficiencyEnumOptions"
            (languageChanged)="filterLanguageOptions()"
            (removeEntry)="
              removeEntry(i)
            "></app-vacancy-language-requirement-row>
        </ng-container>
      </ng-container>
      <div *ngIf="addLanguageOptions.length && !formIsDisabled">
        <ui-library-simple-select
          [options]="addLanguageOptions"
          [searchable]="true"
          [placeholderText]="'addLanguage' | i18next"
          size="M"
          backgroundColor="white"
          design="outline"
          formControlName="languageToAdd"
          class="uj-grow" />
      </div>
    </div>
  </div>
</div>
