import {
  Component,
  computed,
  ElementRef,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  MatchFragment,
  VacancyDetailFragment,
  VacancyStatusEnum,
  WorkAvailabilityEnum,
} from '../../../../../graphql/generated';
import {
  AvatarComponent,
  ButtonTextComponent,
  CardComponent,
  DotComponent,
  FormFieldComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { VacanciesService } from '../../../../../models/unibase/vacancies/vacancies.service';
import { getStatusWithDuration } from '../../../../helpers/functions/getStatusWithDuration';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../../global-sheets/global-sheets.service';

@Component({
  standalone: true,
  selector: 'app-match-vacancy-card',
  templateUrl: './match-vacancy-card.component.html',
  imports: [
    CardComponent,
    AvatarComponent,
    I18NextModule,
    SharedDefaultModule,
    DotComponent,
    ButtonTextComponent,
    FormFieldComponent,
  ],
  providers: [],
})
export class MatchVacancyCardComponent implements OnInit {
  vacanciesService = inject(VacanciesService);
  globalSheetsService = inject(GlobalSheetsService);
  hostElement = inject(ElementRef);

  match = input.required<MatchFragment>();
  vacancy = signal<VacancyDetailFragment | undefined>(undefined);

  vacancyStatus = computed(() => {
    const vacancy = this.vacancy();
    if (!vacancy) return;

    return getStatusWithDuration(vacancy);
  });

  ngOnInit() {
    const vacancyUUid = this.match().vacancy?.uuid;
    if (vacancyUUid)
      this.vacanciesService
        .subscribeVacancy$(vacancyUUid)
        .subscribe((vacancy) => {
          this.vacancy.set(vacancy);
        });
  }

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
  protected readonly VacancyStatusEnum = VacancyStatusEnum;

  openVacancy(uuid: string) {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.VACANCY_EDIT,
      uuid: uuid,
      action: 'vacancy',
    });
  }

  openVacancyPreview(uuid: string) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.MATCH_EDIT,
        uuid: this.match().uuid,
      },
      'overlayView',
      'vacancy',
    );
  }
}
