<unijob-sheet
  [id]="sheetId()"
  size="s"
  (sheetClosed)="sheetClosed.emit(true)">
  <ng-container slot="header">
    <h2 class="title-m">
      {{ "archiveMatch" | i18next | firstUppercase }}
    </h2>
  </ng-container>
  <ng-container slot="content">
    <div
      class="uj-flex uj-flex-col uj-gap-f"
      [formGroup]="formGroup">
      <div class="uj-flex uj-flex-col uj-gap-c">
        <span class="title-xs">{{ "reason" | i18next }}</span>
        <span class="text-m">
          {{ "pleaseSelectOneOfTheFollowingReasons" | i18next }}</span
        >
        <ui-library-simple-select
          class="uj-grow"
          size="M"
          [options]="archiveReasonsOptions"
          [placeholderText]="'selectReason' | i18next"
          design="outline"
          [formControl]="archiveReason"
          backgroundColor="white">
        </ui-library-simple-select>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div class="uj-flex uj-gap-d uj-flex-col uj-w-full">
      @if (mailToLink()) {
        <ui-library-form-field
          fullWidth
          [label]="'speech' | i18next"
          labelFor="formality">
          <div class="uj-flex uj-gap-b uj-flex-row uj-w-full">
            <ui-library-simple-select
              id="formality"
              size="M"
              style="width: 100%"
              design="outline"
              [formControl]="formality"
              [options]="formalityOptions"></ui-library-simple-select>
            <a
              [href]="mailToLink()"
              class="uj-w-full"
              ui-library-button-outline
              iconLeft="mail">
              {{ "sendCancelation" | i18next | firstUppercase }}
            </a>
          </div>
        </ui-library-form-field>
      }
      <button
        (click)="archiveMatch()"
        [disabled]="!archiveReason.value"
        class="uj-w-full"
        ui-library-button-solid
        type="button"
        iconLeft="archive">
        {{ "archiveMatch" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-sheet>
