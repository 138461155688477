<div>
  <div class="uj-flex uj-items-center uj-justify-between">
    <div
      class="uj-flex uj-flex-2 uj-w-0 uj-flex-1 uj-min-h-[36px] uj-items-center">
      <h2 class="uj-truncate title-m">
        {{
          (talent().firstName
            ? talent().firstName
            : ("firstName" | i18next | firstUppercase)) +
            " " +
            (talent().lastName
              ? talent().lastName
              : ("lastName" | i18next | firstUppercase))
        }}
      </h2>
    </div>
    <div
      class="uj-items-center uj-justify-end uj-hidden uj-ml-d md:uj-flex"
      *ngIf="userService.readyUser$ | async">
      <div>
        <app-current-user-avatar />
      </div>
    </div>
  </div>
  <div
    class="uj-flex uj-mt-b uj-center md:uj-justify-between uj-gap-c uj-flex-wrap">
    <div class="uj-flex uj-items-center uj-flex-wrap">
      @if (unreadPages().length > 0) {
        <div class="uj-flex uj-items-baseline uj-gap-b">
          <span
            ui-library-dot
            color="accent"
            flashing></span>
          <span class="uj-text-text-xs">
            {{ "newDocuments" | i18next }}
          </span>
        </div>
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
      }
      <app-talent-status-indicator
        [isPositionedInHeader]="true"
        class="uj-mr-b" />
      <ng-container *ngTemplateOutlet="WorkAvailability"></ng-container>
      <ng-container *ngIf="talent().talentNumber as talentNumber">
        <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
        <span class="text-s">{{ talentNumber }} </span>
      </ng-container>
    </div>
    <div
      *ngIf="talent().updatedAt"
      class="uj-flex uj-flex-row-reverse uj-items-center md:uj-flex-row">
      <div class="uj-flex uj-flex-row-reverse uj-items-center md:uj-flex-row">
        <span class="uj-hidden text-s sm:uj-block">
          {{ lastUpdatedAt().lastEditTime }}
        </span>
        <span class="uj-block text-s sm:uj-hidden">
          {{ lastUpdatedAt().lastEditTimeMobile }}
        </span>
        <i
          ui-library-icon-tag
          icon="check"
          class="uj-mr-[6px] md:uj-mr-0 md:uj-ml-[6px]"
          color="positive"></i>
        <span
          class="uj-h-c uj-mx-c unijob-vertical-divider !uj-hidden md:!uj-block"></span>
        <button
          class="!uj-hidden md:!uj-block"
          (click)="openChangelogSheet()"
          ui-library-button-link
          size="S"
          color="dark"
          fontWeight="bold">
          {{ "history" | i18next | firstUppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="uj-z-10 uj-w-full uj-pt-e uj-bg-surface-main">
  <div
    scrollOverflowGradient
    class="uj-tab-group">
    <unijob-tab
      [name]="'profile' | i18next | firstUppercase"
      [active]="activeTab() === tabEnum.PROFILE"
      (tabSelected)="switchActiveTabTo(tabEnum.PROFILE)"></unijob-tab>
    <unijob-tab
      [name]="'documents' | i18next | firstUppercase"
      [active]="activeTab() === tabEnum.DOCUMENTS"
      [highlight]="unreadPages().length > 0"
      (tabSelected)="switchActiveTabTo(tabEnum.DOCUMENTS)"></unijob-tab>
  </div>
</div>

<ng-template #WorkAvailability>
  <div class="uj-flex uj-gap-b uj-items-center">
    <app-talent-workavailability-dot
      [workAvailability]="
        talent().workAvailability
      "></app-talent-workavailability-dot>
    <span class="uj-text-text text-s-b">{{
      "WorkAvailabilityEnum." + talent()?.workAvailability | i18next
    }}</span>
    <ng-container *ngIf="availabilityInfos() as infos">
      <span class="uj-text-text text-s">
        {{ infos }}
      </span>
    </ng-container>
  </div>
</ng-template>
