export const allowedSalaryInput = ($event: KeyboardEvent): boolean => {
  let isValidInput = true;
  const value = $event.key;
  const isNumber = value.match(/^(\d*\.)?\d+$/); // numbers and decimal only
  if ($event.metaKey) {
    return true;
  }
  if (!isNumber) {
    switch ($event.code) {
      case 'Digit0':
      case 'Backspace':
      case 'Delete':
      case 'Enter':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Tab':
      case 'Period':
      case 'Comma':
      case 'NumpadDecimal':
        break;
      default:
        isValidInput = false;
    }
  }
  return isValidInput;
};
