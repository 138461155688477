<div
  class="overlay"
  *ngIf="open()">
  <div class="overlay-wrapper">
    <div class="header">
      <div class="header-content">
        <div class="header-left">
          <ng-content select="[header-left]" />
        </div>
        <div class="header-center"><ng-content select="[header-center]" /></div>
        <div class="header-right">
          <button
            (click)="closeOverlay()"
            ui-library-button-solid
            corner="round"
            iconOnly="close"
            color="secondary-dark"></button>
        </div>
      </div>
    </div>
    <ng-content select="[content]"></ng-content>
  </div>
</div>
