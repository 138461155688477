import { Component, computed, ElementRef, inject, input } from '@angular/core';
import {
  MatchFragment,
  WorkAvailabilityEnum,
} from '../../../../../graphql/generated';
import {
  AvatarComponent,
  ButtonTextComponent,
  CardComponent,
  DotComponent,
  FormFieldComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../../global-sheets/global-sheets.service';
import { TooltipModule } from '@intemp/unijob-ui';
import { TalentWorkAvailabilityDotComponent } from '../../../../components/talent-workAvailability-dot/talent-workAvailability-dot.component';
import { getAvailabilityInfo } from '../../../../helpers/functions/getAvailabilityInfo';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-match-talent-card',
  templateUrl: './match-talent-card.component.html',
  imports: [
    CardComponent,
    AvatarComponent,
    I18NextModule,
    SharedDefaultModule,
    DotComponent,
    ButtonTextComponent,
    TooltipModule,
    FormFieldComponent,
    TalentWorkAvailabilityDotComponent,
  ],
  providers: [],
})
export class MatchTalentCardComponent {
  datePipe = inject(DatePipe);
  globalSheetsService = inject(GlobalSheetsService);
  match = input.required<MatchFragment>();
  constructor(public hostElement: ElementRef) {}
  availabilityInfos = computed(() => {
    const { workAvailability, availableForWorkAt, employedUntil } =
      this.match().talent;
    return getAvailabilityInfo(
      workAvailability,
      availableForWorkAt,
      employedUntil,
      (date) => {
        return this.datePipe.transform(date, 'dd.MM.yyyy') ?? '';
      },
    );
  });

  openTalent(uuid: string) {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.TALENT_EDIT,
      uuid: uuid,
      action: 'profile',
    });
  }

  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;

  openTalentPreview(uuid: string) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.MATCH_EDIT,
        uuid: this.match().uuid,
      },
      'overlayView',
      'talent',
    );
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.MATCH_EDIT,
        uuid: this.match().uuid,
      },
      'overlayAction',
      'active',
    );
  }

  protected getAvatarSrc(talent: MatchFragment['talent']): string {
    const avatarSrcPath = talent.avatar?.mediaObject.sizes
      ?.find((size) => size.name === 'avatarS')
      ?.src.replace(':talentUuid', talent.uuid);
    return avatarSrcPath ? environment.mediaUrl + avatarSrcPath : '';
  }
}
