<div
  class="uj-relative uj-flex uj-items-stretch uj-overflow-hidden uj-bg-surface-main uj-h-full uj-min-w-full">
  @if (vacancyPreviewSafeUrl()) {
    <iframe
      *ngIf="vacancyPreviewSafeUrl() as previewUrl"
      onload
      class="uj-grow"
      [src]="previewUrl"
      (load)="iframeLoadingCompleted()"
      title="job preview"></iframe>
  } @else {
    <app-loading-spinner
      class="!uj-text-text-white"
      position="static"></app-loading-spinner>
  }
</div>
