export function generateTitleFromFilename(
  path: string,
  pretty = false,
): string {
  // Extract the filename from the path (handles both '/' and '\')
  const filename = path.split(/[/\\]/).pop() || '';

  // Remove the file extension by only considering the last dot as the start of the extension
  let baseName = filename.replace(/\.[^.]*$/, '');

  //  replace remaining dots with spaces
  baseName = baseName.replace(/\./g, ' ');

  if (pretty) {
    // Replace underscores and hyphens with spaces
    baseName = baseName.replace(/[_-]/g, ' ');

    // Convert camelCase to spaces (e.g., "exampleFileName" -> "Example File Name")
    baseName = baseName.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalize the first letter of each word and ensure proper casing with mixed delimiters
    baseName = baseName.replace(
      /\b\w+/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );

    // Remove special characters
    baseName = baseName.replace(/[^a-zA-Z0-9\s]/g, '');
  }

  // Trim any extra spaces (leading, trailing, or multiple spaces)
  baseName = baseName.replace(/\s+/g, ' ').trim();

  // if empty return ... instead of empty string
  if (baseName === '') {
    return '...';
  }

  return baseName;
}
