<div class="overlay-wrapper">
  <div
    class="header"
    *ngIf="hasHeader()">
    <div class="header-content">
      <div class="header-left">
        <ng-content select="[header-left]"></ng-content>
      </div>
      <div class="header-center">
        <div class="preview-controls">
          <ng-content select="[header-center]"></ng-content>
        </div>
      </div>
      <div class="header-right">
        <ng-content select="[header-right]"></ng-content>
      </div>
    </div>
  </div>
  <div
    class="uj-relative uj-flex uj-w-full uj-h-full uj-overflow-hidden uj-pb-e">
    @if (overlayView() === "talent") {
      @if (talent(); as talent) {
        <app-talent-document-preview
          [talent]="talent"
          [showHeader]="false"
          [mode]="overlayAction()"
          [uuid]="openSheetOverlayUuid()" />
      } @else {
        <app-loading-spinner></app-loading-spinner>
      }
    } @else if (overlayView() === "vacancy") {
      @if (vacancy(); as vacancy) {
        <app-vacancy-website-preview
          [vacancy]="vacancy"></app-vacancy-website-preview>
      } @else {
        <app-loading-spinner></app-loading-spinner>
      }
    } @else {
      View {{ overlayView() }} not supported
    }
  </div>
</div>
