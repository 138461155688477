<ui-library-form-field
  *ngrxLet="allConsultants() as allConsultants"
  fullWidth
  required
  [label]="'responsibleEmployee' | i18next">
  <app-consultant-select
    [formControl]="consultantIdControl()"
    [allConsultants]="allConsultants">
  </app-consultant-select>
</ui-library-form-field>
