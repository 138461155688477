<ui-library-card
  disablePadding
  class="!uj-flex uj-items-center">
  <ng-container *ngIf="isEditingSource; else preview">
    <div
      class="uj-w-full uj-grid uj-grid-cols-1 md:uj-grid-cols-2 uj-gap-c uj-items-end fist:uj-mt-d">
      <div>
        <label
          class="uj-block text-m-b uj-mb-b uj-relative"
          [for]="'type'"
          >{{ "type" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <ui-library-simple-select
          class="uj-flex-1"
          [options]="sourceTypeOptions"
          size="M"
          [placeholderText]="'choose' | i18next"
          design="outline"
          backgroundColor="white"
          [formControl]="source.controls.sourceType">
        </ui-library-simple-select>
      </div>
      <div>
        <label
          class="uj-block text-m-b uj-mb-b uj-relative"
          [for]="'date'"
          >{{ "date" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <unijob-date-picker
          [formControl]="source.controls.contactAt"
          mode="single"
          [placeholder]="'date' | i18next"
          [showInvalidState]="true">
        </unijob-date-picker>
      </div>
      <span
        class="text-m red-text"
        *ngIf="
          source.controls.contactAt.dirty &&
          source.controls.contactAt.errors?.required
        ">
        {{ "fieldRequired" | i18next }}
      </span>
      <div
        class="uj-col-span-full uj-flex uj-flex-col md:uj-flex-row uj-gap-c uj-mt-d">
        <ng-container
          *ngIf="source.controls.isNewEntry.value; else sourceToUpdate">
          <button
            class="!uj-w-full"
            ui-library-button-outline
            color="primary"
            (click)="cancelEditSource()">
            {{ "cancel" | i18next | firstUppercase }}
          </button>
          <button
            [disabled]="!source.valid"
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="plus"
            color="primary"
            (click)="addSource()">
            {{ "add" | i18next | firstUppercase }}
          </button>
        </ng-container>
        <ng-template #sourceToUpdate>
          <button
            class="!uj-w-full"
            ui-library-button-outline
            iconLeft="trash"
            color="primary"
            (click)="modals.open(deleteConfirmationModalId)">
            {{ "delete" | i18next | firstUppercase }}
          </button>
          <button
            class="!uj-w-full"
            ui-library-button-solid
            iconLeft="check"
            color="primary"
            (click)="updateSourceEntry()">
            {{ "save" | i18next | firstUppercase }}
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ui-library-card>
<ng-template #preview>
  <div
    [ngClass]="{
      'uj-pr-d': source.controls.platform.value,
      'uj-pr-b': !source.controls.platform.value,
    }"
    class="uj-flex uj-items-center uj-flex-1 uj-py-c uj-pl-d">
    <div class="uj-flex uj-flex-col uj-flex-grow">
      <span class="text-m uj-flex uj-gap-b">
        @if (source.controls.platform.value) {
          <img
            [src]="platformsIconMap[source.controls.platform.value]"
            alt="Icon Description"
            class="uj-w-e uj-h-e" />
          {{
            "JobApplicationSourcePlatformEnum." + source.controls.platform.value
              | i18next
          }}
        } @else {
          {{
            "TalentSourceTypeEnum." + source.controls.sourceType.value | i18next
          }}
        }
      </span>
    </div>
    <div>
      <span class="text-m uj-text-grey-300">
        {{
          source.controls.contactAt.value?.at(0)
            | date: (isSourcePlatform ? "dd.MM.yyyy - HH:mm" : "dd.MM.yyyy")
        }}</span
      >
    </div>
  </div>
  <!--  <button-->
  <!--    *ngIf="source.value.sourceType"-->
  <!--    ui-library-button-text-->
  <!--    iconOnly="edit_pen_1"-->
  <!--    (click)="editSource()"></button>-->
</ng-template>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  size="S">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "areYouSureYouWantToDeleteThisEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="modals.close(deleteConfirmationModalId)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteSource()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button"
        iconLeft="trash">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
