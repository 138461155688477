<ui-library-card
  padding="d"
  [color]="isBoxDisabled() ? 'grey' : undefined">
  <div
    class="uj-flex uj-flex-row uj-items-center uj-justify-between uj-gap-b uj-mb-b">
    <div class="uj-text-base uj-font-bold">
      {{ channel.name }}
    </div>
    <div class="uj-flex uj-flex-row uj-items-center">
      <button
        *ngIf="
          channel.maxRunningAndPendingSlotsPerUser ||
          channel.maxRunningAndPendingSlotsTotal
        "
        ui-library-button-text
        type="button"
        [disabled]="settingsDisabled()"
        size="S"
        iconOnly="settings"
        color="dark"
        [tooltip]="
          settingsDisabled()
            ? this.settingsDisabledReason
            : ('editSlots' | i18next)
        "
        (click)="openSlotsSheet()"></button>
      <button
        *ngIf="hasExternalLink()"
        [tooltip]="'copyLink' | i18next"
        ui-library-button-text
        type="button"
        (click)="copyExternalLinkToClipboard()"
        size="S"
        iconOnly="link"
        color="dark"></button>
      <!-- unijob / expand-resize-open-new-tab-external-1 -->
      <button
        *ngIf="hasExternalLink()"
        [tooltip]="'openInNewTab' | i18next"
        ui-library-button-text
        type="button"
        (click)="openExternalLinkInNewTab()"
        size="S"
        iconOnly="expand_resize_open_new_tab_external_1"
        color="dark"></button>
      <ng-container *ngIf="switchVisible()">
        <span
          *ngIf="
            hasExternalLink() ||
            channel.maxRunningAndPendingSlotsPerUser ||
            channel.maxRunningAndPendingSlotsTotal
          "
          class="uj-h-e uj-mr-c unijob-vertical-divider"></span>
        <unijob-switch
          [tooltip]="switchDisabled() ? switchDisabledReason : ''"
          [ngModel]="switchState"
          (ngModelChange)="handleSwitchChange($event)"
          [disabled]="switchDisabled() || isSwitching"
          round></unijob-switch>
      </ng-container>
    </div>
  </div>
  <!-- UJ Karriere Description -->
  <ng-container *ngIf="channel.exportSettings.universaljobInternal?.enabled">
    <p class="uj-text-text-s/[16px] uj-mb-b">
      {{ "ujInternalDescription" | i18next }}
    </p>
  </ng-container>
  <div
    *ngIf="configs?.length === 0 && neverConfiguredWording()"
    class="uj-text-text-s/[16px]">
    <ng-container>
      <div
        *ngIf="showConfigLights()"
        class="uj-inline-block uj-pr-a">
        <span
          [class.color-green]="light() === 'green'"
          [class.color-yellow]="light() === 'yellow'"
          [class.color-grey]="light() === 'grey'"
          class="uj-inline-block unijob-dot"></span>
      </div>
    </ng-container>
    {{ neverConfiguredWording() }}
  </div>
  <div
    *ngFor="let config of configs"
    class="uj-text-text-s/[16px]">
    <ng-container *ngIf="stateWording(config)">
      <div
        *ngIf="showConfigLights()"
        class="uj-inline-block uj-pr-a">
        <span
          [class.color-green]="light(config) === 'green'"
          [class.color-yellow]="light(config) === 'yellow'"
          [class.color-grey]="light(config) === 'grey'"
          class="uj-inline-block unijob-dot color-green"></span>
      </div>
    </ng-container>
    {{ stateWording(config) }}
  </div>
</ui-library-card>
