import { Component, computed, inject, input } from '@angular/core';
import { TalentSheetService } from '../services/talent-sheet.service';
import {
  TalentFragment,
  WorkAvailabilityEnum,
} from '../../../../graphql/generated';
import { I18NextModule } from 'angular-i18next';
import { UserService } from '../../../../models/shared/user/user.service';
import { TalentTabEnum } from '../../../../pages/talents/talent.types';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../global-sheets/global-sheets.service';
import { TabModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { CurrentUserAvatarComponent } from '../../../components/current-user-avatar/current-user-avatar.component';
import { TalentStatusIndicatorComponent } from '../talent-status-indicator/talent-status-indicator.component';
import {
  ButtonLinkComponent,
  DotComponent,
  IconTagComponent,
} from '@intemp/unijob-ui2';
import { getLastEditTime } from '../../../helpers/functions/getLastEditTime';
import { TalentWorkAvailabilityDotComponent } from '../../../components/talent-workAvailability-dot/talent-workAvailability-dot.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { getAvailabilityInfo } from '../../../helpers/functions/getAvailabilityInfo';
import { DatePipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-talent-sheet-header',
  templateUrl: './talent-sheet-header.component.html',
  imports: [
    TabModule,
    I18NextModule,
    SharedDefaultModule,
    CurrentUserAvatarComponent,
    TalentStatusIndicatorComponent,
    DotComponent,
    ButtonLinkComponent,
    IconTagComponent,
    TalentWorkAvailabilityDotComponent,
  ],
  providers: [],
})
export class TalentSheetHeaderComponent {
  datePipe = inject(DatePipe);
  talent = input.required<TalentFragment>();

  readonly unreadPages = computed(() =>
    this.talent().pages.filter((page) => page.active && !page.read),
  );

  lastUpdatedAt = computed(() => {
    return getLastEditTime(this.talent().updatedAt ?? new Date().toISOString());
  });

  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });

  activeTab = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.uuid === this.talent().uuid,
    );
    return sheet?.tab ?? TalentTabEnum.PROFILE;
  });

  availabilityInfos = computed(() => {
    const { workAvailability, availableForWorkAt, employedUntil } =
      this.talent();
    return getAvailabilityInfo(
      workAvailability,
      availableForWorkAt,
      employedUntil,
      (date) => {
        return this.datePipe.transform(date, 'dd.MM.yyyy') ?? 'fallback date';
      },
    );
  });

  constructor(
    public service: TalentSheetService,
    public userService: UserService,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  openChangelogSheet() {
    //TODO: open vacancy-changelog sheet
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.TALENT_CHANGELOG,
      uuid: this.talent().uuid,
    });
  }

  switchActiveTabTo(tab: TalentTabEnum) {
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.TALENT_EDIT,
        uuid: this.talent().uuid,
      },
      'tab',
      tab.toLowerCase(),
    );
  }

  protected readonly tabEnum = TalentTabEnum;
  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
}
