<div
  class="uj-flex uj-flex-col uj-gap-c"
  [formGroup]="formGroup">
  <ui-library-form-field
    [label]="'salary' | i18next | firstUppercase"
    required
    [feedback]="hintErrorMessages.salary ? 'invalid' : 'none'"
    [hint]="hintErrorMessages.salary ?? '' | i18next"
    fullWidth
    [dot]="
      hasChanges(VacancyPublishableFieldsEnum.publishSalary) ||
      (!publishSalary.value &&
        (hasChanges(VacancyPublishableFieldsEnum.salaryFlexible) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryUnit) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryMin) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryMax) ||
          hasChanges(VacancyPublishableFieldsEnum.salary) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryHoursPerWeek) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryCurrency) ||
          hasChanges(VacancyPublishableFieldsEnum.salaryPaymentsPerYear)))
    ">
    <div class="uj-flex uj-flex-col uj-gap-c sm:uj-flex-row">
      <div class="uj-flex uj-items-center uj-gap-c">
        <ui-library-simple-select
          class="uj-grow sm:uj-min-w-[110px]"
          size="M"
          [options]="salaryUnitOptions"
          [placeholderText]="'month' | i18next"
          design="outline"
          formControlName="salaryUnit"
          backgroundColor="white" />
        <ng-container *ngIf="isXsDown$ | async">
          <ng-container *ngTemplateOutlet="salaryPaymentsCount"></ng-container>
        </ng-container>
      </div>
      <ui-library-input-container class="uj-w-full">
        <ng-container *ngIf="!salaryFlexible.value; else flexibleSalary">
          <input
            ui-library-text-input
            [feedback]="salary.errors ? 'invalid' : 'none'"
            class="uj-min-w-[96x] uj-flex-grow-1"
            focusTracker="salary"
            formControlName="salary"
            [placeholder]="'enterAmount' | i18next"
            type="text"
            [imask]="currencyMask()"
            (keydown)="allowedSalaryInput($event)"
            (paste)="filterClipboardValue($event)"
            positionInGroup="start-seamless"
            fullWidth />
        </ng-container>
        <ng-template #flexibleSalary>
          <input
            ui-library-text-input
            [feedback]="salaryMin.errors ? 'invalid' : 'none'"
            class="uj-flex-1 sm:uj-min-w-[96px]"
            focusTracker="salaryMin"
            formControlName="salaryMin"
            positionInGroup="start-seamless"
            [placeholder]="'min' | i18next"
            type="text"
            [imask]="currencyMask()"
            [unmask]="'typed'"
            (keydown)="allowedSalaryInput($event)"
            (paste)="filterClipboardValue($event)" />
          <div
            ui-library-pseudo-input
            [isDisabled]="formIsDisabled"
            [feedback]="
              salaryMin.errors && salaryMax.errors ? 'invalid' : 'none'
            "
            bordered
            positionInGroup="center-seamless">
            —
          </div>
          <input
            ui-library-text-input
            [feedback]="salaryMax.errors ? 'invalid' : 'none'"
            class="uj-flex-1 sm:uj-min-w-[96px]"
            focusTracker="salaryMax"
            formControlName="salaryMax"
            [placeholder]="'max' | i18next"
            type="text"
            [imask]="currencyMask()"
            (keydown)="allowedSalaryInput($event)"
            (paste)="filterClipboardValue($event)"
            positionInGroup="center-seamless" />
        </ng-template>
        <ui-library-simple-select
          [options]="salaryCurrencyOptions"
          [placeholderText]="'chf' | i18next"
          formControlName="salaryCurrency"
          size="M"
          design="outline"
          backgroundColor="white"
          class="uj-w-[88px] uj-flex-shrink-0"
          positionInGroup="end" />

        <ng-container *ngIf="!(isXsDown$ | async)">
          <ng-container *ngTemplateOutlet="salaryPaymentsCount"></ng-container>
        </ng-container>
      </ui-library-input-container>
      <ng-template #salaryPaymentsCount>
        <div
          *ngIf="salaryUnit.value !== SalaryUnitEnum.YEAR"
          ui-library-pseudo-input
          class="uj-shrink-0 uj-grow-0 uj-flex uj-items-center">
          <ui-library-icon
            icon="close"
            size="16"></ui-library-icon>
        </div>
        <ui-library-simple-select
          *ngIf="salaryUnit.value === SalaryUnitEnum.MONTH"
          [options]="salaryPaymentsPerYearOptions"
          class="!uj-w-[70px] uj-flex-shrink-0"
          size="M"
          placeholderText="12"
          design="outline"
          formControlName="salaryPaymentsPerYear"
          backgroundColor="white" />
        <input
          ui-library-text-input
          class="!uj-w-[70px] uj-flex-shrink-0"
          *ngIf="salaryUnit.value === SalaryUnitEnum.HOUR"
          focusTracker="salaryHoursPerWeek"
          formControlName="salaryHoursPerWeek"
          placeholder="42"
          max="168"
          min="1"
          type="number" />
      </ng-template>
    </div>
  </ui-library-form-field>
  <unijob-switch
    formControlName="salaryFlexible"
    [label]="'salaryRangeFromTo' | i18next"
    round></unijob-switch>
  <unijob-switch
    (click)="validateHideSalarySwitch($event)"
    formControlName="publishSalary"
    [label]="'publishSalary' | i18next"
    round></unijob-switch>
</div>
