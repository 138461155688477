import { JobApplicationSourcePlatformEnum } from '../../graphql/generated';
import { sourceToPlatformMap } from './source-to-platform-map';

export const platformIcons = {
  [JobApplicationSourcePlatformEnum.UNIVERSALJOB]:
    sourceToPlatformMap['universaljob-others'].iconPath,
  [JobApplicationSourcePlatformEnum.JOBS_CH]:
    sourceToPlatformMap['jobs.ch'].iconPath,
  [JobApplicationSourcePlatformEnum.LINKED_IN]:
    sourceToPlatformMap['linkedin'].iconPath,
  [JobApplicationSourcePlatformEnum.JOB_SHOT]:
    sourceToPlatformMap['jobshot'].iconPath,
};
