<ui-library-card
  padding="12px"
  interactable="true"
  [selected]="selected">
  <div class="document-content">
    @if (page()) {
      @if (!page()?.read && page()?.active) {
        <span
          ui-library-dot
          class="dot"
          uiLibraryTooltip
          [tooltip]="'unread' | i18next"></span>
      }
      <div
        class="handle"
        cdkDragHandle>
        <ui-library-icon
          icon="drag_handle"
          color="light-grey"></ui-library-icon>
      </div>
    }
    <div class="content">
      @if (page(); as page) {
        <div
          [class]="
            'document-wrapper' +
            (this.pageInView() ? ' document-wrapper--active' : '')
          ">
          <img
            appImgLoader
            [src]="getDocumentPreviewSrc(pageOrFile())"
            [alt]="pageOrFile().title" />
          <div
            *ngIf="preview.observed"
            (click)="previewClicked($event)"
            class="overlay">
            <ui-library-icon
              color="accent"
              icon="preview_zoom"></ui-library-icon>
          </div>
          <div
            class="favorite"
            *ngIf="page && page.favorite">
            <ui-library-icon
              icon="star_filled"
              size="8"
              color="warning-1"></ui-library-icon>
          </div>
        </div>
      }
      @if (file(); as file) {
        <ui-library-icon
          class="uj-flex uj-flex-col uj-items-center uj-justify-center uj-h-[36px]"
          icon="file_document_blank"
          size="20"
          color="dark"></ui-library-icon>
      }
      <div class="main-content">
        <div class="header">
          <span
            class="header__item header__item--page-number"
            *ngIf="pageNumber"
            >{{ pageNumber | number: "2.0" }}</span
          >
          <div class="header__item header__item--title">
            <span
              #titleElement
              [class]="
                'title' + (editingTitle() !== false ? ' title--editing' : '')
              "
              [contentEditable]="
                titleChange.observed && editingTitle() !== false
              "
              (click)="
                titleChange.observed && editingTitle() !== false
                  ? $event.stopPropagation()
                  : editTitleClicked($event)
              "
              (input)="updateEditingTitle($event)"
              (keyDown.enter)="saveEditedTitleClicked($event)"
              (keyDown.escape)="cancelEditingTitleEscapePushed($event)">
              {{ pageOrFile().title }}
            </span>
          </div>
          <div class="header__item header__item--controls">
            @if (file(); as file) {
              <button
                ui-library-button-text
                [disabled]="
                  downloadStatus().progress !== 0 ||
                  downloadStatus().errorMessage
                "
                color="dark"
                iconOnly="download"
                (click)="downloadDocument(pageOrFile())"></button>
            }
            @if (page(); as page) {
              <button
                *ngIf="toggleFavorite.observed && editingTitle() === false"
                ui-library-button-text
                color="warning-1"
                uiLibraryTooltip
                [tooltip]="
                  'favoritesWillBeUsedByDefaultDuringRecommendation' | i18next
                "
                [iconOnly]="page.favorite ? 'star_filled' : 'star'"
                (click)="toggleFavoriteClicked($event)"></button>
              <button
                *ngIf="titleChange.observed && editingTitle() !== false"
                ui-library-button-text
                color="dark"
                iconOnly="check"
                (click)="saveEditedTitleClicked($event)"></button>
              <button
                *ngIf="makeActive.observed"
                ui-library-button-text
                color="dark"
                iconOnly="restore_unarchive"
                (click)="makeActiveClicked($event)"></button>
              <div
                class="uj-w-[1px] uj-h-[20px] uj-bg-border-border uj-mx-b"></div>
              <button
                ui-library-button-text
                [contextMenu]="contextMenu"
                (click)="$event.stopPropagation()"
                iconOnly="menu_dots_vertical"></button>
              <ng-template #contextMenu>
                <div class="uj-flex uj-flex-col uj-items-start">
                  <button
                    ui-library-button-text
                    fullWidth
                    align="start"
                    *ngIf="moveUp.observed"
                    color="dark"
                    iconLeft="arrow_up"
                    [disabled]="pageNumber === 1"
                    (click)="moveUpClicked($event)">
                    {{ "moveUp" | i18next }}
                  </button>

                  <button
                    ui-library-button-text
                    fullWidth
                    align="start"
                    *ngIf="moveDown.observed"
                    color="dark"
                    iconLeft="arrow_down"
                    (click)="moveDownClicked($event)">
                    {{ "moveDown" | i18next }}
                  </button>

                  <button
                    *ngIf="makeActive.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="restore_unarchive"
                    (click)="makeActiveClicked($event)">
                    {{ "restore" | i18next }}
                  </button>

                  <ui-library-divider></ui-library-divider>

                  <button
                    *ngIf="markUnread.observed"
                    [disabled]="!page.read"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="accent"
                    color="dark"
                    iconLeft="file_document_dot_unread"
                    (click)="this.markUnreadClicked($event)">
                    {{ "markAsUnread" | i18next }}
                  </button>
                  <button
                    *ngIf="toggleFavorite.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    [iconLeft]="
                      !page.favorite
                        ? 'star_filled'
                        : 'star_remove_unstar_cross_2'
                    "
                    (click)="toggleFavoriteClicked($event)">
                    {{
                      page.favorite
                        ? ("unfavorite" | i18next)
                        : ("makeFavorite" | i18next)
                    }}
                  </button>

                  <button
                    *ngIf="titleChange.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="edit_pen_1"
                    (click)="editTitleClicked($event)">
                    {{ "rename" | i18next }}
                  </button>
                  <button
                    *ngIf="archive.observed"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="accent"
                    color="dark"
                    iconLeft="archive"
                    (click)="this.archiveClicked($event)">
                    {{ "archive" | i18next }}
                  </button>
                  <ui-library-divider></ui-library-divider>
                  <button
                    *ngIf="preview.observed"
                    (click)="previewClicked($event)"
                    ui-library-button-text
                    fullWidth
                    align="start"
                    color="dark"
                    iconLeft="preview_zoom">
                    {{ "preview" | i18next }}
                  </button>
                  <ng-container
                    *ngIf="
                      downloadStatus().progress === 0 ||
                      downloadStatus().errorMessage
                    ">
                    <button
                      ui-library-button-text
                      fullWidth
                      align="start"
                      color="dark"
                      iconLeft="download"
                      (click)="downloadDocument(pageOrFile())">
                      {{ "download" | i18next }}
                    </button>
                  </ng-container>
                </div>
              </ng-template>
            }
          </div>
        </div>

        <div class="main-content__content">
          <div class="date_and_source">
            <span>{{
              pageOrFile().createdAt | date: "dd.MM.yyyy - HH:mm"
            }}</span>
          </div>
          <div class="page_and_filename">
            @if (page(); as page) {
              @if (
                page.talentFileReference.mediaObject.metadata?.numberOfPages;
                as numberOfPages
              ) {
                @if (numberOfPages > 1) {
                  <span>{{ "page" | i18next }}</span>
                  <span>{{ page.page }}</span>
                  <span>{{ "from" | i18next }}</span>
                  <span>{{ numberOfPages }}</span>
                }
              }
              <span
                class="page_and_filename__title"
                [title]="page.talentFileReference.title">
                ({{ page.talentFileReference.title }})</span
              >
            }
            @if (file(); as file) {
              <span>{{ file.title }}</span>
            }
          </div>
        </div>

        <ng-container
          *ngIf="
            downloadStatus().progress > 0 && !downloadStatus().errorMessage
          ">
          <ui-library-progress-bar
            [progress]="downloadStatus().progress"
            [hint]="'downloading' | i18next"></ui-library-progress-bar>
        </ng-container>
        <unijob-info-box
          *ngIf="downloadStatus()?.errorMessage"
          [title]="'error' | i18next"
          [message]="downloadStatus().errorMessage ?? ''"
          type="ERROR"
          [closable]="true">
        </unijob-info-box>
      </div>
    </div>
  </div>
</ui-library-card>
