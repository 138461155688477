{
  "13thPaycheck": "13. Monatslohn",
  "1DayToApprove": "1 freizugebender Tag",
  "1DayToTransfer": "1 Tag zu übermitteln",
  "absence": "Absenz",
  "absenceTimespan": "Absenz Zeitraum",
  "absent": "Abwesenheit",
  "acceptedFileTypes": "png, jpg, pdf, doc, max. 2MB",
  "acceptTermsAndConditions": "Ich bin mit den Datenschutz-bestimmungen einverstanden",
  "accident": "Unfall",
  "actions": "Aktionen",
  "active": "Aktiv",
  "activeEmployees": "Aktive Mitarbeitende",
  "activeEmployments": "Aktive Einsätze",
  "activeLimited": "aktiv von {{ from }} ({{ fromDays }}) bis {{ until }} ({{ untilDays }})",
  "activeRuntime": "aktive Laufzeit {{ from }} - {{ until }}",
  "activeSince": "aktiv seit {{ from }} ({{ fromDays }})",
  "activeTill": "aktiv bis {{ until }} ({{ untilDays }})",
  "addAddon": "Add-on hinzufügen",
  "addAttachment": "Anhang hinzufügen",
  "addEntry": "Eintrag hinzufügen",
  "addHoursRowNote": "Kostenstelle oder sonstige Bemerkung",
  "addLanguage": "Sprache hinzufügen",
  "addNote": "Notiz hinzufügen",
  "addOn": "Add-on",
  "addOnSearch": "Add-on: Search",
  "addOnSearchMax": "Max",
  "addOnSearchPush": "Push",
  "addOnSearchTarget": "Target",
  "addReceipt": "Beleg hinzufügen",
  "addRemarksLikeKeywords": "Bemerkungen wie z.B. spezielle Keywords usw. hinzufügen",
  "address": "Adresse",
  "addTab": "Tab hinzufügen",
  "adjustedBy": "angepasst durch",
  "adjustment": "Korrektur",
  "adjustmentNote": "Hinweis zur Korrektur",
  "adjustSearchOrFilter": "Passe die Suche / Filter-Eingaben an oder setzte sie zurück.",
  "adjustSearchOrResetFilter": "Passe die Suche / Filter-Eingaben an oder setzte sie zurück.",
  "advertisement": "Inserat",
  "after": "nachher",
  "age": "Alter",
  "ahvCard": "AHV Karte",
  "ahvNumber": "AHV-Nummer",
  "all": "Alle",
  "allBranches": "Alle Filialen",
  "allTalents": "Alle Talente",
  "allRequiredFieldsAreFilled": "Alle notwendigen Felder ausgefüllt",
  "allVacancies": "Alle Vakanzen",
  "alreadyImported": "bereits importiert",
  "amountDaysWithoutHoursEntries": "Anzahl Tage ohne Einträge",
  "amountOfAbsences": "Anzahl Absenzen",
  "applicationFormLink": "Link Bewerbungsformular",
  "apply": "Übernehmen",
  "approve": "Freigeben",
  "approved": "freigegeben",
  "approveDay": "Tag freigeben",
  "approveDays": "Tage freigeben",
  "approveDaysConfirmation": "Willst du Einträge freigeben?",
  "approveDaysHint": "Danach können die Tage nicht mehr korrigiert werden",
  "archiveReason": "Archivierungsgrund",
  "areAllEntriesChecked": "Alle Einträge dieser Woche überprüft?",
  "areAllEntriesComplete": "Alle Einträge dieser Woche getätigt?",
  "areYouSure": "Bist du sicher?",
  "ascending": "Aufsteigend",
  "assignment": "Vertrag",
  "attachment": "Anhang",
  "attention": "Achtung",
  "authenticate": "Authentifizieren",
  "authentication": "Authentifizierung",
  "autoDetectedAfterTheVacancyIsPublished": "Wird automatisch nach dem Veröffentlichen der Vakanz erkannt.",
  "bankAccountNumber": "Kontonummer",
  "bankingInfo": "Bankverbindung",
  "bankingInfoUsageHint": "Deine Bankinformationen werden für die Lohnauszahlung benötigt.",
  "before": "vorher",
  "beingCompressed": "Wird komprimiert...",
  "beingUploaded": "Wird hochgeladen...",
  "benefit": "Profitieren",
  "benefits": "Vorteile",
  "billing": "Abrechnung",
  "branch": "Filiale",
  "branchVacancies": "Vakanzen Filiale",
  "bulkEdit": "Mehrfachbearbeitung",
  "byArrangement": "nach Vereinbarung",
  "byDate": "nach Datum",
  "calendarWeekX": "KW {{week}}",
  "call": "Anrufen",
  "cancel": "Abbrechen",
  "talents": "Talente",
  "cannotBeCombined": "Aktuell können Add-on’s nicht kombiniert werden.",
  "changeCanNotBeRevertedAsContentIsIdentical": "Die Änderung kann nicht zurückgesetzt werden, da sie mit dem aktuellen Inhalt identisch ist.",
  "changeHistory": "Änderungsverlauf",
  "changelogActions": {
    "activated": "aktiviert",
    "added": "hinzugefügt",
    "changed": "geändert",
    "created": "erstellt",
    "deactivated": "deaktiviert",
    "removed": "entfernt",
    "restored": "wiederhergestellt"
  },
  "changelogStatus": {
    "ARCHIVED": "hat <span class='text-m-b'>{{entryName}}</span> archiviert",
    "DELETED": "hat <span class='text-m-b'>{{entryName}}</span> gelöscht",
    "DRAFT": "hat <span class='text-m-b'>{{entryName}}</span> erstellt",
    "LIVE": "hat <span class='text-m-b'>{{entryName}}</span> veröffentlicht",
    "SCHEDULED": "hat <span class='text-m-b'>{{entryName}}</span> geplant"
  },
  "changelogHubBotActions": {
    "activated": "hat <span class='text-m-b'>{{entryName}}</span> aktiviert",
    "added": "hat <span class='text-m-b'>{{entryName}}</span> hinzugefügt",
    "changed": "hat <span class='text-m-b'>{{entryName}}</span> geändert",
    "created": "hat <span class='text-m-b'>{{entryName}}</span> erstellt",
    "deactivated": "hat <span class='text-m-b'>{{entryName}}</span> deaktiviert",
    "removed": "hat <span class='text-m-b'>{{entryName}}</span> entfernt",
    "scheduled": "hat <span class='text-m-b'>{{entryName}}</span> geplant"
  },
  "changePicture": "Bild ändern",
  "changeReverted": "Änderung zurückgesetzt",
  "changes": "Änderungen",
  "changesApply": "Anwenden",
  "changeSelection": "Wechseln",
  "changesPublished": "Änderungen veröffentlicht",
  "changeVacancy": "Vakanz ändern",
  "changeXVacancies": "{{count}} Vakanzen ändern",
  "character": "Zeichen",
  "charged": "Verrechnet",
  "checklist": "Checkliste",
  "checkYourInput": "Prüfe deine Eingabe",
  "checkYourSearchInput": "Prüfe deine Sucheingabe.",
  "choose": "Auswählen",
  "chooseAbsence": "Absenz auswählen",
  "chooseAPlan": "Wähle ein Packet",
  "chooseCompanyContact": "Kontakt auswählen",
  "chooseCompanyLocation": "Unternehmen/Standort auswählen",
  "chooseDate": "Datum auswählen",
  "chooseDateRange": "Zeitraum auswählen",
  "chooseDocument": "Dokument auswählen",
  "chooseEmployment": "Anstellung wählen",
  "chooseFile": "Datei auswählen",
  "chooseFiles": "Dateien auswählen",
  "chooseFileType": "Dateityp Auswählen",
  "chooseFunction": "Funktion wählen",
  "chooseType": "Typ auswählen",
  "city": "Ort",
  "civilOrigin": "Bürgerort",
  "clearSearchHistory": "Suchverlauf löschen",
  "close": "Schliessen",
  "commission": "Provision",
  "commissionOverLimit": "Provision über Limit",
  "commissionSplitDoesNotAddUpToTotal": "Die Summe der Einträge stimmt nicht mit Provision überein",
  "commissionTotalWithoutTax": "Total exkl. MwSt",
  "companies": "Unternehmen",
  "company": "Firma",
  "companyCreated": "Unternehmen erstellt!",
  "companyDeleted": "Unternehmen gelöscht!",
  "companyDuplicatedSuccessfully": "Unternehmen erfolgreich dupliziert!",
  "companyLocation": "Unternehmen",
  "companySavedSuccessfully": "Unternehmen gespeichert",
  "companySheetAlreadyOpen": "Es wird bereits eine Firma bearbeitet",
  "companyShortName": "Firmenname (Kurzform)",
  "confirmationEmailSent": "Wir haben dir eine Bestätigungs-E-Mail an <b>{{email}}</b> gesendet.",
  "confirmDelete": "Löschen bestätigen",
  "confirmDeleteTab": "Möchtest du den Tab wirklich löschen?",
  "confirmWithEnter": "Mit Enter ↵ Bestätigen",
  "contact": "Kontakt",
  "contactOnTeams": "Teams",
  "contactPerson": "Kontaktperson",
  "contacts": "Kontakte",
  "contactUs": "Kontaktiere uns",
  "copy": "Kopie",
  "copyLink": "Link kopieren",
  "copyToClipboard": "In Zwischenablage kopieren",
  "cost": "Kosten",
  "country": "Land",
  "createAbsence": "Absenz erfassen",
  "createdAt": "Erstellt am",
  "createNewUser": "Neuen Benutzer erstellen",
  "createUser": "Benutzer erstellen",
  "creationDate": "Erstelldatum",
  "criteria": "Kriterien",
  "cropProfileImage": "Profilbild zuschneiden",
  "currentVersion": "Aktuelle Version",
  "customer": "Kunde",
  "dashboard": "Übersicht",
  "date": "Datum",
  "dateOfBirth": "Geburtsdatum",
  "dayApprove": "Tag Freigaben",
  "days": "Tage",
  "daySort": "Tage sortieren",
  "delete": "Löschen",
  "deleteAll": "Alle löschen",
  "deleteEntry": "Eintrag löschen",
  "deleteVacancy": "Vakanz löschen?",
  "deleteVacancyConfirmationHint": "Möchten Sie die Vakanz wirklich löschen?",
  "descending": "Absteigend",
  "description": "Beschreibung",
  "descriptionAndTitle": "Beschreibung / Titel",
  "desiredAge": "Wunschalter",
  "details": "Details",
  "deviatingRecipient": "Abweichende begünstigte Person",
  "directions": "Wegbeschreibung",
  "discardChanges": "Verwerfen",
  "displayAbsence": "Absenz anzeigen",
  "displayInJobAdvertisement": "im Stelleninserat anzeigen",
  "displayReport": "Rapport anzeigen",
  "documents": "Dokumente",
  "documentType": "Dokumenttyp",
  "doNotUseEmailOrPhone": "Verwende keine E-Mail-Adressen oder Kontaktangaben",
  "doYouWantToRevertThisChange": "Möchtest du diese Änderung zurücksetzen?",
  "draft": "Entwurf",
  "driversLicense": "Führerschein",
  "dropDocumentHere": "Dokument hier ablegen",
  "dropDocumentsHere": "Dokumente hier ablegen",
  "duplicate": "Duplizieren",
  "duplicateVacancy": "Vakanz duplizieren",
  "duration": "Dauer",
  "edit": "Bearbeiten",
  "editAbsence": "Absenz anpassen",
  "editColumns": "Spalten bearbeiten",
  "editCompany": "Unternehmen bearbeiten",
  "editNote": "Notiz bearbeiten",
  "editReport": "Rapport bearbeiten",
  "editTable": "Tabelle bearbeiten",
  "editTabs": "Tabs bearbeiten",
  "editVacancies": "Vakanzen bearbeiten",
  "editVacancy": "Vakanz bearbeiten",
  "editXCompanies": "{{amount}} Unternehmen bearbeiten",
  "editXVacancies": "{{amount}} Vakanzen bearbeiten",
  "education": "Ausbildung",
  "email": "E-Mail",
  "emailAddress": "E-Mail-Adresse",
  "emailIsNowVerified": "Danke, die Email {{email}} ist jetzt verifiziert",
  "emailSent": "E-Mail gesendet",
  "employee": "Mitarbeiter",
  "employeeResponsibility": "Zuständigkeit",
  "employeeResponsibilityHint": "Sie erhalten nur für die ausgewählten Mitarbeitenden Benachrichtigungen.",
  "employees": "Mitarbeitende",
  "employment": "Anstellung",
  "employments": "Einsätze",
  "employmentSaved": "Einsatz gespeichert",
  "emptyEmployeeData": "Es wurden noch keine Daten übertragen",
  "emptyOption": "Leere Option",
  "emptyTemplate": "Leere Vorlage",
  "emptyVacancy": "Leere Vakanz",
  "end": "Ende",
  "endVacancy": "Vakanz beenden",
  "english": "Englisch",
  "enoughApplicantsReceived": "Genug Bewerber erhalten",
  "enterAmount": "Betrag eingeben",
  "enterJobDescription": "Stellenbeschreibung eingeben",
  "enterReport": "Rapport erfassen",
  "enterSearchTerm": "Suchbegriff eingeben",
  "enterUrl": "URL eingeben",
  "enterWorkloadPercentage": "Pensum angeben",
  "entries": "Einträge",
  "entryDeleteConfirmation": "Eintrag löschen?",
  "entryDeleted": "Eintrag gelöscht",
  "entryDeleteHint": "Der Eintrag ist nicht wiederrufbar.",
  "entryRemoved": "Eintrag entfernt",
  "error_description": {
    "accessExpired": "Leider ist der verwendete Link abgelaufen",
    "accessExpiredHint": "Bitte klicke auf login und danach auf Passwort vergessen um einen neuen Link zu erhalten",
    "linkOnlyValidOnce": "Dieser Link ist nur einmal gültig",
    "linkOnlyValidOnceHint": "Bitte verwende deine bestehende Login Daten um dich anzumelden"
  },
  "publishExactJobLocationInsteadOfReplacement": "Genauer Arbeitsort statt Region verwenden",
  "publishExactJobLocation": "Genauen Standort verwenden",
  "existingCompanies": "Bestehende Unternehmen",
  "existingVacancies": "bestehende Vakanzen",
  "expenses": "Spesen",
  "export": "Exportieren",
  "extendSearch": "Suche erweitern",
  "fieldRequired": "Bitte fülle dieses Pflichtfeld aus",
  "fieldsRequired": "Bitte alle Pflichtfelder ausfüllen",
  "file": "Datei",
  "fileDeleteConfirmation": "Datei löschen?",
  "fileDeleteHint": "Die Datei kann nicht wiederhergestellt werden.",
  "fileRemoved": "Datei entfernt",
  "fileToLargeError": "Datei zu gross",
  "fileTooBig": "Die Datei überschreitet das Upload-Limit von 5 Mb",
  "fileType": "Dateityp",
  "fileUploadComplete": "Datei erfolgreich hochgeladen",
  "filter": "Filter",
  "finishedRuntime": "beendet, Laufzeit {{ from }} - {{ until }}",
  "firstName": "Vorname",
  "french": "Französisch",
  "from": "von",
  "fromValueCanNotBeGreaterThanToValue": "«von Wert» kann nicht grösser sein als «bis Wert»",
  "galaxusBenefitDescription": "Galaxus Mobile Handy-Abos: unlimitierte Daten für nur <span class='text-l-b'> 15.- im Monat. </span> Für jedes Familienmitglied <span class='text-l-b'> spart ihr alle 1.-/Mt. pro Abo. </span>  <br/> <br/> Jetzt unseren Firmencode nutzen und abonnieren. ",
  "generalError": "Fehler",
  "generalErrorText": "Es ist ein unbekannter Fehler aufgetreten. Wir bitten um Entschuldigung.",
  "german": "Deutsch",
  "goodDay": "Guten Tag",
  "goodEvening": "Guten Abend",
  "goodMorning": "Guten Morgen",
  "gotoLogin": "Zum Login",
  "great": "Super!",
  "hasAccountQuestion": "Bereits ein Konto?",
  "hasAllowedDataUsageAt": "Datenschutzerklärung akzeptiert",
  "hasHandWrittenHours": "Die Stunden handschriftlich erfasst?",
  "hasNotBeenActivated": "wurde nicht aufgeschaltet",
  "hasNotBeenPublished": "wurde nicht veröffentlicht",
  "help": "Hilfe",
  "hideLocation": "Standort verbergen",
  "hideSalary": "Gehalt im Inserat verborgen",
  "history": "Verlauf",
  "historyEntriesWillBeDisplayHere": "Hier werden die Änderungen angezeigt.",
  "hour": "Stunde",
  "hours": "Stunden",
  "hoursToBeApproved": "Freizugebende Stunden",
  "iban": "IBAN",
  "imageCompressionError": "Bild konnte nicht komprimiert werden.",
  "immediately": "per sofort",
  "import": "Importieren",
  "importCompany": "Unternehmen importieren",
  "importCompanyInstruction": "Um neue Unternehmen hinzuzufügen oder bestehende Unternehmen anzupassen, muss derzeit immer noch das alte UniBase verwendet werden.<br />Die Anpassungen werden synchronisiert.",
  "importContact": "Kontakt importieren",
  "importContactInstruction": "Um neue Kontakte hinzuzufügen oder bestehende Kontakte anzupassen, muss derzeit immer noch das alte UniBase verwendet werden.<br />Die Anpassungen werden synchronisiert.",
  "iMyself": "Ich",
  "inactive": "Inaktiv",
  "inactiveEmployees": "Inaktive Mitarbeitende",
  "inactiveEmployments": "Vergangene Einsätze",
  "inactiveRuntime": "inaktiv, Laufzeit {{ from }} - {{ until }}",
  "inactiveSince": "inaktiv seit {{ from }} ({{ fromDays }})",
  "info": "Info",
  "initialVacancyActivation": "Erstaufschaltung Vakanz",
  "inputHours": "Stunden erfassen",
  "intern": "Intern",
  "internalChannels": "Interne Kanäle",
  "internNoteExample": "z.B. Kunde wünscht sich einen Mann",
  "invalidAccountOrCode": "Ungültiges Konto oder Code",
  "invalidHoursRow": "Bitte überprüfe die Stunden",
  "invalidIBAN": "Bitte überprüfe deine IBAN",
  "invalidPhone": "Bitte überprüfe deine Telefonnummer",
  "invalidTime": "Ungültige Zeit",
  "isoWeek": "KW",
  "italian": "Italienisch",
  "job": "Beruf",
  "JobAdvert": "Stelle",
  "jobCompany": "Unternehmen",
  "jobLocationAddressReplacement": "Region",
  "jobNotificationRequired": "Stellemeldepflicht",
  "jobTitle": "Stellenbezeichnung",
  "jobTitleShown": "Angezeigter Stellentitel",
  "jobTitleShownDescription": "Dieser Titel wird im Inserat angezeigt.",
  "just_now": "gerade eben",
  "language": "Sprache",
  "languageRequirements": "Sprachen",
  "languages": "Sprachen",
  "last_changes_at": "Letzte Änderung  am {{timePast}}",
  "last_changes_hours_ago": "Letzte Änderung vor {{timePast}} h",
  "last_changes_hours_ago_short": "vor {{timePast}} h",
  "last_changes_minutes_ago": "Letzte Änderung vor {{timePast}} min",
  "last_changes_minutes_ago_short": "vor {{timePast}} min",
  "saving": "speichert...",
  "lastName": "Nachname",
  "lastUpdated": "Zuletzt aktualisiert",
  "lastUpdatedAt": "Zuletzt aktualisiert am",
  "leadershipPosition": "Führungsposition",
  "linkCopied": "Link kopiert",
  "linkInvalid": "Link ungültig",
  "linkInvalidText": "Der verwendete Link ist ungültig. Entweder wurde das Passwort bereits geändert oder der Link ist abgelaufen.",
  "linkToAdvert": "Link Stelle",
  "linkToVacancy": "Link zur Vakanz",
  "list": "Liste",
  "location": "Ort",
  "loggingIn": "Anmeldung läuft...",
  "loggingOut": "Abmeldung läuft...",
  "login": "Anmeldung",
  "logout": "Abmelden",
  "manageEntries": "Einträge verwalten",
  "manualSlots": "Manuelle Slots",
  "externalChannels": "Externe Kanäle",
  "personalSlots": "Persönliche Kanäle",
  "manualSlotsInfoText": "Hier findest du die Links für die Job-Plattormen mit manuellen Slots.",
  "personalSlotsInfoText": "Hier findest du die Links um sie auf deinen persönlichen Kanäle zu posten.",
  "maritalStatus": "Zivilstand",
  "MAX": "Max",
  "maxLengthCharacters": "Dieses Feld darf maximal {{max}} Zeichen beinhalten",
  "maxPlanTooltip": "Reichweite und gezieltes Audience Targeting",
  "maxValue": "Max-Wert",
  "me": "Ich",
  "microsoftTeams": "Teams öffnen",
  "minLengthCharacters": "Dieses Feld muss mindestens {{min}} Zeichen beinhalten",
  "minutes": "Minuten",
  "minutesShort": "min",
  "minValue": "Min-Wert",
  "mipDuration": "MIP Dauer",
  "missingAbsenceDuration": "Bitte Dauer der Absenz angeben",
  "missingAbsenceType": "Bitte Absenzart auswählen",
  "missingAdjustmentDuration": "Bitte den Wert der Korrektur angeben",
  "missingDuration": "Bitte Dauer angeben.",
  "missingExpenseCost": "Bitte Betrag erfassen",
  "missingExpenseNote": "Bitte eine Beschreibung der Spesen angeben",
  "missingFromTime": "Bitte Startzeit eingeben",
  "missingToTime": "Bitte Endzeit eingeben",
  "moneyAmount": "Betrag",
  "month": "Monat",
  "moreActions": "Weitere Aktionen",
  "multiEditFailed": "Die Vakanzen konnten nicht bearbeitet werden.",
  "mustServeMilitaryService": "Militärdienstpflichtig",
  "myVacancies": "Meine Vakanzen",
  "name": "Name",
  "nationality": "Nationalität",
  "negativeDuration": "Die Arbeitsdauer an einem Tag darf nicht negativ sein.",
  "new": "Neu",
  "newCompany": "Neues Unternehmen",
  "newContact": "Neuer Kontakt",
  "newDocuments": "Neue Dokumente",
  "newVacancy": "Neue Vakanz",
  "newTalent": "Neues Talent",
  "nextMilitaryService": "Nächster Militärdienst",
  "nextPaymentIntendedAt": "nächste Auszahlung vorgesehen am",
  "no": "Nein",
  "noAbsenceTypeSelected": "Bitte Absenztyp auswählen",
  "noAccountQuestion": "Noch kein Konto?",
  "noChangesFound": "Keine Änderungen gefunden",
  "noCompanyFound": "Keine Unternehmen gefunden",
  "unfortunatelyNoCompanyFound": "Leider kein Unternehmen gefunden",
  "checkSearchInputsOrImportCompany": "Prüfe deine Sucheingabe oder importiere das Unternehmen aus dem alten UniBase.",
  "noContactFound": "Leider kein Kontakt gefunden",
  "noDataSelected": "Im Ausgewählten Zeitraum gibt es keine zu übertragenden Daten",
  "noDataToTransfer": "Es gibt aktuell keine Daten zu übermitteln.",
  "noDocumentsFound": "Es sind keine Dokumente vorhanden",
  "noDocumentUploaded": "Kein Dokument hochgeladen",
  "noEmailRecievedQuestion": "Keine E-Mail erhalten?",
  "noEmployeeFound": "Leider haben wir keine Mitarbeiter gefunden",
  "noEmployeesDisplayed": "Es werden keine Einträge angezeigt. Passen Sie bei Bedarf den Filter bei \"Zuständigkeit\" an.",
  "noEmployments": "Keine Einsätze vorhanden",
  "noEmploymentsFound": "Leider haben wir keine Einsätze gefunden.",
  "noEntries": "Keine Einträge",
  "noEntriesInSearchHistory": "Keine Einträge im Suchverlauf",
  "noJobNotificationRequired": "Keine Stellemeldepflicht",
  "nonCompeteAgreement": "Konkurrenzverbot",
  "noNegativeCommission": "Keine negative Provision erlaubt",
  "noNewEmploymentsOrDocuments": "Es gibt aktuell keine aktiven Einsätze oder neuen Dokumente.",
  "noNews": "Keine Neuigkeiten.",
  "noReceiptUploaded": "Kein Beleg hochgeladen",
  "noReportSelected": "Bitte Zeitraum und Rapport angeben",
  "noSelectOptions": "Keine Auswahlmöglichkeiten",
  "note": "Notiz",
  "notes": "Notizen",
  "nothingToDo": "Im Moment gibt es keine Pendenzen.",
  "noTimespanSelected": "Bitte Zeitraum auswählen",
  "online": "online",
  "onlyAfterPublishing": "Add-on’s können erst nach der Veröffentlichung der Vakanz hinzugefügt werden.",
  "oops": "Ups",
  "open": "offen",
  "openHours": "offene Stunden",
  "openMap": "Karte öffnen",
  "or": "oder",
  "orderWithCosts": "Kostenpflichtig bestellen",
  "originalAd": "Original Inserat",
  "others": "Sonstige",
  "overlappingHoursRows": "Die Stundeneinträge eines Tages dürfen sich nicht überschneiden",
  "own": "Eigene",
  "pageNotFound": "Wir können die Seite, die Sie suchen, leider nicht finden.",
  "paid": "ausbezahlt",
  "participate-now": "Jetzt mitmachen",
  "partTime": "Teilzeit",
  "past": "Vergangene",
  "pause": "Pause",
  "paySlips": "Lohnabrechnungen",
  "permanentPosition": "Festanstellung",
  "personalDetails": "Persönliche Angaben",
  "personalDocuments": "Persönliche Dokumente",
  "phone": "Telefonnummer",
  "phoneNumber": "Telefonnummer",
  "phoneNumberMustStartWith+": "Bitte geben Sie die Vorwahl der Telefonnummer an (zB. +41)",
  "placeOfBirth": "Geburtsort",
  "platformLinks": "Plattformen-Links",
  "platformLinksOnlyAvailableForLiveVacancies": "Plattformen-Links können erst nach der Veröffentlichung der Vakanz abgerufen werden.",
  "platforms": "Plattformen",
  "pleaseFillAllTheRequiredFields": "Bitte fülle alle Pflichtfelder aus.",
  "pleaseCheckTheFollowingFields": "Bitte folgende Felder prüfen!",
  "pleaseFillJobTitleFirst": "Bitte zuerst Stellenbezeichnung ausfüllen.",
  "pleaseResolveErrorsInOrderToSave": "Bitte behebe die Fehler, um zu speichern.",
  "pleaseSafeBeforeAddingAbsence": "Bitte speichere deine Änderungen bevor du eine Absenz erfasst",
  "pleaseSafeBeforeAddingExpenses": "Bitte speichere deine Änderungen bevor du Spesen erfasst",
  "pleaseSafeBeforeEditingAbsence": "Bitte speichere deine Änderungen bevor du eine Absenz bearbeitest",
  "pleaseSafeBeforeEditingExpenses": "Bitte speichere deine Änderungen bevor du Spesen bearbeitest",
  "pleaseSafeBeforeReportUpload": "Bitte speichere deine Änderungen bevor du einen Rapport hochlädst",
  "pleaseSafeBeforeTransfer": "Bitte speichere deine Änderungen bevor du Daten übermittelst",
  "pleaseSafeEntryBeforeUpload": "Bitte den Eintrag vor dem Upload speichern",
  "pleaseSelectDocumentType": "Bitte wähle einen Dokumenttyp aus.",
  "pleaseSelectOneOfTheFollowingReasons": "Bitte wähle einen der folgenden Gründe aus.",
  "pleaseStandBy": "Bitte habe einen Augenblick Geduld.",
  "pleaseVerifyYourEmail": "Bitte verifiziere deine E-Mail-Adresse.",
  "politePleaseSafeBeforeAcceptingHours": "Bitte speichern Sie die Änderungen bevor Sie Stunden freigeben",
  "politePleaseSafeBeforeAcceptingReport": "Bitte speichern Sie die Änderungen bevor Sie den Rapport freigeben",
  "politePleaseSafeBeforeRejectingHours": "Bitte speichern Sie die Änderungen bevor Sie Stunden zurückweisen",
  "politePleaseSafeBeforeReportUpload": "Bitte speichern Sie die Änderungen bevor Sie einen Rapport hochladen",
  "position": "Position",
  "possiblyOther": "ggf. weitere",
  "possiblyPermit": "ggf. Bewilligung",
  "preferredEmploymentType": "Bevorzugte Anstellung",
  "preferredLocationRadiusKm": "Maximale Distanz",
  "preferredMaxCommutingTimeMinutes": "Maximale Pendelzeit",
  "preferredWorkArrangement": "Bevorzugtes Arbeitsmodell",
  "prefersCommutingByBicycle": "Fahrrad",
  "prefersCommutingByCar": "Auto",
  "prefersCommutingByPublicTransport": "ÖV",
  "presentation": "Darstellung",
  "preview": "Vorschau",
  "primaryAddressLocation": "Ort",
  "primaryAddressStreet": "Strasse",
  "primaryAddressZip": "PLZ",
  "primaryEmail": "Primäre E-Mail-Adresse",
  "primaryPhone": "Primäre Telefonnummer",
  "professionalTitle": "Berufsbezeichnung",
  "profile": "Profil",
  "profileImageRemoved": "Profilbild entfernt",
  "profileImageSaved": "Profilbild gespeichert",
  "promos": "Promos",
  "promote": "Promote",
  "publicationChanged": "Zuletzt veröffentlicht",
  "publicationChangedAt": "Zuletzt veröffentlicht am",
  "publish": "Veröffentlichen",
  "publishChanges": "Änderungen veröffentlichen",
  "publishChangesInDays": "In {{days}} Tagen veröffentlichen",
  "publishVacancy": "Vakanz veröffentlichen",
  "publishVacancyConfirmationHint": "Möchten Sie die Vakanz wirklich veröffentlichen?",
  "publishVacancyInDays": "In {{days}} Tagen veröffentlichen",
  "PUSH": "Push",
  "pushPlanTooltip": "Platzierung auf allen relevanten Jobplattformen",
  "qualifications": "Qualifikationen",
  "readDocuments": "Gelesene Dokumente",
  "readyToPublish": "Ready zum live schalten?",
  "reason": "Grund",
  "reasonForArchiving": "Grund für Archivierung",
  "redirectingToLogin": "Du wirst zum Login weitergeleitet...",
  "referenceNumber": "Referenznummer",
  "register": "Registrieren",
  "registeredWithRav": "Beim RAV gemeldet",
  "registration": "Registrierung",
  "registrationCompleted": "Deine Registrierung wurde erfolgreich abgeschlossen.",
  "reject": "Ablehnen",
  "rejectDay": "Tag ablehnen",
  "rejectDays": "Tage ablehnen",
  "rejectDaysHint": "Möchtest Du einen Grund angeben?",
  "rejectDaysReasonPlaceholder": "Ablehnungsgrund hinzufügen (sichtbar)",
  "rejected": "Abgelehnt",
  "rejectedInfoAt": "am",
  "rejectedInfoFrom": "Abgelehnt von",
  "religiousDenomination": "Konfession",
  "remark": "Bemerkung",
  "remarksForPublishing": "Bemerkungen für die Schaltung",
  "removePicture": "Bild entfernen",
  "rename": "Umbenennen",
  "replace": "Ersetzen",
  "report": "Rapport",
  "reportIsTransmittedDirectly": "Rapport wird direkt übermittelt",
  "reportIsTransmittedDirectlyMessage": "Achtung, der Rapport wird direkt übermittelt. Diese Aktion kann nicht rückgängig gemacht werden. Um Änderungen vorzunehmen, musst du unter “Kontakte” deinen Berater kontaktieren.",
  "reportState": "Daten über Rapport erfasst",
  "reportTimespan": "Rapport Zeitraum",
  "resendEmail": "E-Mail erneut senden",
  "resendSms": "SMS erneut senden",
  "resetSearchOrFilter": "Suche / Filter zurücksetzen",
  "residencePermit": "Aufenthaltsbewiiligung",
  "approval": "Bewilligung",
  "residencePermitValidUntil": "Aufenthaltsbewiiligung Gültig bis",
  "responsibleContact": "Zuständiger Kontakt",
  "responsibleEmployee": "Zuständige:r Mitarbeiter:in",
  "restore": "Wiederherstellen",
  "restoredSuccessfully": "Erfolgreich wiederhergestellt",
  "restoreHint": "Möchten Sie die Verison {{version}} wirklich wiederherstellen? Spätere Versionen gehen dabei verloren!",
  "restoreVersion": "Version wiederherstellen",
  "revert": "Zurücksetzen",
  "revertChange": "Änderung zurücksetzen",
  "sadlyWeDidntFindVacancies": "Leider haben wir keine Vakanzen gefunden \uD83D\uDE48",
  "salary": "Gehalt",
  "salaryCanNotBeZero": "Gehalt kann nicht leer oder «0» sein",
  "salaryCurrency": "Währung",
  "salaryExpectations": "Salärvorstellungen",
  "salaryFix": "Gehalt fix",
  "salaryHoursPerWeek": "Anzahl Stunden pro Woche",
  "publishSalary": "Gehalt im Inserat anzeigen",
  "salaryPaymentsPerYear": "Anzahl Zahlungen pro Jahr",
  "salaryRangeFromTo": "von - bis verwenden",
  "salaryUnit": "Gehaltseinheit",
  "samsungBenefitConditions": "Als Mitarbeiter:in von universaljob profitierst du von Rabatten auf ausgewählte Produkte und spannenden Bundle-Angeboten. Du musst nur auf folgenden Link klicken und erhältst Zugang zum internen Samsung-Shop. Viel Spass beim Einkaufen.",
  "save": "Speichern",
  "saveChanges": "Speichern",
  "saved": "Gespeichert",
  "savedTabs": "Gespeicherte Tabs",
  "saveSearchQuery": "Filter als Standard speichern",
  "scheduledToBePublished": "warten bis {{ from }} ({{ fromDays }}), aktiv von {{ from }} bis {{ until }}",
  "scrollTop": "Nach oben scrollen",
  "search": "Suche",
  "searchAddon": "Search",
  "searchTalents": "Talente suchen",
  "searchCompanyContactOptionsFooterInstruction": "Kontakt nicht in Liste vorhanden?",
  "searchCompanyContactPlaceholder": "Name, E-Mail, Telefon suchen",
  "searchCompanyLocationOptionsFooterInstruction": "Unternehmen nicht in Liste vorhanden?",
  "searchCompanyLocationPlaceholder": "Name, Strasse, PLZ, Ort suchen",
  "searchFilter": "Filter",
  "searchFilterReset": "Suche / Filter zurücksetzen",
  "searchFor": "nach \"{{searchText}}\" suchen",
  "searchIn": "in {{field}} suchen",
  "searchInDateOfBirth": "in Geburtsdatum suchen",
  "searchInEmail": "in E-Mail suchen",
  "searchInFirstName": "in Vorname suchen",
  "searchInLastName": "in Nachname suchen",
  "searchInLocation": "Im Ort suchen",
  "searchInPhone": "in Telefonnummer suchen",
  "searchInStreet": "In Strasse suchen",
  "searchInTitle": "Im Titel suchen",
  "searchInVacancyNumber": "In Vakanznummer suchen",
  "searchInWorkPlace": "Im Arbeitsort suchen",
  "searchInZip": "In PLZ suchen",
  "searchInPublishedWorkLocation": "Im veröffentlichten Arbeitsort suchen",
  "searchTermOrSelectCategory": "Suchbegriff eingeben oder Kategorie auswählen",
  "searchVacancies": "Vakanzen suchen",
  "securityCode": "Sicherheitscode",
  "selectBranch": "Filiale auswählen",
  "selectConsultant": "Personalberater auswählen",
  "selectDocumentType": "Dokumenttyp auswählen",
  "selectOptions": "Optionen auswählen",
  "selectReason": "Grund auswählen",
  "selectSort": "Sortierung auswählen",
  "selectValue": "Auswählen",
  "sendApplication": "Bewerben",
  "sentEmailTo": "Wir haben dir eine E-Mail an {{email}} geschickt.",
  "serverError": "Serverfehler",
  "settings": "Einstellungen",
  "share": "Teilen",
  "showAll": "Alle anzeigen",
  "showAllDocuments": "Alle Dokumente anzeigen",
  "showAllEmployments": "Alle Einsätze anzeigen",
  "showDetails": "Details anzeigen",
  "showHours": "Stunden anzeigen",
  "showMap": "Karte anzeigen",
  "showPlatformLinks": "Plattformen-Links anzeigen",
  "sick": "Krank",
  "sickness": "Krankheit",
  "signOn": "Anmelden",
  "signOnWith": "Mit {{type}} anmelden",
  "signUpWith": "Mit {{type}} registrieren",
  "since": "seit",
  "sinceArchived": "seit {{date}} ({{duration}} {{durationUnit}})",
  "sinceDaysPublished": "seit {{date}} ({{duration}} {{durationUnit}})",
  "sinceDeleted": "seit {{date}} ({{duration}} {{durationUnit}})",
  "skills": "Skills",
  "sorting": "Sortierung",
  "specialistResponsibility": "Fachverantwortung",
  "spellingChecked": "Rechtschreibung kontrolliert?",
  "splitCommission": "Provision aufteilen",
  "splitWithNoone": "Niemand auswählen",
  "staffConditions": "Mitarbeiterkondition",
  "start": "Start",
  "startAndEndTimeMustBeDifferent": "Start- und Endzeit müssen unterschiedlich sein",
  "startDate": "Startdatum",
  "wishDate": "Wunschdatum",
  "statements": "Bescheinigungen",
  "statistics": "Statistik",
  "status": "Status",
  "stop": "Beenden",
  "street": "Strasse",
  "streetAndNumber": "Strasse & Nr",
  "streetPlusNumber": "Strasse + Nr",
  "submit": "Übermitteln",
  "submitDaysConfirmation": "Willst du die Einträge übermitteln?",
  "submitDaysHint": "Danach können die Einträge im angegebenen Zeitraum nicht mehr geändert werden",
  "successfullySaved": "Erfolgreich gespeichert",
  "support": {
    "companyUser": {
      "adjust": "Korrigieren",
      "adjustText_1": "Wenn die Anzahl Stunden nicht korrekt ist, können Sie diese über einen Korrektur-Eintrag anpassen. Klicken Sie auf das Stift Symbol um den aktuellen Eintrag zu ändern. Der Original-Eintrag bleibt weiterhin durchgestrichen sichtbar.",
      "adjustText_2": "Der Mitarbeiter sieht die Korrektur, das Änderungsdatum und den Namen des Bearbeiters. Sie können nicht nur Zeiteinträge, sondern auch Notizen, Spesen Absenzen oder Rapporte korrigieren.",
      "adjustText_3": "Den jeweiligen Eintrag korrigieren",
      "approveAndReject": "Freigeben und Ablehnen",
      "approveText_1": "Sobald sie die Arbeitsstunden des Mitarbeiters fertig überprüft haben, können Sie diese über den Button Freigabe zur Auszahlung <span class='text-l-b'> freigeben. </span>",
      "approveText_2": "Nach dem Freigeben können keine Änderungen mehr vorgenommen werden.",
      "generalQuestionsText": "Bei Fragen wenden Sie sich an Ihren universaljob Kontakt, welchen Sie im Hauptmenü unter <span class='text-l-b'>Kontakt</span> finden.",
      "rejectText_1": "Falls die Daten noch Fehler enthalten oder zum Beispiel ein notwendiger Beleg nicht hinterlegt ist, können Sie die Stunden <span class='text-l-b'>Ablehnen</span>, sodass der Mitarbeiter die fehlenden Informationen angeben kann.",
      "rejectText_2": "Der Mitarbeiter kann die Stunden nur wieder bearbeiten, wenn Sie die Stunden ablehnen.",
      "reviewAndCorrect": "Überprüfen und Korrigieren",
      "reviewAndCorrectText_1": "Wenn ein Mitarbeiter seine Arbeitsstunden fertig erfasst und zur Überprüfung freigegeben hat, wird dieser gekennzeichnet. Klicken Sie im Hauptmenü auf <span class='text-l-b'> Mitarbeitende </span> um alle <span class='text-l-b'> offenen Freigaben  </span> zu sehen. <br> Um die Stunden einzusehen, klicken Sie beim entsprechenden Mitarbeiter auf <span class='text-l-b'> Stunden anzeigen</span>.",
      "technicalQuestions": "Technische Fragen",
      "technicalQuestionsText": "Bei technischen Schwierigkeiten mit hub.universaljob.ch oder Verbesserungsvorschlägen können Sie sich direkt an die Entwickler von hub.universaljob.ch wenden: <br><br>intemp AG <br> <a class='uj-underline' href='mailto:support@intemp.ch'> support@intemp.ch </a> <br> +41 52 551 22 22"
    },
    "employee": {
      "absenceEditOrDelete": "Absenz bearbeiten oder löschen",
      "absenceEditOrDeleteText": "Eingetragene Absenzen werden dem jeweiligen Tag hinzugefügt. Der Button <span class='text-l-b'>  Absenz anpassen </span>  wird sichtbar.",
      "absenceEditOrDeleteText_2": "Hier kann die Absenz geändert oder gelöscht werden. Auch dazugehörige Belege können vergrössert oder gelöscht werden.",
      "absenceEditOrDeleteText_3": "Wurde eine Absenz bereits übermittelt, kannst du sie nur noch öffnen um zum Beispiel den Beleg anzuschauen. Wie alle übermittelten Einträge kannst du sie nicht mehr bearbeiten.",
      "add": "Hinzufügen",
      "addHoursEntries": "Stunden-Einträge hinzufügen",
      "addNote": "Hinzufügen einer neuen Notiz",
      "addReceipt": "Hinzufügen eines neuen Belegs",
      "breaks": "Pausen",
      "breaksText_1": "Pausen kann man bei einem Stunden-Eintrag <span class='text-l-b'>manuell </span> eintragen. Sie werden dem Stunden-Total <span class='text-l-b'>abgezogen</span>",
      "breaksText_2": "Es wird <span class='text-l-b'>automatisch </span>eine Pause zwischen zwei Stunden-Einträgen erkannt",
      "callOperation": "Einsatz aufrufen",
      "clickStartAndDatePeriod": "Klicke auf das Startdatum und danach auf das Enddatum",
      "clickTwiceOnTheSameDay": "Klicke zwei Mal auf das selbe Datum",
      "deleteEntry": "Löscht den jeweiligen Stunden-Eintrag",
      "deleteReport": "Rapport entfernen",
      "deleteReportText_1": "Wenn du einen Rapport entfernen möchtest oder ein falsches Dokument hochgeladen hast, nimm zur Korrektur bitte Kontakt mit uns auf.",
      "editNote": "Bearbeiten einer hinterlegten Notiz",
      "editReceipt": "Bearbeiten und Löschen eines hinterlegten Belegs",
      "enterAbsences": "Absenzen erfassen",
      "enterAbsencesText": "Klicke im entsprechenden Einsatz in der rechten Spalte auf <span class='text-l-b'> Absenz erfassen </span> (auf dem Smartphone über das Punkte-Menü neben Tage <span class='text-l-b'> übermitteln </span>).",
      "enterAbsencesText_2": "Du kannst auch über den Plus-Button eines spezifischen Tages eine Absenz hinzufügen. Der Unterschied ist dabei nur, dass der aktuelle Tag als Zeitraum bereits ausgewählt ist.",
      "enterExpenses": "Spesen erfassen",
      "enterExpensesText": "Um Spesen erfassen zu können, klicke auf den entsprechenden Einsatz. Über das  <span class='text-l-b'> Plus-Symbol </span> kannst du dem gewünschten Tag einen Spesen-Eintrag hinzufügen. <br><br> Gib anschliessend für den Nutzungszweck der Spesen eine Beschreibung sowie den Betrag an. Einen Beleg kannst du über das Kamera-Icon erfassen.</p>",
      "enterHours": "Stunden erfassen",
      "enterHoursText_1": "Um Stunden zu erfassen, klicke links im Hauptmenü auf <span class='text-l-b'> Einsätze </span>. Wähle dann den gewünschten Einsatz unter <span class='text-l-b'>Aktive Einsätze</span> aus.",
      "enterHoursText_2": "Falls du beim gewünschten Einsatz den Button <span class='text-l-b'> Rapport hochladen </span> anstelle von <span class='text-l-b'> Stunden erfassen</span> siehst bedeutet dies, dass dein Arbeitgeber noch keinen Account besitzt, um Stunden digital freizugeben. Da die Arbeitsstunden immer bestätigt werden müssen, kannst du in diesem Fall unterschriebene Rapporte hochladen oder deinen Arbeitgeber bitten, einen Account zur digitalen Prüfung der Stunden anzufordern.",
      "enterHoursText_3": "Wenn Du dich in der Stundenerfassung befindest, kannst du jeweils den gewünschten Monat ausklappen, um dort Stunden zu erfassen. Du findest bei jedem Tag bereits einen Stundeneintrag und kannst über das <span class='text-l-b'> Plus-Symbol </span> weitere Einträge hinzufügen.",
      "enterWorkReports": "Arbeitsrapporte erfassen",
      "generalQuestions": "Allgemeine Fragen",
      "generalQuestionsText": "Bei Fragen wende dich an deinen universaljob Kontakt, welchen du im Hauptmenü unter <span class='text-l-b'>Kontakt</span> findest.",
      "hoursEntries": "Stunden-Einträge",
      "pointsMenuSmartphone": "Punkte-Menü Smartphone",
      "pointsMenuSmartphoneHint": "Hier wurde eine Notiz oder ein Beleg hinterlegt",
      "pointsMenuSmartphoneText": "Neben dem Stunden-Eintrag befinden sich auf Mobile weitere Bearbeitungs-Funktionen",
      "receipt": "Beleg",
      "selectDay": "Tag auswählen",
      "selectSingleDay": "Einzelner Tag auswählen",
      "sendReport": "Rapport senden",
      "sendReportText_1": "Klicke im entsprechenden Einsatz in der rechten Spalte auf <span class='text-l-b'>Rapport hochladen </span> (auf dem Smartphone über das Punkte-Menü neben <span class='text-l-b'> Tage übermitteln </span>).",
      "sendReportText_2": "Wenn du über einen Rapport verfügst, sollte dieser deine <span class='text-l-b'>Spesen </span> ebenfalls enthalten. Deshalb ist es bei Tagen, welche einen Rapport hinterlegt haben, nicht möglich weitere Einträge zu erfassen.",
      "sendReportText_3": "Wenn du einen Rapport hochlädst, wird dieser direkt an universaljob übermittelt.",
      "setPeriod": "Zeitraum festlegen",
      "submitData": "Übermitteln",
      "submitReport": "Rapport übermitteln",
      "submitReportText_1": "Wenn du alle Stunden, Absenzen und Spesen bis zu einem Zeitpunkt erfasst hast, kannst du die erfassten Daten über den Button <span class='text-l-b'>Übermitteln</span> zur Überprüfung an deinen Arbeitgeber senden.<br><br> Wähle dort einen Zeitpunkt aus, bis wann du die Daten übermitteln möchtest, und klicke anschliessend auf <span class='text-l-b'> Tage übermitteln.</span>",
      "submitReportText_2": "Wenn du die Daten einmal übermittelt hast, kannst du diese nicht mehr bearbeiten! Falls die Daten noch Fehler enthalten, muss dein Arbeitgeber die Stunden ablehnen, um diese wieder zur Bearbeitung freizugeben.",
      "technicalQuestions": "Technische Fragen",
      "technicalQuestionsText": "Bei technischen Schwierigkeiten mit hub.universaljob.ch oder Verbesserungsvorschlägen kannst du dich direkt an die Entwickler von hub.universaljob.ch wenden: <br><br>intemp AG <br> <a class='uj-underline' href='mailto:support1@intemp.ch'> support@intemp.ch </a> <br> +41 52 551 22 22",
      "uploadReport": "Rapport hochladen",
      "uploadReportText_1": "Über den Button <span class='text-l-b'>Rapport hochladen</span> kannst du den Zeitraum des Rapports auswählen und das entsprechende Dokument hinterlegen."
    }
  },
  "switchToFixedEmployment": "Wechsle zu fixe Anstellung oder passe die Werte an",
  "switchToFixedSalary": "Wechsle zu  Gehalt fix oder passe die Werte an",
  "switzerland": "Schweiz",
  "TARGET": "Target",
  "targetPlanTooltip": "Google und Socialmedia Advertising",
  "templates": "Vorlagen",
  "temporary": "Temporär",
  "testString": "Example test string",
  "tiles": "Kacheln",
  "timespan": "Zeitraum",
  "title": "Titel",
  "to": "bis",
  "toBeApproved": "Wartet auf Freigabe",
  "toBranchPage": "zur Standortseite",
  "toSearch": "Suchen",
  "total": "Total",
  "totalExpenses": "Total Spesen",
  "totalHours": "Total Stunden",
  "totalPriceExclTax": "Total exkl. MwSt",
  "totalRequired": "Die Arbeitsdauer an einem Tag kann nicht Null sein",
  "transferDays": "Tage übermitteln",
  "transferDaysUntil": "Tage übermitteln bis",
  "transferSuccess": "Tage übermittelt",
  "translatedPayrollLanguages": {
    "ALB": "Albanisch",
    "BUL": "Bulgarisch",
    "DES": "Deutsch",
    "ENU": "Englisch",
    "FRS": "Französisch",
    "HRV": "Kroatisch",
    "ITS": "Italienisch",
    "POL": "Polnisch",
    "POR": "Portugiesisch",
    "RUM": "Rumänisch",
    "SLO": "Slowakisch",
    "SPA": "Spanisch",
    "SRP": "Serbisch",
    "TUR": "Türkisch"
  },
  "tryAgain": "Erneut versuchen",
  "type": "Typ",
  "uj-post-to-win-first-price": "1. Preis: 500 CHF",
  "uj-post-to-win-second-price": "2. Preis: 300 CHF",
  "uj-post-to-win-third-price": "3. Preis: 200 CHF",
  "uj-post-to-win-title": "Gewinne jeden Monat einen der folgenden Preise!",
  "ujWebsite": "UJ Website",
  "ujWebsiteLinkCopied": "Link kopiert",
  "unpublishedChanges": "unveröffentlichte Änderungen",
  "unsavedChangesConfirmation": "Möchtest du diese Änderungen verwerfen oder speichern?",
  "unsavedChangesCustomerConfirmation": "Möchten Sie diese Änderungen verwerfen oder speichern?",
  "unsavedChangesCustomerTitle": "Korrektur vorgenommen",
  "unsavedChangesTitle": "Änderungen vorgenommen",
  "unschedule": "Sperrfrist aufheben",
  "updated": "Aktualisiert",
  "upload": "Hochladen",
  "uploadAbsenceSuccessful": "Die Absenz wurde erfasst",
  "uploadAttachment": "Anhang hochladen",
  "uploadedWrongRapport": "Falls du diesen Rapport ändern möchtest, schreibe uns bitte eine E-Mail.",
  "uploadError": "Upload abgebrochen, bitte erneut versuchen.",
  "uploadExpensesSuccessful": "Die Spesen wurden erfasst",
  "uploadFile": "Datei hochladen",
  "uploadFilesHint": "Bitte lade folgende Dokumente hoch, falls du diese nicht bereits an uns übermittelt hast:",
  "uploadMedicalCertificate": "Ärztliches Attest hochladen",
  "uploadPaymentReceipt": "Zahlungsbeleg hochladen",
  "uploadPicture": "Bild hochladen",
  "uploadReport": "Rapport hochladen",
  "uploadReportSuccessful": "Rapport erfolgreich hochgeladen",
  "userManagement": "Benutzer",
  "users": "Benutzer",
  "vacancies": "Vakanzen",
  "vacanciesSavedSuccessfully": "Vakanzen gespeichert",
  "vacancy": "Vakanz",
  "vacancyArchived": "Vakanz archiviert",
  "vacancyBranch": "Branche",
  "vacancyChangelogActions": {
    "activated": "hat <span class='text-m-b'>Vakanz</span> aktiviert",
    "added": "hat <span class='text-m-b'>{{entryName}}</span> hinzugefügt",
    "archived": "hat <span class='text-m-b'>Vakanz</span> archiviert",
    "changed": "hat <span class='text-m-b'>{{entryName}}</span> geändert",
    "created": "hat <span class='text-m-b'>{{entryName}}</span> erstellt",
    "deactivated": "hat <span class='text-m-b'>Vakanz</span> deaktiviert",
    "deleted": "hat <span class='text-m-b'>Vakanz</span> gelöscht",
    "published": "hat <span class='text-m-b'>Vakanz</span> veröffentlicht",
    "removed": "hat <span class='text-m-b'>{{entryName}}</span> entfernt",
    "restored": "hat <span class='text-m-b'>Vakanz</span> wiederhergestellt",
    "scheduled": "hat <span class='text-m-b'>Vakanz</span> geplant",
    "unscheduled": "hat <span class='text-m-b'>Vakanz</span> Sperrfrist aufgehoben",
    "generated": "hat einen neue <span class='text-m-b'>Vakanznummer</span> generiert",
    "changesRepublished": "hat <span class='text-m-b'>Änderungen</span> veröffentlicht"
  },
  "vacancyChangelogEntry": {
    "nestedEntry": "hat <span class='text-m-b'>{{entryName}}</span> bei <span class='text-m-b'>{{parentName}}</span> {{action}} ",
    "singleEntry": "hat <span class='text-m-b'>{{entryName}}</span> {{action}}"
  },
  "vacancyCreated": "Vakanz erstellt!",
  "vacancyDeleted": "Vakanz gelöscht!",
  "vacancyDuplicatedSuccessfully": "Vakanz erfolgreich dupliziert!",
  "vacancyHasBlockingPromotion": "Vakanz hat eine blockierende Promotion",
  "vacancyNr": "Vakanz-Nr",
  "vacancyNumber": "Vakanznummer",
  "vacancyNumberCopied": "Vakanznummer kopiert",
  "vacancyPublishedSuccessfully": "Vakanz erfolgreich veröffentlicht.",
  "vacancySavedSuccessfully": "Vakanz gespeichert",
  "vacancySheetAlreadyOpen": "Es wird bereits eine Vakanz bearbeitet",
  "vacancyStatusEnum": {
    "ARCHIVED": "Archiviert",
    "DELETED": "Gelöscht",
    "DRAFT": "Entwurf",
    "LIVE": "Aktiv"
  },
  "UniBaseXVacancyChannelConfigurationStatusEnum": {
    "PENDING": "Geplant",
    "RUNNING": "Aktiv",
    "COMPLETED": "Inaktiv",
    "EXPIRED": "Abgelaufen",
    "REQUESTED": "wartet auf Freigabe"
  },
  "vacancyTitle": "Vakanztitel",
  "vacation": "Urlaub",
  "vacationReferred": "Ferien bezogen",
  "verificationEmail": "Leider gibt es ein Problem. Bitte versuche es später noch einmal.",
  "verificationEmailResend": "Email wurde erneut versendet",
  "verificationMessage": "Bitte verifiziere deine Identität.",
  "verificationSms": "Wir haben dir eine SMS an <b>{{number}}</b> mit dem Code zur Aktvierung deines Kontos geschickt.",
  "verifyUser": "Bei der Prüfung deiner Daten ist ein Fehler aufgetreten.",
  "version": "Version",
  "versionRestoredSuccessfully": "Version erfolgreich wiederhergestellt",
  "versions": "Versionen",
  "view": "Ansehen",
  "viewCompany": "Firma ansehen",
  "viewVacancy": "Vakanz ansehen",
  "vitrineTV": "Schaufenster TV",
  "wageStatements": "Lohnausweise",
  "waitingUntil": "bis {{date}} ({{duration}} {{durationUnit}})",
  "welcome": "Herzlich willkommen",
  "welcomeAt": "Willkommen bei",
  "whatDoesTheCompanyDoPlaceHolder": "Beschreibe kurz und knapp das Unternehmen und was es zu einem attraktiven Arbeitgeber macht.",
  "whatDoiGetPlaceHolder": "Nenne weitere Benefits, die Ihr Unternehmen zu einem einzigartigen und attraktiven Arbeitsplatz machen.",
  "whatDoiNeedToBeSuccessfulPlaceHolder": "Beschreibe die Anforderungen an den Talente wie z.B. gewünschte Berufserfahrung, Qualifikationen und Fähigkeiten.",
  "whatIsMyFunctionPlaceHolder": "Beschreibe die genauen Aufgaben und Veranwortungsbereiche der Stelle, damit potenzielle Bewerber verstehen, was diese Position attraktiv macht.",
  "willBePublishedAfter": "wird nach Veröffentlichung der Vakanz aktiv geschaltet",
  "willNotBePublished": "wird nicht aufgeschaltet",
  "workArrangement": "Arbeitsmodell",
  "workAvailability": "Verfügbarkeit",
  "workDurationMustBeGreaterThanPauseDuration": "Die Arbeitsdauer muss grösser sein als die Pausendauer",
  "workload": "Pensum",
  "workloadCanNotBeZero": "Stellenprozent dark nicht <<0>> sein",
  "workloadFlexible": "Flexibels Pensum",
  "workloadFrom": "Pensum von",
  "workPlace": "Arbeitsort",
  "exactWorkPlace": "Genauer Arbeitsort",
  "workRegion": "Region",
  "write": "Schreiben",
  "writeEmail": "Mail schreiben",
  "xDaysToApprove": "{{amount}} freizugebende Tage",
  "xDaysToTransfer": "{{amount}} Tage zu übermitteln",
  "xVacanciesCouldNotBeUpdated": "{{count}} Vakanzen konnten nicht aktualisiert werden",
  "xVacanciesUpdated": "{{count}} Vakanzen aktualisiert",
  "year": "Jahr",
  "years": "Jahre",
  "yes": "Ja",
  "yourContactAtUniversalJob": "Ihr Kontakt bei universaljob",
  "zip": "PLZ",
  "zwischenverdienste": "Zwischenverdienste",
  "cannotReachServerAttemtingToReconnect": "Verbindung wird wiederhergestellt. Bitte warte einen Moment.",
  "genderAddition": "(m/w/d)",
  "hide-preview": "Vorschau schliessen",
  "editSavedFilter": "Gespeicherten Filter bearbeiten",
  "editTemporaryFilter": "Temporären Filter bearbeiten",
  "showSearchHistory": "Suchverlauf anzeigen",
  "searchInJobCompany": "In Unternehmen suchen",
  "searchInContact": "In Kontakt suchen",
  "promotion": "Promotion",
  "regionOrAproximateLocation": "Region oder ungefähre Ortsangabe",
  "months": "Monate",
  "seconds": "Sekunden",
  "validationVacancyTitleMaxLength": "Kürze dein Stellentitel wenn möglich",
  "validationVacancyTitleDoubledTermsProvidedByOtherFields": "Doppelter Inhalt: entferne %-Angaben oder Geschlechts-Angaben",
  "validationVacancyTitleBannedFillerWordsOrCharacters": "Verwende keine «Füllwörter» wie z.B: sofort, gesucht, dringend oder Sonderzeichen wie z.B: «!», «:», «*»",
  "validationVacancyTitleIsEmpty": "Bitte gib eine Stellenbezeichnung ein",
  "youAreOffline": "Du bist offline",
  "pageIsReloading": "Seite wird neu geladen",
  "useFromTo": "von - bis verwenden",
  "employmentContract": "Arbeitsvertrag",
  "employmentStart": "Arbeitsbeginn",
  "employmentEnd": "Arbeitsende",
  "startDateCanNotBeGreaterThanEndDate": "Startdatum kann nicht grösser als Enddatum sein",
  "stats": "Statistik",
  "trackedActionType": {
    "VACANCY_VIEWED": "Aufrufe",
    "VACANCY_APPLICATION": "Bewerbungen",
    "VACANCY_APPLICATION_FORM_OPENED": "Bewerbungsformular geöffnet",
    "VACANCY_TVSCREEN_QR_CODE_SCANNED": "TV-QR-Code gescannt"
  },
  "perChannel": "je Kanal",
  "applicationToViewRatio": "Bewerbungsquote",
  "tvScreen": "TV-Screen",
  "sinceRelativeDate": "seit {{date}} ({{relativeDate}})",
  "contractTerm": "Vertragslaufzeit",
  "whatMakesTheCompanyTitle": "Was zeichnet das Unternehmen aus?",
  "whatAreMyBenefits": "Was sind meine Vorteile?",
  "whatDoINeedTitle": "Was brauche ich, um erfolgreich zu sein?",
  "whatIsMyFunctionTitle": "Was ist meine Funktion?",
  "totalViewed": "Aufrufe",
  "totalApplications": "Bewerbungen",
  "prioBoost": "Prio Boost",
  "editSlots": "Slots bearbeiten",
  "prioBoostSlots": "Prio Boost Slots",
  "prioBoostDescription": "Du kannst bis zu {{max}} deiner Vakanzen auf den Job-Plattformen boosten 🚀.",
  "prioBoostSlotGuidance": "Aktuell hast du folgende Vakanzen priorisiert:",
  "onlyResponsibleCanChangeChannelConfig": "Nur Zuständige:r Mitarbeiter:in kann die Kanalkonfiguration ändern",
  "onlyUsersOfSameBranchCanExtendAddons": "Nur Mitarbeiter derselben Filiale können Addons verlängern.",
  "onlyUserOfSameBranchCanChannelConfig": "Nur Mitarbeiter derselben Filiale können die Kanalkonfiguration ändern.",
  "onlyUsersOfSameBranchCanAddAddons": "Nur Mitarbeiter derselben Filiale können Addons hinzufügen.",
  "maxSlotsPerUserReached": "Maximale Anzahl an Slots erreicht",
  "maxSlotsTotalReached": "Maximale Anzahl an Slots erreicht",
  "warningChannelsWithMaxRunningAndPendingSlotsPerUserWillBeDeactivated": "Achtung: Der aktive Prio-Boost wird automatisch deaktiviert, wenn der:die zuständige:r Mitarbeiter:in geändert wird.",
  "currentChannelStatus": "Promotion: Status",
  "currentChannelUuid": "Promotion: Kanal",
  "Options": "Optionen",
  "videoOptionTooltip": "Video",
  "searchVideoActiveWarning": "Das Startdatum wird durch unser Marketing gesetzt, sobald das Video Produktionsdatum bekannt ist.",
  "extendSearchAddon": "Letztes Add-on verlängern",
  "SearchPlanFeaturePoint": {
    "TalentExclusivity": "Talente-Exklusivität",
    "ActiveSearchers": "Aktiv Suchende",
    "PassiveSearchers": "Passiv Suchende",
    "RecruitingViaJobPlatforms": "Recruiting über Jobplattformen",
    "RecruitingViaSocialMedia": "Recruiting über Social Media"
  },
  "VIDEOFeaturePoint": {
    "coversTheBroadestSearchWithMax": "Deckt mit «MAX» die breiteste Suche ab",
    "strengthenEmployerAttractiveness": "Arbeitgeberattraktivität stärken",
    "completeVideoProductionWithProfessionals": "Komplette Videoproduktion mit Profis",
    "authenticAndProfessional": "Authentisch und professionell",
    "modernAppearanceAndMoreVisibility": "Moderner Auftritt und mehr Sichtbarkeit"
  },
  "openInNewTab": "In neuem Tab öffnen",
  "searchAddonWarningVacancyArchiveTitle": "Search vorzeitig beenden?",
  "searchAddonWarningVacancyArchiveMessage": "Das gebuchte Search wäre noch bis zum {{ until }} ({{ untilDays }} Tage) aktiv. Bei der vorzeitigen Beendung erfolgt keinerlei Rückerstattung. Möchtest du das wirklich beenden?",
  "pioBoostWarningVacancyArchiveMessage": "Die Vakanz wird aus dem Prio Boost Slot entfernt",
  "deactivationNotes": "Bemerkungen für die Beendung",
  "deactivationNotesPlaceholder": "Bemerkungen wie z.B Grund für die vorzeitige Beendung.",
  "channelRequested": "warten auf Freigabe - Wunschdatum: {{ from }}",
  "addonAcceptRequest": "Add-on aktivieren",
  "resume": "Lebenslauf",
  "journey": "Journey",
  "addChild": "Kind hinzufügen",
  "talent": "Talent",
  "talentCreated": "Talent erstellt",
  "personalData": "Persönliche Daten",
  "availability": "Verfügbarkeit",
  "preferences": "Präferenzen",
  "dataProtection": "Datenschutz",
  "yearOld": "Jahre alt",
  "add": "Hinzufügen",
  "yearOfBirth": "Jahrgang",
  "childAllowance": "Kinderzulage",
  "successfullyAdded": "Erfolgreich hinzugefügt",
  "successfullyRemoved": "Erfolgreich entfernt",
  "successfullyUpdated": "Erfolgreich aktualisiert",
  "hasNoChildAllowance": "keine Kinderzulage",
  "areYouSureYouWantToDeleteThisEntry": "Möchtest du wirklich den Eintrag löschen?",
  "validUntil": "Gültig bis",
  "talentStatusWillChangeAsSoonPermitPresent": "Sobald eine Bewilligung vorhanden ist, wechselt der Talente-Status zu «Aktiv».",
  "chooseCategory": "Kategorie wählen",
  "vehicleAvailable": "Fahrzeug vorhanden",
  "chooseReligiousDenomination": "Konfession wählen",
  "chooseMaritalStatus": "Zivilstand wählen",
  "telephone": "Telefon",
  "addNumber": "Nummer hinzufügen",
  "otherNumbers": "Weitere Nummern",
  "primaryPhoneUpdated": "Telefonnummer als primär festgelegt",
  "primaryEmailUpdated": "Email als primär festgelegt",
  "willBeYourPrimaryPhone": "wird als primäre Nummer festgelegt",
  "primary": "Primär",
  "otherEmails": "Weitere E-Mails",
  "addEmail": "E-Mail hinzufügen",
  "addresses": "Adressen",
  "addAddress": "Adresse hinzufügen",
  "children": "Kinder",
  "addUrl": "URL hinzufügen",
  "invalidUrl": "Die URL ist ungültig, prüfe deine Eingabe",
  "availabilityWillBeDisplayAtInterviewAndSynced": "Verfügbarkeiten werden auch im Interview dargestellt und synchronisiert",
  "availableForWorkAt": "Verfügbar ab",
  "selectDate": "Datum auswählen",
  "registeredWithRAV": "Beim RAV gemeldet",
  "nextService": "Nächster Dienst",
  "nextMilitaryServiceAt": "Nächster Militärdienst",
  "addRemark": "Bemerkung hinzufügen",
  "availabilityNote": "Verfügbarkeitsnotiz",
  "talentChangelogActions": {
    "added": "hat <span class='text-m-b'>{{entryName}}</span> hinzugefügt",
    "changed": "hat <span class='text-m-b'>{{entryName}}</span> geändert",
    "created": "hat <span class='text-m-b'>{{entryName}}</span> erstellt",
    "removed": "hat <span class='text-m-b'>{{entryName}}</span> entfernt",
    "deleted": "hat <span class='text-m-b'>Talent</span> gelöscht"
  },
  "talentChangelogEntry": {
    "nestedEntry": "hat <span class='text-m-b'>{{entryName}}</span> bei <span class='text-m-b'>{{parentName}}</span> {{action}} ",
    "singleEntry": "hat <span class='text-m-b'>{{entryName}}</span> {{action}}",
    "customEntry": {
      "CREATION": "hat <span class='text-m-b'>Talent</span> erstellt",
      "HAS_ACCEPTED_DATA_PRIVACY_POLICY": "Talent hat Datenschutz akzeptiert.",
      "HAS_SENT_DATA_PRIVACY_POLICY_EMAIL": "hat Datenschutz E-Mail an <span class='text-m-b'>{{sentTo}}</span> gesendet"
    }
  },
  "setTalentToBlackList": "Talent auf Blacklist setzen",
  "attentionOnlyUserWithPermissionUndoBlacklist": "Achtung nur Benutzer mit Berechtigung können den Blacklist-Status wieder aufheben.",
  "confirm": "Bestätigen",
  "preferencesWillBeDisplayAtInterviewAndSynced": "Präferenzen werden auch im Interview dargestellt und synchronisiert.",
  "targetJob": "Zielberuf",
  "addJob": "Beruf hinzufügen",
  "workModel": "Arbeitsmodell",
  "salaryExpectation": "Lohnvorstellung",
  "addLocation": "Ort hinzufügen",
  "enterRoute": "Strecke eingeben",
  "preferredLocation": "Gewünschter Ort",
  "radius": "Umkreis",
  "transportation": "Transportmittel",
  "commutingByPublicTransport": "ÖV",
  "commutingByCar": "Auto",
  "commutingByBicycle": "Fahrrad",
  "maxCommutingTimeMinutes": "Max. Pendelzeit",
  "addDuration": "Dauer eintragen",
  "talentLifeCycle": "Talent Lifecycle",
  "availability_until": "Verfügbar bis {{date}} ({{days}} Tage)",
  "data_deletion_notice": "Gemäss den Schweizerischen Datenschutzbestimmungen sind wir verpflichtet, nach Ablauf von 6 Monate alle personenbezogenen Daten von {{firstName}} {{lastName}} zu löschen.",
  "data_deletion_notice_notification_message": "{{firstName}} {{lastName}} wird 4 Wochen automatisiert vor Ablauf der Frist benachrichtigt und hat die Möglichkeit den Zeitraum zu verlängern.",
  "dataPrivacyPolicy": "Datenschutzbestimmungen",
  "no_email_for_privacy_policy": "Keine E-Mail vorhanden um Datenschutzbestimmungen zu verschicken.",
  "privacy_policy_not_accepted_will_be_deleted": "Werden die Datenschutzbestimmungen nicht bis zum <span class='text-m-b'> {{date}} ({{days}} Tage) </span> bestätigt, wird der Talent gelöscht.",
  "privacy_policy_accepted": "Hat Datenschutzbestimmungen am {{date}} akzeptiert.",
  "privacy_policy_not_accepted": "Hat Datenschutzbestimmungen noch nicht akzeptiert.",
  "send_privacy_policy_email": "Datenschutz E-Mail senden",
  "privacy_policy_email_sent_log": "<span class='text-m-b'>{{name}}</span> hat E-Mail an <span class='text-m-b'> {{email}} </span> versendet.",
  "alreadyAccepted": "Datenschutzbestimmungen bereits akzeptiert",
  "invalidToken": "Ungültiger Token",
  "privacyPolicyAccepted": "Datenschutzbestimmungen erfolgreich akzeptiert.",
  "dataPrivacyPolicyNotAccepted": "Datenschutz nicht akzeptiert",
  "talentNotFound": "Talent nicht gefunden",
  "matching": "Matching",
  "loadMore": "Mehr Laden",
  "onlyShowingXEntries": "Es werden nur die ersten {{count}} Einträge angezeigt",
  "source": "Quelle",
  "sources": "Quellen",
  "addLeadsAndSource": "Lead / Quelle hinzufügen",
  "recommendation": "Empfehlung",
  "addRecommendation": "Empfehlung hinzufügen",
  "internalNotes": "Interne Notizen",
  "addNotes": "Notizen hinzufügen",
  "talentNumber": "Talentnummer",
  "talentNumberCopied": "Talentnummer kopiert",
  "salutation": "Anrede",
  "hasVehicle": "Fahrzeug vorhanden",
  "phones": "Telefone",
  "emails": "E-Mails",
  "employedUntil": "Im Einsatz bis",
  "employedSince": "Im Einsatz seit",
  "zipCodeIsInvalid": "Die hinterlegte PLZ scheint ungültig oder ein Postfach zu sein. Bitte PLZ korrigieren.",
  "invalidPhoneNumber": "Ungültige Telefonnummer",
  "invalidEmail": "Ungültige E-Mail",
  "communicationLanguage": "Kommunikationssprache",
  "willBeUsedForAutomatedCommunicationLikeEmail": "Wird für automatisierte Kommunikation (z.B. E-Mail) verwendet",
  "speech": "Ansprache",
  "publishedWorkLocation": "Veröffentlichter Arbeitsort",
  "createdBy": "Erstellt von",
  "lastModifiedBy": "Zuletzt geändert von",
  "searchInCompany": "In Firma suchen",
  "newDocumentsAvailable": "{{count}} neue Dokumente verfügbar",
  "thereAreNewDocumentsAvailableForTalent": "Es gibt {{count}} neue Dokumente zu diesem Talent",
  "activeDocuments": "Aktive Dokumente",
  "generatingPages": "Seiten werden für {{file}} generiert",
  "savingFile": "Datei wird gespeichert für {{file}}",
  "savingPages": "Seiten werden gespeichert für {{file}}",
  "archivedDocuments": "Archivierte Dokumente",
  "originalFiles": "Original Dateien",
  "showDocuments": "Dokumente ansehen",
  "actives": "Aktive",
  "unchecked": "Ungeprüfte",
  "originals": "Originale",
  "loadPdf": "Als PDF anzeigen",
  "uploadDocument": "Dokument hochladen",
  "uploadingFile": "Datei {{file}} wird hochgeladen",
  "error": "Fehler",
  "downloading": "Am herunterladen",
  "favoritesWillBeUsedByDefaultDuringRecommendation": "Favoriten werden bei der Empfehlung standardmässig übernommen",
  "moveUp": "Nach oben",
  "moveDown": "Nach unten",
  "unfavorite": "Favorit entfernen",
  "makeFavorite": "Favorit hinzufügen",
  "download": "Herunterladen",
  "archive": "Archivieren",
  "removeDocument": "Dokument entfernen",
  "areYouSureYouWantToRemoveDocument": "Möchtest du das Dokument wirklich dauerhaft entfernen?",
  "page": "Seite",
  "noTalentsFound": "Keine Talente gefunden",
  "searchMatches": "Matches Suchen",
  "kanban": "Kanban",
  "table": "Tabelle",
  "unread": "Ungelesen",
  "noMatchesFoundInColumn": {
    "ENTRY": "Keine Matches im Eingang",
    "PREPARATION": "Keine Matches in Vorbereitung",
    "RECOMMENDATION": "Keine Matches in Empfehlung",
    "EXAMINATION": "Keine Matches in Prüfung",
    "CONCLUSION": "Keine Matches in Abschluss"
  },
  "archived": "Archiviert",
  "manually": "Manuell",
  "aiRating": "AI Bewertung",
  "my": "Meine",
  "searchInTalent": "In Talent suchen",
  "searchInVacancy": "In Vakanz suchen",
  "matchDetails": "Match Details",
  "talentEmployedFrom": "ab {{date}} ({{duration}} {{durationUnit}})",
  "talentEmployedUntil": "bis {{date}} ({{duration}} {{durationUnit}})",
  "until": "bis",
  "asOf": "ab",
  "matchCompany": "Unternehmen",
  "hubBotRating": "HubBot Bewertung",
  "internalRemark": "Interne Bemerkung",
  "addedToFavorites": "Zu Favoriten hinzugefügt",
  "removedFromFavorites": "Aus Favoriten entfernt",
  "movedToActiveDocuments": "Zu aktive Dokumente verschoben",
  "files": "Dateien",
  "pages": "Seiten",
  "ujInternalDescription": "Interne Stellenangebote für die Suche nach neuen Mitarbeitenden bei universaljob.",
  "willBeYourPrimaryEmail": "wird als primäre E-Mail festgelegt",
  "thisDocumentHasAlreadyBeenUploaded": "Dieses Dokument wurde bereits hochgeladen",
  "emailAlreadyExists": "E-Mail existiert bereits",
  "staffRoles": "Mitarbeiterrollen",
  "markAsUnread": "Ungelesen markieren",
  "successfully": {
    "archived": "Archiviert",
    "markedAsUnread": "Als ungelesen markiert",
    "restored": "Wiederhergestellt"
  },
  "progress": "Fortschritt",
  "movedToProcessStep": "Nach {{processStep}} verschoben",
  "rating": "Bewertung",
  "noRating": "Keine Bewertung",
  "archiveMatch": "Match archivieren",
  "phoneAlreadyExists": "Telefonnummer existiert bereits",
  "imgPreviewIsGenerating": "Vorschau wird erstellt",
  "blackListedReason": "Grund für Blacklist",
  "employmentCompany": "Einsatzfirma",
  "noLeadsOrSources": "Keine Leads / Quellen",
  "targetJobTitle": "Zielberuf",
  "showWorkloadInPublicTitle": "Pensum im Stellentitel anzeigen",
  "sendCancelation": "Absage senden",
  "youFormal": "Sie",
  "youInformal": "Du",
  "formalSalutation": {
    "MALE": "Sehr geehrter Herr {{lastName}}",
    "FEMALE": "Sehr geehrte Frau {{lastName}}",
    "OTHER": "Guten Tag {{firstName}} {{lastName}}"
  },
  "informalSalutation": {
    "MALE": "Lieber {{firstName}}",
    "FEMALE": "Liebe {{firstName}}",
    "OTHER": "Hallo {{firstName}}"
  },
  "jobApplicantCancellationBlind": {
    "subject": "Absage Bewerbung",
    "paragraphsFormal": [
      "Vielen Dank für Ihre Bewerbung und Ihr Interesse an unserer Dienstleistung. Wir haben Ihre Unterlagen sorgfältig geprüft, haben jedoch derzeit leider kein passendes Angebot für Sie.",
      "Gerne behalten wir Ihre Unterlagen in Evidenz und melden uns bei Ihnen, sobald sich eine geeignete Stelle ergibt",
      "Bis dahin wünschen wir Ihnen viel Erfolg und freuen uns, wenn sich bald eine passende Möglichkeit ergibt!"
    ],
    "paragraphsInformal": [
      "Vielen Dank für deine Bewerbung und dein Interesse an unserer Dienstleistung. Wir haben deine Unterlagen sorgfältig geprüft, aber derzeit leider kein passendes Angebot für dich.",
      "Gerne behalten wir deine Unterlagen pendent und melden uns, sobald sich eine passende Stelle ergibt.",
      "Bis dahin wünschen wir dir viel Erfolg und freuen uns, wenn sich bald eine passende Möglichkeit ergibt!"
    ]
  },
  "jobApplicantCancellation": {
    "subject": "Absage Bewerbung «{{vacancyTitle}}»",
    "paragraphsInformal": {
      "WE_CANCELED": [
        "Vielen Dank für deine Bewerbung auf die Stelle als {{vacancyTitle}}. Wir haben uns deine Unterlagen aufmerksam angeschaut und schätzen dein Interesse an der Position sehr. Leider müssen wir dir mitteilen, dass wir uns für diese Position anders entschieden haben.",
        "Wir behalten deine Unterlagen gerne bei uns pendent und melden uns, sobald wir eine passende Stelle haben.",
        "Bis dahin wünschen wir dir viel Erfolg und freuen uns, wenn sich bald eine passende Möglichkeit ergibt!"
      ],
      "CLIENT_CANCELLED": [
        "Auf unsere Empfehlung für die Anstellung als {{vacancyTitle}} bei {{companyLegalName}} haben wir von unserem Kunden ein Feedback erhalten. Nach sorgfältiger Prüfung hat er sich leider dazu entschieden, die Position anderweitig zu besetzen.",
        "Wir melden uns gerne bei Ihnen, sobald wir eine neue passende Stelle oder weiteres Feedback für Sie haben.",
        "Bis dahin wünschen wir Ihnen viel Erfolg und freuen uns, wenn sich bald eine passende Gelegenheit ergibt!"
      ]
    },
    "paragraphsFormal": {
      "WE_CANCELED": [
        "Vielen Dank für Ihre Bewerbung auf die Stelle als {{vacancyTitle}}. Wir haben Ihre Unterlagen sorgfältig geprüft und schätzen Ihr Interesse an der Position sehr. Leider müssen wir Ihnen mitteilen, dass wir uns für diese Position anderweitig entschieden haben.",
        "Wir behalten deine Unterlagen gerne bei uns pendent und melden uns, sobald wir eine passende Stelle haben",
        "Bis dahin wünschen wir dir viel Erfolg und freuen uns, wenn sich bald eine passende Möglichkeit ergibt!"
      ],
      "CLIENT_CANCELLED": [
        "Auf unsere Empfehlung für die Anstellung als {{vacancyTitle}} bei {{companyLegalName}} haben wir von unserem Kunden ein Feedback erhalten. Nach der sorgfältigen Prüfung, hat er sich leider dazu entscheiden die Position anderweitig zu besetzen.",
        "Wir melden uns gerne bei dir, sobald wir eine neue passende Stelle oder weiteres Feedback für dich haben.",
        "Bis dahin wünschen wir dir viel Erfolg und freuen uns, wenn sich bald eine passende Möglichkeit ergibt!"
      ]
    }
  },
  "updatedAt": "aktualisiert am",
  "time": {
    "minuteAgo": "vor 1 Minute",
    "minutesAgo": "vor {{count}} Minuten",
    "hourAgo": "vor 1 Stunde",
    "hoursAgo": "vor {{count}} Stunden",
    "dayAgo": "vor 1 Tag",
    "daysAgo": "vor {{count}} Tagen",
    "monthAgo": "vor 1 Monat",
    "monthsAgo": "vor {{count}} Monaten",
    "yearAgo": "vor 1 Jahr",
    "yearsAgo": "vor {{count}} Jahren"
  },
  "vacancyStatus": "Vakanz Status"
}
