<unijob-sheet
  [id]="sheetId"
  size="l"
  (sheetScrolled)="service.triggerAnchorScroll()"
  (sheetClosed)="handleSheetClosed(sheetId)"
  contentFlex>
  <ng-container
    slot="overlay"
    *ngIf="!isLgDown() && openTalentPreview()">
    <ng-container *ngTemplateOutlet="talentPreviewOverlay"></ng-container>
  </ng-container>
  <ng-container slot="nav">
    @if (openTalentPreview()) {
      <button
        ui-library-button-solid
        iconOnly="preview_zoom"
        corner="round"
        (click)="this.closePreview()"></button>
    } @else {
      <button
        ui-library-button-outline
        iconOnly="preview_zoom"
        corner="round"
        (click)="this.openPreview()"></button>
    }
  </ng-container>
  <ng-container slot="header">
    <app-talent-sheet-header
      *ngIf="service.talent$ | async as talent; else loading"
      [talent]="talent"></app-talent-sheet-header>
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="service.talent$ | async as talent; else loading">
      <ng-container *ngIf="activeTab() === tabEnum.PROFILE">
        <div
          class="lg:uj-max-w-[550px] uj-grow uj-h-fit"
          #scrollContainerChild>
          <ui-library-accordion
            id="personalDataAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'personalData' | i18next"
            [open]="openAccordions().personalDataAccordion"
            (openChange)="setAccordionOpen('personalDataAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-personal-data />
          </ui-library-accordion>
          <ui-library-accordion
            id="contactAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'contact' | i18next"
            [open]="openAccordions().contactAccordion"
            (openChange)="setAccordionOpen('contactAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-contact />
          </ui-library-accordion>
          <ui-library-accordion
            id="availabilityAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'availability' | i18next"
            [open]="openAccordions().availabilityAccordion"
            (openChange)="setAccordionOpen('availabilityAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-availability />
          </ui-library-accordion>
          <ng-container>
            <ui-library-accordion
              id="preferencesAccordion"
              class="uj-block uj-w-full uj-mb-g"
              [label]="'preferences' | i18next"
              [open]="openAccordions().preferencesAccordion"
              (openChange)="setAccordionOpen('preferencesAccordion', $event)"
              [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
              <app-talent-preferences />
            </ui-library-accordion>
          </ng-container>
          <ui-library-accordion
            id="dataProtectionAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'dataProtection' | i18next"
            [open]="openAccordions().dataProtectionAccordion"
            (openChange)="setAccordionOpen('dataProtectionAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-data-protection />
          </ui-library-accordion>
          <ui-library-accordion
            id="internAccordion"
            class="uj-block uj-w-full uj-mb-g"
            [label]="'intern' | i18next"
            [open]="openAccordions().internAccordion"
            (openChange)="setAccordionOpen('internAccordion', $event)"
            [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
            <app-talent-intern />
          </ui-library-accordion>
        </div>
        <aside
          *ngIf="activeTab() === tabEnum.PROFILE"
          class="uj-sticky uj-top-0 uj-hidden uj-w-0 lg:uj-block uj-ml-f uj-grow uj-max-w-[188px]">
          <app-anchor-navigation
            [thresholdNumber]="158"
            [scrollContainer]="scrollContainerChild.parentElement"
            [thresholdMultiplier]="0"
            [anchorList]="
              activeTab() === tabEnum.PROFILE
                ? [
                    {
                      name: 'personalData' | i18next,
                      id: 'personalDataAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },

                    {
                      name: 'contact' | i18next,
                      id: 'contactAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'availability' | i18next,
                      id: 'availabilityAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'preferences' | i18next,
                      id: 'preferencesAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'dataProtection' | i18next,
                      id: 'dataProtectionAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                    {
                      name: 'intern' | i18next,
                      id: 'internAccordion',
                      beforeScrollFunction: openAccordionIfCollapsed,
                    },
                  ]
                : []
            ">
          </app-anchor-navigation>
        </aside>
      </ng-container>
      <ng-container *ngIf="activeTab() === tabEnum.DOCUMENTS">
        <app-talent-documents [talent]="talent" />
      </ng-container>
      <div
        class="uj-absolute uj-flex uj-justify-center uj-gap-c sm:uj-mt-0 uj-right-e uj-left-e sm:uj-left-auto uj-bottom-e">
        <button
          class="uj-w-full md:uj-w-auto"
          ui-library-button-solid
          [contextMenu]="contextMenuTemplate"
          [contextMenuTemplateVariables]="{ talent: talent }"
          contextMenuBgColor="bg-pink"
          horizontalAlignment="center"
          iconRight="menu_dots_vertical">
          {{ "actions" | i18next }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</unijob-sheet>

<ng-template #loading>
  <div><app-loading-spinner></app-loading-spinner></div>
</ng-template>

<ng-template
  #contextMenuTemplate
  let-talent="talent">
  <div>
    <app-talent-action-menu
      [talent]="talent"
      [isInSheet]="true"
      [buttonStyle]="'solid'" />
  </div>
</ng-template>

@if (openOverlayOnSmallScreen()) {
  <app-overlay-wrapper
    [open]="openOverlayOnSmallScreen()"
    (close)="closePreview()">
    <ng-container
      *ngIf="service.talent$ | async as talent"
      content>
      <div class="uj-flex uj-justify-center uj-w-full uj-h-full">
        <app-talent-document-preview
          class="uj-relative uj-flex uj-items-stretch uj-overflow-hidden uj-grow uj-pb-g"
          [talent]="talent"
          [showHeader]="false"
          [mode]="openSheetOverlayAction()"
          [uuid]="openSheetOverlayUuid()" />
      </div>
    </ng-container>
  </app-overlay-wrapper>
}

<ng-template #talentPreviewOverlay>
  <div
    class="uj-flex uj-justify-center uj-w-full uj-h-full"
    *ngIf="service.talent$ | async as talent">
    @if (openTalentPreview()) {
      <app-talent-document-preview
        class="uj-relative uj-flex uj-items-stretch uj-overflow-hidden uj-grow"
        [talent]="talent"
        [showHeader]="false"
        [mode]="openSheetOverlayAction()"
        [uuid]="openSheetOverlayUuid()" />
    }
  </div>
</ng-template>
