<div
  class="lg:uj-max-w-[550px] uj-grow uj-h-fit"
  #scrollContainerChildDocuments>
  <div class="uj-flex uj-flex-col uj-gap-g uj-w-full content-wrapper">
    <div *ngIf="unreadPages().length > 0">
      <ui-library-infobox
        [closable]="true"
        [label]="
          'newDocumentsAvailable' | i18next: { count: unreadPages().length }
        "
        type="new">
        <div
          class="uj-flex uj-flex-col uj-items-start uj-gap-b uj-self-stretch">
          {{
            "thereAreNewDocumentsAvailableForTalent"
              | i18next: { count: unreadPages().length }
          }}
          <button
            iconLeft="preview_zoom"
            (click)="openFirstUnreadPage()"
            ui-library-button-link>
            {{ "showDocuments" | i18next }}
          </button>
        </div>
      </ui-library-infobox>
    </div>
    <ui-library-accordion
      [label]="'activeDocuments' | i18next"
      [count]="activePages().length"
      type="left-outside"
      [indicator]="unreadPages().length > 0"
      [open]="openAccordions().activePages"
      (openChange)="setAccordionOpen('activePages', $event)"
      class="active-pages"
      id="activePages"
      open>
      <div class="uj-flex uj-flex-col uj-gap-b">
        <div
          cdkDropList
          #activePagesList="cdkDropList"
          (cdkDropListDropped)="activePagesListDrop($event)"
          [cdkDropListConnectedTo]="[archivedPagesList]"
          class="card-list uj-flex uj-flex-col uj-gap-b">
          @for (page of activePages(); track page.uuid; let i = $index) {
            <app-talent-document-card
              [attr.data-selected-count]="this.selectedPageUuids().length"
              [cdkDragData]="page"
              cdkDrag
              cdkDragLockAxis="y"
              [page]="page"
              [pageNumber]="i + 1"
              [talent]="talent()"
              (toggleFavorite)="toggleFavorite(page, $event)"
              (archive)="archivePage(page)"
              (moveUp)="movePageUp(page)"
              (moveDown)="movePageDown(page)"
              (titleChange)="titleChanged($event, page)"
              (click)="activePageClicked($event, page)"
              [selected]="selectedPageUuids().includes(page.uuid)"
              (preview)="previewPage(page)"
              [active]="openSheetOverlayUuid() === page.uuid"
              (markUnread)="markUnread(page)" />
          }
        </div>
        <button
          ui-library-button-outline
          fullWidth
          iconLeft="upload_arrow"
          (click)="fileInput.click()"
          (drop)="onUploadDrop($event)"
          (dragover)="dragover($event)">
          {{ "uploadDocument" | i18next }}
        </button>
        <ng-container *ngFor="let file of Object.values(uploads())">
          <ui-library-progress-bar
            *ngIf="file.uploadPhase === 'uploadingFile'"
            [canCancel]="true"
            (cancel)="cancelUpload(file.fileName)"
            [progress]="(file.uploadProgress || 0) / 2"
            [hint]="
              'uploadingFile' | i18next: { file: file.fileName }
            "></ui-library-progress-bar>
          <ui-library-progress-bar
            *ngIf="file.uploadPhase === 'generatingPages'"
            [progress]="(file.generatingPagesProgress ?? 0) + 50"
            [hint]="
              'generatingPages' | i18next: { file: file.fileName }
            "></ui-library-progress-bar>
          <ui-library-progress-bar
            *ngIf="file.uploadPhase === 'savingFile'"
            [progress]="75"
            [hint]="
              'savingFile' | i18next: { file: file.fileName }
            "></ui-library-progress-bar>
          <ui-library-progress-bar
            *ngIf="file.uploadPhase === 'savingPages'"
            [progress]="90"
            [hint]="
              'savingPages' | i18next: { file: file.fileName }
            "></ui-library-progress-bar>
          <ui-library-infobox
            *ngIf="file.errorMessage"
            [label]="'error' | i18next"
            type="negative"
            [closable]="true"
            (remove)="resetUpload(file.fileName)">
            {{ file.errorMessage }}
          </ui-library-infobox>
        </ng-container>
      </div>
      <input
        type="file"
        #fileInput
        multiple
        (change)="onFileSelected($event)"
        hidden />
    </ui-library-accordion>

    <ui-library-accordion
      [label]="'archivedDocuments' | i18next"
      [count]="archivedPages().length"
      type="left-outside"
      id="archivedPages"
      class="inactive-pages"
      [open]="openAccordions().archivedPages"
      (openChange)="setAccordionOpen('archivedPages', $event)">
      <div
        class="card-list uj-flex uj-flex-col uj-gap-b"
        cdkDropList
        #archivedPagesList="cdkDropList"
        [cdkDropListConnectedTo]="[activePagesList]"
        (cdkDropListDropped)="archivedPagesListDrop($event)">
        <app-talent-document-card
          *ngFor="let page of archivedPages()"
          [cdkDragData]="page"
          cdkDrag
          cdkDragLockAxis="y"
          [page]="page"
          [talent]="talent()"
          (archive)="archivePage(page)"
          (makeActive)="makePageActive(page)"
          (titleChange)="titleChanged($event, page)"
          (click)="inactivePageClicked($event, page)"
          [selected]="selectedPageUuids().includes(page.uuid)"
          (preview)="previewPage(page)"
          [active]="openSheetOverlayUuid() === page.uuid" />
      </div>
    </ui-library-accordion>

    <ui-library-accordion
      [label]="'originalFiles' | i18next"
      [count]="talent().files.length"
      type="left-outside"
      [open]="openAccordions().files"
      (openChange)="setAccordionOpen('files', $event)"
      id="files">
      <div class="uj-flex uj-flex-col uj-gap-b">
        <app-talent-document-card
          *ngFor="let file of talent().files"
          [file]="file"
          [talent]="talent()" />
      </div>
    </ui-library-accordion>
  </div>
</div>
<aside
  class="uj-sticky uj-top-0 uj-hidden uj-w-0 lg:uj-block uj-ml-f uj-grow uj-max-w-[188px]">
  <app-anchor-navigation
    class="uj-w-full"
    [thresholdNumber]="158"
    [scrollContainer]="scrollContainerChildDocuments.parentElement"
    [thresholdMultiplier]="0"
    [anchorList]="[
      {
        name: 'actives' | i18next,
        id: 'activePages',
        beforeScrollFunction: openAccordionIfCollapsed,
      },
      {
        name: 'archived' | i18next,
        id: 'archivedPages',
        beforeScrollFunction: openAccordionIfCollapsed,
      },
      {
        name: 'originals' | i18next,
        id: 'files',
        beforeScrollFunction: openAccordionIfCollapsed,
      },
    ]">
  </app-anchor-navigation>
</aside>
