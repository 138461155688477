import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { IconModule, randomId, TooltipModule } from '@intemp/unijob-ui';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TalentFormEditService } from '../../services/talentFormEdit.service';
import {
  ArrayActionEnum,
  JobApplicationSourcePlatformEnum,
  TalentSourceTypeEnum,
} from '../../../../../graphql/generated';
import { SourcesFormGroup } from '../../talent-sheet.types';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { TalentSourceEntryComponent } from './talent-sources-entry/talent-source-entry.component';
import { ButtonOutlineComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-sources',
  templateUrl: './talent-sources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconModule,
    I18NextModule,
    SharedDefaultModule,
    TooltipModule,
    TalentSourceEntryComponent,
    ButtonOutlineComponent,
  ],
})
export class TalentSourcesComponent {
  formService = inject(TalentFormEditService);
  sourcesIsInEditMode = signal(false);

  addSource() {
    const newSource = new FormGroup<SourcesFormGroup>({
      uuid: new FormControl<string>(randomId()),
      type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.ADDED),
      platform: new FormControl<JobApplicationSourcePlatformEnum | null>(null),
      contactAt: new FormControl<Date[] | null>(null, [Validators.required]),
      sourceType: new FormControl<TalentSourceTypeEnum | null>(null),
      isNewEntry: new FormControl<boolean>(true),
    });
    this.formService.sources.push(newSource);
    this.sourcesIsInEditMode.set(true);
  }

  sortedSources() {
    const platformOrder = [
      JobApplicationSourcePlatformEnum.UNIVERSALJOB,
      JobApplicationSourcePlatformEnum.JOBS_CH,
      JobApplicationSourcePlatformEnum.LINKED_IN,
      JobApplicationSourcePlatformEnum.JOB_SHOT,
    ];

    return this.formService.sources.controls.sort((a, b) => {
      const platformA = a.value.platform;
      const platformB = b.value.platform;
      if (!platformA || !platformB) return 0;

      const platformAIndex = platformOrder.indexOf(platformA);
      const platformBIndex = platformOrder.indexOf(platformB);

      if (platformAIndex < platformBIndex) {
        return -1;
      }

      if (platformAIndex > platformBIndex) {
        return 1;
      }

      return 0;
    });
  }

  toggleSourcesIsInEditMode($event: boolean) {
    this.sourcesIsInEditMode.set($event);
  }
}
