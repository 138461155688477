<div>
  <label class="uj-flex uj-items-center uj-gap-b text-m-b uj-mb-b uj-relative"
    >{{ "sources" | i18next | firstUppercase }}
  </label>
  <div class="uj-flex uj-flex-col uj-gap-b">
    @for (formGroup of sortedSources(); track formGroup.value.uuid) {
      <app-talent-sources-entry
        (isInEditMode)="toggleSourcesIsInEditMode($event)"
        [source]="formGroup"></app-talent-sources-entry>
    } @empty {
      <span class="text-m">–</span>
    }
    <!--    <button-->
    <!--      *ngIf="!sourcesIsInEditMode()"-->
    <!--      class="!uj-w-full uj-mt-c"-->
    <!--      ui-library-button-outline-->
    <!--      iconLeft="plus"-->
    <!--      color="dark"-->
    <!--      (click)="addSource()">-->
    <!--      {{ "addLeadsAndSource" | i18next | firstUppercase }}-->
    <!--    </button>-->
  </div>
</div>
