<div class="uj-flex uj-flex-col uj-gap-f">
  <div class="input-wrap !uj-mb-0 !uj-flex uj-flex-col uj-gap-c">
    <div class="uj-flex uj-flex-col">
      <label class="input-label uj-relative uj-flex uj-items-center"
        >{{ "employment" | i18next | firstUppercase }}
      </label>
      <div
        class="uj-flex uj-flex-col sm:uj-flex-row sm:uj-justify-evenly uj-gap-c">
        <ui-library-simple-select
          class="uj-flex-1"
          [options]="employmentTypeOptions"
          size="M"
          [placeholderText]="'chooseEmployment' | i18next"
          design="outline"
          backgroundColor="white"
          [formControl]="employmentType">
        </ui-library-simple-select>
        <div
          class="uj-flex uj-flex-1"
          [ngClass]="{
            'uj-outline uj-outline-1 uj-outline-offset-2 uj-outline-ui-negative uj-rounded-sm uj-mt-a':
              hintErrorMessages.workloadPercentage,
          }">
          <ng-container *ngIf="!!workloadFlexible.value">
            <input
              ui-library-text-input
              positionInGroup="start-seamless"
              focusTracker="workloadPercentageMin"
              [placeholder]="'min' | i18next"
              [formControl]="workloadPercentageMin"
              (input)="clampPercentageValue('workloadPercentageMin', $event)"
              type="number" />
            <div
              class="uj-px-d uj-py-c uj-border uj-border-x-0 uj-border-border uj-cursor-default">
              —
            </div>
            <input
              ui-library-text-input
              positionInGroup="center-seamless"
              focusTracker="workloadPercentageMax"
              [placeholder]="'max' | i18next"
              [formControl]="workloadPercentageMax"
              (input)="clampPercentageValue('workloadPercentageMax', $event)"
              type="number" />
          </ng-container>
          <input
            *ngIf="!workloadFlexible.value"
            ui-library-text-input
            positionInGroup="start"
            focusTracker="workloadPercentage"
            [placeholder]="'enterWorkloadPercentage' | i18next"
            [formControl]="workloadPercentage"
            (input)="clampPercentageValue('workloadPercentage', $event)"
            type="number" />
          <div
            [class.uj-text-text-grey]="formIsDisabled"
            [class.uj-bg-surface-grape]="formIsDisabled"
            class="uj-px-d uj-py-c uj-border uj-border-l-0 uj-rounded-tr uj-rounded-r-b uj-border-border uj-text-text-54 uj-flex uj-items-center">
            %
          </div>
        </div>
      </div>
    </div>
    <div
      class="uj-flex uj-text-ui-negative uj-items-center uj-gap-a"
      *ngIf="hintErrorMessages.workloadPercentage">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <span class="text-s">{{
        hintErrorMessages.workloadPercentage | i18next
      }}</span>
    </div>
    <unijob-switch
      focusTracker="workloadFlexible"
      [formControl]="workloadFlexible"
      [label]="'useFromTo' | i18next"
      round></unijob-switch>
    <div
      class="uj-flex uj-text-ui-negative uj-items-center uj-gap-a"
      *ngIf="hintErrorMessages.employmentContract">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <span class="text-s">{{
        hintErrorMessages.employmentContract | i18next
      }}</span>
    </div>
  </div>
</div>
