import { CdkDragHandle } from '@angular/cdk/drag-drop';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { ContextMenuModule, InfoBoxModule } from '@intemp/unijob-ui';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  CardComponent,
  DividerComponent,
  DotComponent,
  IconComponent,
  ProgressBarComponent,
  TooltipDirective,
} from '@intemp/unijob-ui2';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { catchError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { TalentFragment } from '../../../../../graphql/generated';
import { downloadBlobAsFile } from '../../../../helpers/functions/downloadBlobAsFile';
import { GlobalSheetsService } from '../../../global-sheets/global-sheets.service';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { ImgLoaderDirective } from '../../../../components/img-loader/img-loader.directive';

@Component({
  standalone: true,
  selector: 'app-talent-document-card',
  templateUrl: './talent-document-card.component.html',
  styleUrls: ['./talent-document-card.component.scss'],
  imports: [
    I18NextModule,
    SharedDefaultModule,
    CardComponent,
    IconComponent,
    ButtonTextComponent,
    ProgressBarComponent,
    InfoBoxModule,
    CdkDragHandle,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    ContextMenuModule,
    DividerComponent,
    TooltipDirective,
    DotComponent,
    ImgLoaderDirective,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentDocumentCardComponent implements OnDestroy {
  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  ngOnDestroy(): void {
    // Clean up the event listener if it's still attached
    if (this.clickOutsideListener) {
      this.clickOutsideListener();
    }
  }

  page = input<TalentFragment['pages'][number]>();
  file = input<TalentFragment['files'][number]>();
  pageOrFile = computed(() => {
    const pageOrFile = this.page() || this.file();
    if (!pageOrFile) {
      throw new Error('missing file or page input in talent-document card');
    }
    return pageOrFile;
  });

  @ViewChild('titleElement') titleElement!: ElementRef;

  @Input({ required: true }) talent!: TalentFragment;
  @Input({ required: false }) pageNumber?: number;
  @Input({ required: false }) selected?: boolean;
  @Input() active?: boolean;
  @Output() toggleFavorite = new EventEmitter<boolean>();
  @Output() archive = new EventEmitter<void>();
  @Output() markUnread = new EventEmitter<void>();
  @Output() moveUp = new EventEmitter<void>();
  @Output() moveDown = new EventEmitter<void>();
  @Output() makeActive = new EventEmitter<void>();
  @Output() titleChange = new EventEmitter<string>();
  @Output() preview = new EventEmitter<void>();

  pageInView = computed(() => {
    return (
      this.globalSheetsService.documentsPreviewActivePage() ===
      this.page()?.uuid
    );
  });

  i18next = inject(I18NextPipe);
  editingTitle = signal<false | string>(false);
  private clickOutsideListener: (() => void) | null = null;

  public toggleFavoriteClicked(event: MouseEvent) {
    const page = this.page();
    if (page) {
      this.toggleFavorite.emit(!page.favorite);
      event.stopPropagation();
    }
  }

  public archiveClicked(event: MouseEvent) {
    this.archive.emit();
    event.stopPropagation();
  }

  public markUnreadClicked(event: MouseEvent) {
    this.markUnread.emit();
    event.stopPropagation();
  }

  public moveUpClicked(event: MouseEvent) {
    this.moveUp.emit();
    event.stopPropagation();
  }

  public moveDownClicked(event: MouseEvent) {
    this.moveDown.emit();
    event.stopPropagation();
  }

  public makeActiveClicked(event: MouseEvent) {
    this.makeActive.emit();
    event.stopPropagation();
  }

  public previewClicked(event: MouseEvent) {
    this.preview.emit();
    event.stopPropagation();
  }

  public editTitleClicked(event: MouseEvent) {
    const pageOrFile = this.pageOrFile();
    this.editingTitle.update((value) => pageOrFile.title);

    // Focus and select text
    setTimeout(() => {
      const element = this.titleElement.nativeElement as HTMLElement;

      if (element && element.isContentEditable) {
        element.focus();
        const selection = window.getSelection();

        // TODO: currently does not work consistently
        if (selection?.anchorOffset === 1 && selection.isCollapsed) {
          const range = document.createRange();
          range.selectNodeContents(element);
          range.collapse(false);
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      }
    });

    // Clean up the previous listener if it exists
    if (this.clickOutsideListener) {
      this.clickOutsideListener();
    }

    // Listen for clicks outside the editable area
    this.clickOutsideListener = this.renderer.listen(
      'document',
      'click',
      (clickEvent: MouseEvent) => {
        const clickedInside = this.elRef.nativeElement.contains(
          clickEvent.target,
        );
        if (!clickedInside) {
          this.saveEditedTitleClicked(clickEvent);
        }
      },
    );
    event.stopPropagation();
  }

  public cancelEditingTitleEscapePushed(event: Event) {
    this.cancelEditingTitle();
    event.stopPropagation();
  }

  private cancelEditingTitle(reset = true) {
    this.editingTitle.update(() => false);

    // reset innerHTML to the current title
    if (reset) {
      const element = this.titleElement.nativeElement;
      element.innerText = this.pageOrFile()?.title || '';
    }

    // Remove the click outside listener once editing is cancelled
    if (this.clickOutsideListener) {
      this.clickOutsideListener();
      this.clickOutsideListener = null;
    }
  }

  public updateEditingTitle(event: Event) {
    const target = event.target as HTMLInputElement;

    this.editingTitle.update((value) => target.innerText);
  }

  public saveEditedTitleClicked(event: Event) {
    const newTitle = this.editingTitle();
    const pageOrFile = this.pageOrFile();
    if (newTitle === false || !pageOrFile) {
      return;
    }
    const newStrippedTitle = newTitle?.trim();
    // remove html tags
    const strippedTitle = newStrippedTitle
      ?.replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/g, ' ');
    if (strippedTitle !== pageOrFile.title) {
      this.titleChange.emit(strippedTitle);
    }
    this.editingTitle.update((value) => false);
    // remove selection
    const selection = window.getSelection();
    selection?.removeAllRanges();
    this.cancelEditingTitle(false);
    event.stopPropagation();
  }

  public getDocumentPreviewSrc(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    const avatarSrcPath = page.mediaObject.sizes
      ?.find((size) => size.name === 'documentPreview')
      ?.src.replace(':talentUuid', this.talent.uuid);
    return avatarSrcPath ? environment.mediaUrl + avatarSrcPath : '';
  }

  public downloadStatus = signal<{
    progress: number;
    errorMessage?: string;
    src: string;
  }>({
    progress: 0,
    src: '',
    errorMessage: undefined,
  });

  public downloadDocument(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    if (!page.mediaObject.downloadUrl) {
      this.downloadStatus.update((prev) => ({
        progress: 1, // indicates started but failed
        src: '',
        errorMessage: 'No download URL available',
      }));
      return;
    }
    const downloadSrc =
      environment.mediaUrl +
      page.mediaObject.downloadUrl?.replace(':talentUuid', this.talent.uuid);
    const downloadProgress$ = this.http.get(downloadSrc, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });

    this.downloadStatus.update((prev) => ({
      progress: 1, // indicates started
      src: downloadSrc,
      errorMessage: undefined,
    }));

    downloadProgress$
      .pipe(
        catchError((err) => {
          const errorMessage =
            err instanceof HttpErrorResponse
              ? err.message
              : 'An error occurred';
          this.downloadStatus.update((prev) => ({
            ...prev,
            errorMessage,
          }));
          return [];
        }),
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.downloadStatus.update((prev) => ({
            ...prev,
            progress: Math.ceil((100 * event.loaded) / (event.total || 1)), // avoids 0 progress
          }));
        } else if (event.type === HttpEventType.Response) {
          const contentType = event.headers.get('Content-Type');
          const blob = event.body as Blob;

          const isErrorObject =
            contentType && contentType.includes('application/json');
          if (isErrorObject) {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const response = JSON.parse(reader.result as string);
                this.downloadStatus.update((prev) => ({
                  ...prev,
                  errorMessage: response.message || 'Unknown error',
                }));
              } catch (parseError) {
                this.downloadStatus.update((prev) => ({
                  ...prev,
                  errorMessage:
                    'An error occurred while processing the response',
                }));
              }
            };
            reader.readAsText(blob);
          } else {
            downloadBlobAsFile(blob, page.title);

            this.downloadStatus.update((prev) => ({
              ...prev,
              errorMessage: undefined,
              progress: 0,
            }));
          }
        }
      });
  }
}
