import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { randomId } from '../../helpers/functions/randomId';
import { logCustomError } from '../../helpers/functions/logError';
import {
  ContextMenuModule,
  ResponsiveService,
  setTimeout$,
  SheetModule,
  SheetService,
  TooltipModule,
  WysiwygModule,
} from '@intemp/unijob-ui';
import { MatchSheetService } from './services/match-sheet.service';
import { MatchSheetHeaderComponent } from './match-sheet-header/match-sheet-header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { MatchResponsibilityComponent } from './match-responsibility/match-responsibility.component';
import { LetDirective } from '@ngrx/component';
import { MatchSheetFormService } from './services/match-sheet-form.service';
import { ConsultantService } from '../../../core/services/consultant.service';
import { TalentPersonalDataComponent } from '../talent-sheet/talent-personal-data/talent-personal-data.component';
import {
  AccordionComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  FormFieldComponent,
  SimpleSelectComponent,
  TagComponent,
} from '@intemp/unijob-ui2';

import { toSignal } from '@angular/core/rxjs-interop';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../global-sheets/global-sheets.service';
import {
  MatchStatusEnum,
  TalentSubscribeGQL,
} from '../../../graphql/generated';
import { GraphQLService } from '../../../core/services/graphql.service';
import { TalentDocumentPreviewComponent } from '../talent-sheet/talent-document-preview/talent-document-preview.component';
import { MatchOverlayContentComponent } from './match-overlay-content/match-overlay-content.component';
import { OverlayWrapperComponent } from '../overlay-wrapper/overlay-wrapper.component';
import { VacancyWebsitePreviewComponent } from '../vacancy-detail/vacancy-website-preview/vacancy-website-preview.component';
import i18next from 'i18next';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatchActionMenuComponent } from '../../../pages/matches/match-action-menu/match-action-menu.component';
import { MatchesService } from '../../../core/services/matches/matches.service';
import { MatchContentComponent } from './match-detail/match-detail.component';
import { MatchArchiveSheetComponent } from './match-archive-sheet/match-archive-sheet.component';
import { FocusTrackerDirective } from '../vacancy-detail/focusTracker.directive';

@Component({
  standalone: true,
  selector: 'app-match-sheet',
  templateUrl: './match-sheet.component.html',
  imports: [
    SheetModule,
    MatchSheetHeaderComponent,
    AsyncPipe,
    NgIf,
    SharedDefaultModule,
    MatchResponsibilityComponent,
    LetDirective,
    MatchContentComponent,
    AccordionComponent,
    TalentPersonalDataComponent,
    TalentDocumentPreviewComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    MatchOverlayContentComponent,
    OverlayWrapperComponent,
    VacancyWebsitePreviewComponent,
    SimpleSelectComponent,
    TagComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    WysiwygModule,
    MatchActionMenuComponent,
    ContextMenuModule,
    TooltipModule,
    MatchArchiveSheetComponent,
  ],
  providers: [MatchSheetService, MatchSheetFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchSheetComponent implements AfterViewInit, OnInit {
  //services
  service = inject(MatchSheetService);
  matchesService = inject(MatchesService);
  consultantService = inject(ConsultantService);
  formService = inject(MatchSheetFormService);
  responsiveService = inject(ResponsiveService);
  globalSheetsService = inject(GlobalSheetsService);
  talentSubscribeGQL = inject(TalentSubscribeGQL);
  graphQLService = inject(GraphQLService);
  sheetService = inject(SheetService);

  //inputs
  sheetId = input<string>('match-edit' + randomId());
  matchId = input.required<string>();

  sheetClosed = output<string>();

  isSmDown$ = this.responsiveService.isSmDown$;

  isLgDown = toSignal(this.responsiveService.isLgDown$, {
    initialValue: false,
  });

  //overlay
  match = toSignal(this.service.match$, { initialValue: null });

  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });
  openSheetOverlayAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.overlayAction;
  });

  openSheetAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.action;
  });

  openSheetOverlayView = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.overlayView;
  });

  openOverlayOnSmallScreen = computed<boolean>(() => {
    return this.isLgDown() && this.openSheetOverlayView() !== undefined;
  });

  openSheetOverlayUuid = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId(),
    );
    return sheet?.overlayUuid;
  });

  actions = computed(() => {
    const match = this.match();
    if (!match) return { primaryActions: [], secondaryActions: [] };
    return this.matchesService.getMatchActionOptions(match);
  });

  hasSeparator = computed(() => {
    const actions = this.actions();
    return (
      actions.primaryActions.length > 0 && actions.secondaryActions.length > 0
    );
  });

  archiveMatchSheetId = 'archiveMatch' + randomId();

  viewOptions = [
    {
      value: 'talent',
      label: i18next.t('talent'),
    },
    {
      value: 'vacancy',
      label: i18next.t('vacancy'),
    },
  ];

  activeViewControl = new FormControl();

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  constructor() {
    this.activeViewControl.valueChanges.subscribe((value) => {
      this.globalSheetsService.updateParam(
        this.sheetId(),
        'overlayView',
        value,
      );
    });

    effect(() => {
      this.service.matchId$.next(this.matchId());
      if (this.openSheetOverlayView()) {
        this.activeViewControl.setValue(this.openSheetOverlayView());
      }
      if (this.openSheetAction()) {
        // need to wait for sheet order
        setTimeout(() => {
          this.handleAction(this.openSheetAction());
        }, 100);
      }
    });
  }

  ngOnInit() {
    if (!this.isLgDown() && !this.openSheetOverlayView()) {
      this.openPreview();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.matchId()) {
      logCustomError('missing MatchId on match-edit sheet');
    }
    setTimeout$(() => {
      this.openSheet();
    });

    this.listenToFocusTrackerChange();
  }

  openSheet() {
    this.service.open(this.sheetId());
    this.service.subscribeMatch();
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  openPreview() {
    this.globalSheetsService.updateParam(
      this.sheetId(),
      'overlayView',
      'talent',
    );

    this.globalSheetsService.updateParam(
      this.sheetId(),
      'overlayAction',
      'active',
    );
  }
  closePreview() {
    this.globalSheetsService.updateParam(this.sheetId(), 'overlayAction', null);
    this.globalSheetsService.updateParam(this.sheetId(), 'overlayView', null);
  }

  handleAction(action: string | undefined) {
    switch (action) {
      case 'archive-match':
        this.sheetService.open(this.archiveMatchSheetId);
        break;
      default:
        break;
    }
  }

  clearSheetActionParam() {
    const match = this.match();
    if (!match) {
      return;
    }
    this.globalSheetsService.updateParam(
      {
        type: GlobalSheetTypeEnum.MATCH_EDIT,
        uuid: match.uuid,
      },
      'action',
      null,
    );
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }

  protected readonly MatchStatusEnum = MatchStatusEnum;
}
