import { Component, computed, inject, input } from '@angular/core';
import { TalentDocumentPreviewComponent } from '../../talent-sheet/talent-document-preview/talent-document-preview.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { GlobalSheetsService } from '../../global-sheets/global-sheets.service';
import { catchError, combineLatest, EMPTY, filter, map, switchMap } from 'rxjs';
import {
  MatchFragment,
  TalentSubscribeGQL,
  VacancySubscribeGQL,
} from '../../../../graphql/generated';
import { GraphQLService } from '../../../../core/services/graphql.service';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import {
  ButtonSolidComponent,
  SimpleSelectComponent,
  TagComponent,
} from '@intemp/unijob-ui2';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedFormsModule } from '../../shared-forms/shared-forms.module';
import { VacancyWebsitePreviewComponent } from '../../vacancy-detail/vacancy-website-preview/vacancy-website-preview.component';
import { OverlayWrapperComponent } from '../../overlay-wrapper/overlay-wrapper.component';
import { ResponsiveService } from '@intemp/unijob-ui';

@Component({
  standalone: true,
  selector: 'app-match-overlay-content',
  templateUrl: './match-overlay-content.component.html',
  styleUrls: ['./match-overlay-content.component.scss'],
  imports: [
    TalentDocumentPreviewComponent,
    SharedDefaultModule,
    ButtonSolidComponent,
    TagComponent,
    ReactiveFormsModule,
    SharedFormsModule,
    SimpleSelectComponent,
    VacancyWebsitePreviewComponent,
    OverlayWrapperComponent,
  ],
})
export class MatchOverlayContentComponent {
  //services
  globalSheetsService = inject(GlobalSheetsService);
  talentSubscribeGQL = inject(TalentSubscribeGQL);
  vacancySubscribeGQL = inject(VacancySubscribeGQL);
  graphQLService = inject(GraphQLService);
  responsiveService = inject(ResponsiveService);

  //inputs
  sheetId = input.required<string>();
  hasHeader = input(true);
  match = input.required<MatchFragment>();
  openSheetOverlayUuid = input.required<string | undefined>();
  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });

  overlayAction = computed(
    () =>
      this.openSheets().find((sheet) => sheet.sheetId === this.sheetId())
        ?.overlayAction,
  );

  overlayView = computed(
    () =>
      this.openSheets().find((sheet) => sheet.sheetId === this.sheetId())
        ?.overlayView,
  );

  talentUuid = computed(() => this.match()?.talent?.uuid);
  vacancyUuid = computed(() => this.match()?.vacancy?.uuid);

  isLgDown = toSignal(this.responsiveService.isLgDown$, {
    initialValue: false,
  });

  openOverlayOnSmallScreen = computed<boolean>(() => {
    return this.isLgDown() && this.overlayView() !== undefined;
  });

  vacancyObservable = combineLatest([
    toObservable(this.vacancyUuid),
    toObservable(this.overlayView),
  ]).pipe(
    filter(([, view]) => {
      return view === 'vacancy';
    }),
    switchMap(([vacancyUuid, openSheetOverlayView]) => {
      if (!vacancyUuid || !openSheetOverlayView) {
        return EMPTY;
      } else {
        return this.vacancySubscribeGQL
          .subscribe({ uuid: vacancyUuid }, { fetchPolicy: 'no-cache' })
          .pipe(
            map((res) => {
              return res.data?.vacancySubscribe;
            }),
            catchError((err) => {
              this.graphQLService.handleError(err);
              return [];
            }),
          );
      }
    }),
  );

  talentObservable = combineLatest([
    toObservable(this.talentUuid),
    toObservable(this.overlayView),
  ]).pipe(
    filter(([, view]) => {
      return view === 'talent';
    }),
    switchMap(([talentUuid]) => {
      if (!talentUuid) {
        return EMPTY;
      } else {
        return this.talentSubscribeGQL
          .subscribe({ uuid: talentUuid }, { fetchPolicy: 'no-cache' })
          .pipe(
            map((res) => {
              return res.data?.talentSubscribe;
            }),
            catchError((err) => {
              this.graphQLService.handleError(err);
              return [];
            }),
          );
      }
    }),
  );
  talent = toSignal(this.talentObservable, {
    initialValue: undefined,
  });

  vacancy = toSignal(this.vacancyObservable, {
    initialValue: undefined,
  });
}
