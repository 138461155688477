<div
  [formGroup]="formGroup"
  class="uj-flex uj-flex-col uj-gap-f uj-relative">
  <ui-library-form-field
    [label]="'originalAd' | i18next | firstUppercase"
    fullWidth>
    <div class="uj-flex uj-gap-c">
      <input
        ui-library-text-input
        focusTracker="originalUrl"
        formControlName="originalUrl"
        [placeholder]="'enterUrl' | i18next"
        fullWidth
        type="text" />
      <button
        *ngIf="originalUrlIsValid"
        (click)="navigateToOriginalAd()"
        ui-library-button-outline
        color="dark"
        iconOnly="expand_share_send"></button>
    </div>
  </ui-library-form-field>
  <ui-library-form-field [label]="'desiredAge' | i18next | firstUppercase">
    <div class="uj-flex uj-justify-center">
      <input
        ui-library-text-input
        focusTracker="agePreferenceMin"
        formControlName="agePreferenceMin"
        [placeholder]="'min' | i18next"
        type="number"
        fullWidth
        positionInGroup="start-seamless" />
      <div
        [class.uj-text-text-grey]="formIsDisabled"
        [class.uj-bg-surface-grape]="formIsDisabled"
        class="uj-px-d uj-py-c uj-border uj-border-x-0 uj-border-border uj-cursor-default uj-flex uj-items-center">
        —
      </div>
      <input
        ui-library-text-input
        focusTracker="agePreferenceMax"
        formControlName="agePreferenceMax"
        [placeholder]="'max' | i18next"
        type="number"
        fullWidth
        positionInGroup="center-seamless" />
      <div
        [class.uj-text-text-grey]="formIsDisabled"
        [class.uj-bg-surface-grape]="formIsDisabled"
        class="uj-px-d uj-py-c uj-border uj-border-l-0 uj-rounded-tr uj-rounded-r-b uj-border-border uj-text-text-54 uj-flex uj-items-center">
        {{ "years" | i18next }}
      </div>
    </div>
  </ui-library-form-field>
  <ui-library-form-field
    [label]="'notes' | i18next | firstUppercase"
    fullWidth>
    <unijob-wysiwyg
      focusTracker="notes"
      formControlName="notes"
      [linkPlaceholder]="'internNoteExample' | i18next"></unijob-wysiwyg>
  </ui-library-form-field>
  <ui-library-form-field
    [label]="'status' | i18next | firstUppercase"
    fullWidth>
    <app-vacancies-status-indicator
      [vacancy]="vacancy"
      showDate
      extendedFormat="true"
      textSize="medium"></app-vacancies-status-indicator>
  </ui-library-form-field>
  <div *ngIf="vacancy.status === VacancyStatusEnum.ARCHIVED">
    <label class="input-label"
      >{{ "reasonForArchiving" | i18next | firstUppercase }}
    </label>
    <app-vacancy-select-archive
      (selectChange)="updateArchiveReason($event)"
      [archiveReason]="archiveReason.value"></app-vacancy-select-archive>
  </div>
  <div
    *ngIf="
      vacancy.uniBaseX && vacancy.uniBaseX.vacancyNumbersWithDates.length > 0
    ">
    <label class="input-label"
      >{{ "vacancyNumber" | i18next | firstUppercase }}
    </label>
    <div class="uj-flex uj-flex-col uj-gap-c">
      <div
        *ngFor="
          let vacancyNumberWithDate of vacancy.uniBaseX.vacancyNumbersWithDates;
          let i = index
        "
        class="rounded-sm uj-flex uj-gap-c">
        <div
          ui-library-pseudo-input
          isDisabled
          class="uj-flex-1">
          {{ vacancyNumberWithDate.vacancyNumber }}
        </div>
        <button
          *ngIf="i === 0"
          (click)="
            copyVacancyNumberToClipboard(vacancyNumberWithDate.vacancyNumber)
          "
          ui-library-button-outline
          color="dark"
          iconOnly="copy_plus_duplicate"
          [tooltip]="'copyToClipboard' | i18next"></button>
      </div>
    </div>
  </div>
</div>
