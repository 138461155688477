import { TalentFragment } from '../../../../graphql/generated';
import { ChangelogState } from '../../../../pages/talents/talent.types';

/**
 * Check if the field can be reverted, if not, the revert button  should not be displayed in the changelog.
 * @description
 * This is required because some fields should not be reverted.
 * @param fieldName
 */
export function canRevertChangelog(
  fieldName: keyof TalentFragment,
  action: ChangelogState,
) {
  if (action === 'added') {
    const fieldKeysToExclueIdfActionIsAdded: (keyof TalentFragment)[] = [
      'pages',
      'files',
    ];
    return !fieldKeysToExclueIdfActionIsAdded.includes(fieldName);
  }
  const fieldKeysToExclude: (keyof TalentFragment)[] = [
    'dataPrivacyPolicySentEmails',
    'talentDataAvailableUntil',
    'hasAllowedDataUsageAt',
    'files',
    'sources',
  ];

  return !fieldKeysToExclude.includes(fieldName);
}
