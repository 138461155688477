<ng-container
  *ngrxLet="{
    sheetId: sheetId(),
    match: match(),
  } as data">
  <unijob-sheet
    [id]="sheetId()"
    size="m"
    (sheetScrolled)="service.triggerAnchorScroll()"
    (sheetClosed)="handleSheetClosed(sheetId())"
    contentFlex>
    <ng-container
      slot="overlay"
      *ngIf="openSheetOverlayView() && !isLgDown()">
      <ng-container *ngTemplateOutlet="matchPreviewOverlay"></ng-container>
    </ng-container>
    <ng-container slot="nav">
      @if (openSheetOverlayView()) {
        <button
          ui-library-button-solid
          iconOnly="preview_zoom"
          corner="round"
          (click)="this.closePreview()"></button>
      } @else {
        <button
          ui-library-button-outline
          iconOnly="preview_zoom"
          corner="round"
          (click)="this.openPreview()"></button>
      }
    </ng-container>
    <ng-container slot="header">
      <app-match-sheet-header
        *ngIf="data.match; else loading"
        [match]="data.match"></app-match-sheet-header>
    </ng-container>
    <ng-container slot="content">
      <div class="uj-flex uj-flex-col uj-gap-f uj-w-full uj-h-fit">
        <div class="uj-flex uj-flex-col uj-gap-d">
          <app-match-responsibility
            *ngIf="consultantService.consultantUsers$ | async as consultants"
            [allConsultants]="consultants"
            [consultantIdControl]="
              formService.responsibleUserId
            "></app-match-responsibility>

          <ui-library-form-field
            fullWidth
            [label]="'progress' | i18next"
            labelFor="progress">
            <ui-library-simple-select
              [formControl]="formService.processStep"
              [options]="formService.processStepOptions"
              size="M"
              backgroundColor="white"
              title="progress" />
          </ui-library-form-field>

          <ui-library-form-field
            *ngIf="match()?.status === MatchStatusEnum.ARCHIVED"
            fullWidth
            [label]="'reasonForArchiving' | i18next"
            labelFor="reasonForArchiving">
            <ui-library-simple-select
              class="uj-grow"
              size="M"
              [options]="formService.archiveReasonsOptions"
              [placeholderText]="'selectReason' | i18next"
              design="outline"
              [formControl]="formService.archiveReason"
              backgroundColor="white">
            </ui-library-simple-select>
          </ui-library-form-field>

          <ui-library-form-field
            fullWidth
            [label]="'internalRemark' | i18next"
            labelFor="internalRemark">
            <unijob-wysiwyg
              class="uj-mb-b"
              title="internalRemark"
              focusTracker="internalRemarks"
              [formControl]="formService.internalRemarks"
              [linkPlaceholder]="'addNotes' | i18next"></unijob-wysiwyg>
          </ui-library-form-field>
        </div>

        <ui-library-accordion
          id="matchDetailsAccordion"
          class="uj-block uj-w-full uj-mb-g"
          type="left-outside"
          [label]="'details' | i18next"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'">
          <app-match-detail />
        </ui-library-accordion>
      </div>
      <div
        class="uj-absolute uj-flex uj-justify-center uj-gap-c sm:uj-mt-0 uj-right-e uj-left-e sm:uj-left-auto uj-bottom-e">
        <button
          class="uj-w-full md:uj-w-auto"
          ui-library-button-solid
          [contextMenu]="contextMenuTemplate"
          [contextMenuTemplateVariables]="{ match: data.match }"
          contextMenuBgColor="bg-pink"
          horizontalAlignment="center"
          iconRight="menu_dots_vertical">
          {{ "actions" | i18next }}
        </button>
      </div>
    </ng-container>
  </unijob-sheet>

  <ng-template #loading>
    <div><app-loading-spinner></app-loading-spinner></div>
  </ng-template>

  <ng-template #matchPreviewOverlay>
    <div class="uj-flex uj-justify-center uj-w-full uj-h-full">
      @if (openSheetOverlayView()) {
        <app-match-overlay-content
          *ngIf="data.match && data.sheetId"
          [openSheetOverlayUuid]="openSheetOverlayUuid()"
          [sheetId]="data.sheetId"
          [hasHeader]="true"
          [match]="data.match">
          <ng-container
            header-left
            *ngTemplateOutlet="overlayHeaderLeft"></ng-container>
          <ng-container
            header-center
            *ngTemplateOutlet="overlayHeaderCenter"></ng-container>
        </app-match-overlay-content>
      }
    </div>
  </ng-template>

  @if (openOverlayOnSmallScreen()) {
    <app-overlay-wrapper
      [open]="openOverlayOnSmallScreen()"
      (close)="closePreview()">
      <ng-container content>
        <div class="uj-flex uj-justify-center uj-w-full uj-h-full uj-pb-f">
          @if (openSheetOverlayView()) {
            <app-match-overlay-content
              *ngIf="data.match && data.sheetId"
              [openSheetOverlayUuid]="openSheetOverlayUuid()"
              [sheetId]="data.sheetId"
              [hasHeader]="false"
              [match]="data.match">
            </app-match-overlay-content>
          }
        </div>
      </ng-container>
      <ng-container
        header-left
        *ngTemplateOutlet="overlayHeaderLeft"></ng-container>
      <ng-container
        header-center
        *ngTemplateOutlet="overlayHeaderCenter"></ng-container>
    </app-overlay-wrapper>
  }
</ng-container>

<ng-template #overlayHeaderLeft>
  <ng-container *ngIf="openSheetOverlayView() === 'vacancy'">
    <span
      color="warning-1"
      ui-library-tag
      fontWeight="bold"
      >{{ "preview" | i18next }}</span
    >
  </ng-container>
</ng-template>

<ng-template #overlayHeaderCenter>
  <ui-library-simple-select
    class="uj-w-[150px]"
    size="M"
    design="outline"
    [options]="viewOptions"
    [formControl]="activeViewControl" />
  <ng-container *ngIf="false">
    <button
      ui-library-button-solid
      positionInGroup="start"
      corner="round"
      iconOnly="zoom_minus"
      color="secondary-dark"></button>
    <button
      ui-library-button-solid
      positionInGroup="center"
      corner="round"
      iconOnly="full_page"
      color="secondary-dark"></button>
    <button
      ui-library-button-solid
      positionInGroup="end"
      corner="round"
      iconOnly="zoom_plus"
      color="secondary-dark"></button>
  </ng-container>
</ng-template>

<ng-template #overlayHeaderRight>
  <span
    color="warning-1"
    ui-library-tag
    fontWeight="bold"
    >{{ "preview" | i18next }}</span
  >
</ng-template>

<ng-template
  #contextMenuTemplate
  let-match="match">
  <div>
    <app-match-action-menu
      [match]="match"
      [isInSheet]="true"
      [buttonStyle]="'solid'" />
  </div>
</ng-template>

<app-match-archive-sheet
  [match]="match()"
  (sheetClosed)="clearSheetActionParam()"
  [sheetId]="archiveMatchSheetId"></app-match-archive-sheet>
