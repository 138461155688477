import { TalentFragment } from '../../../../graphql/generated';

export function getChangelogFieldLabel(fieldName: keyof TalentFragment) {
  switch (fieldName) {
    case 'recommendedBy':
      return 'recommendation';
    case 'sources':
      return 'leadsOrSources';
    case 'preferenceNote':
      return 'remark';
    case 'preferredLocations':
      return 'preferredLocation';
    case 'workloadFlexible':
      return 'useFromTo';
    case 'salaryExpectationFlexible':
      return 'salaryRangeFromTo';
    case 'salaryExpectationMax':
    case 'workloadPercentageMax':
      return 'maxValue';
    case 'salaryExpectationMin':
    case 'workloadPercentageMin':
      return 'minValue';
    case 'workloadPercentage':
      return 'workload';
    case 'employmentType':
      return 'employment';
    case 'targetJobTitle':
      return 'targetJob';
    case 'sex':
      return 'speech';
    case 'pages':
      return 'page';
    case 'files':
      return 'file';
    default:
      return fieldName;
  }
}

export function getChangelogGroupLabel(
  fieldName: keyof TalentFragment,
): string | undefined {
  switch (fieldName) {
    case 'availableForWorkAt':
    case 'employedSince':
    case 'employedUntil':
    case 'employmentCompany':
      return 'availability';
    case 'preferenceNote':
      return 'preferences';
    case 'commutingByPublicTransport':
    case 'commutingByBicycle':
    case 'commutingByCar':
      return 'transportation';
    case 'salaryExpectationFlexible':
    case 'salaryExpectation':
    case 'salaryExpectationMin':
    case 'salaryExpectationMax':
      return 'salaryExpectation';
    case 'workloadPercentageMax':
    case 'workloadPercentageMin':
      return 'workload';
    case 'workloadFlexible':
    case 'workloadPercentage':
      return 'employment';
    case 'pages':
      return 'documents';
    case 'files':
      return 'originalFiles';
    default:
      return undefined;
  }
}
