import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { MatchSheetFormService } from '../services/match-sheet-form.service';
import { MatchPartiesComponent } from '../match-parties/match-parties.component';
import { ConsultantSelectModule } from '../../consultant-select/consultant-select.module';
import { LetDirective } from '@ngrx/component';
import { MatchSheetService } from '../services/match-sheet.service';
import { RatingStarsComponent } from '../../../components/rating-stars/rating-stars.component';
import { FormFieldComponent } from '@intemp/unijob-ui2';
import { WysiwygModule } from '@intemp/unijob-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { MatchSourceComponent } from '../match-source/match-source.component';

@Component({
  standalone: true,
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  imports: [
    I18NextModule,
    SharedDefaultModule,
    MatchPartiesComponent,
    ConsultantSelectModule,
    LetDirective,
    RatingStarsComponent,
    FormFieldComponent,
    WysiwygModule,
    ReactiveFormsModule,
    MatchSourceComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchContentComponent {
  formService = inject(MatchSheetFormService);
  matchSheetService = inject(MatchSheetService);
  i18next = inject(I18NextPipe);
}
