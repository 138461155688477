import { TalentFormEditService } from '../../services/talentFormEdit.service';
import {
  AfterViewInit,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { WysiwygModule } from '@intemp/unijob-ui';
import { I18NextModule } from 'angular-i18next';
import { SharedDefaultModule } from '../../../shared-default/shared-default.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FocusTrackerDirective } from '../../../vacancy-detail/focusTracker.directive';

@Component({
  standalone: true,
  selector: 'app-talent-internal-note',
  templateUrl: './talent-internal-note.component.html',
  imports: [
    WysiwygModule,
    I18NextModule,
    SharedDefaultModule,
    ReactiveFormsModule,
  ],
})
export class TalentInternalNoteComponent implements AfterViewInit {
  formService = inject(TalentFormEditService);

  @ViewChildren(FocusTrackerDirective)
  focusTrackers?: QueryList<FocusTrackerDirective>;

  ngAfterViewInit() {
    this.listenToFocusTrackerChange();
  }

  listenToFocusTrackerChange() {
    if (!this.focusTrackers) return;
    for (const tracker of this.focusTrackers) {
      tracker.focusTrackerChange.subscribe((value) => {
        this.formService.focusedFields.next({
          [tracker.targetID as string]: value,
        });
      });
    }
  }
}
