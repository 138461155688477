import { Observable } from 'rxjs';
import {
  CurrencyEnum,
  SalaryUnitEnum,
  VacanciesTableColumnConfiguration,
  VacancyArchiveReasonEnum,
  VacancyDetailUniBaseXVacancyFragment,
  VacancyEmploymentTypeEnum,
  VacancyHistoryEntryFragment,
  VacancyLanguageRequirement,
  VacancyPositionTypeEnum,
  VacancyPublishableFieldsEnum,
  VacancySortEnum,
  VacancyTableColumnIdEnum,
  VacancyUpdateMutationVariables,
} from '../../graphql/generated';
import { SortKeyWithColumnLabel } from '../../shared/generic-modules/advanced-data-list/advanced-data-view.types';
import { unijobIcon } from '@intemp/unijob-ui2/particle/icon/unijob-icon.model';

export enum VacancyTabEnum {
  VACANCY = 'VACANCY',
  PROMOTE = 'PROMOTE',
  STATS = 'STATS',
}

// base data is not in a category /accordion
export type VacancyResponsibilityFormData = {
  vacancyUuid: string;
  consultant?: VacancyDetailUniBaseXVacancyFragment['responsibleUser'];
  branchId?: string;
};

export type VacancyCustomerFormData = {
  publishExactJobLocation?: boolean;
  street?: string;
  zip?: string;
  location?: string;
  jobLocationAddressReplacement?: string;
  companyLocation?: {
    companyName: string;
    address: NonNullable<
      VacancyDetailUniBaseXVacancyFragment['companyLocation']
    >['address'];
    contacts: Array<
      NonNullable<VacancyDetailUniBaseXVacancyFragment['companyContact']>
    >;
    uuid: NonNullable<
      VacancyDetailUniBaseXVacancyFragment['companyLocation']
    >['uuid'];
  };
  companyContact?: VacancyDetailUniBaseXVacancyFragment['companyContact'];
};

export type VacancyAdvertisementFormData = {
  title?: string;
  functionText: string | null;
  requirementsText: string | null;
  benefitsText: string | null;
  employerDescriptionText?: string;
};

export type VacancyCriteriaItemFormData = {
  workloadFlexible?: boolean;
  showWorkloadInPublicTitle?: boolean;
  employmentType?: VacancyEmploymentTypeEnum;
  employmentStart?: Date;
  employmentEnd?: Date;
  fixedTerm: boolean;
  workloadPercentageMin?: number;
  workloadPercentageMax?: number;
  workloadPercentage?: number;
  salaryUnit?: SalaryUnitEnum;
  positionType?: VacancyPositionTypeEnum;
  salaryMin?: number;
  salaryMax?: number;
  salary?: number;
  salaryHoursPerWeek?: number;
  salaryCurrency?: CurrencyEnum;
  salaryFlexible?: boolean;
  salaryPaymentsPerYear?: number;
  publishSalary?: boolean;
  languageRequirements?: readonly VacancyLanguageRequirement[];
};

export type VacancyInternalFormData = {
  originalUrl?: string;
  agePreferenceMin?: number;
  agePreferenceMax?: number;
  notes: string | null;
  archiveReason?: VacancyArchiveReasonEnum;
};

export type SubmitVacancyUpdateInput = Omit<
  VacancyUpdateMutationVariables['input'],
  'vacancyUuid'
>;

export type VacancyAllowedAction = {
  label: string;
  shortLabel?: string;
  icon?: unijobIcon;
  action: () => void;
  inVacancyDetail?: boolean;
  theme: 'primary' | 'secondary';
  disabledReason$?: Observable<string | undefined>;
};

export type UnibaseXPublicationChanges = Partial<
  Record<VacancyPublishableFieldsEnum, boolean>
>;

export type TableUserConfiguration = Record<
  VacancyTableColumnIdEnum,
  VacanciesTableColumnConfiguration
>;

/**
 * In the future, we might have additional columns that are independent of VacancyTableColumnId
 */
export type TableColumns = VacancyTableColumnIdEnum;

/**
 * Defines the sort keys to be used for each column. If no sort key is available, the column
 * is not sortable.
 */
export type ColumnSortKeys = Partial<Record<TableColumns, VacancySortEnum>>;

export type VacancyColumnTitles = Record<VacancyTableColumnIdEnum, string>;

export type VacancySortKeyWithColumnLabel = SortKeyWithColumnLabel & {
  value: VacancySortEnum;
  label: string;
};

export type VacancyHistoryEntryFragmentForLocal =
  VacancyHistoryEntryFragment & {
    isUnpublishedChange?: boolean;
  };
