import { MatchProcessStepEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getProcessStepOptions = () => {
  return [
    {
      value: MatchProcessStepEnum.ENTRY,
      label: i18next.t('MatchProcessStepEnum.ENTRY'),
    },
    {
      value: MatchProcessStepEnum.PREPARATION,
      label: i18next.t('MatchProcessStepEnum.PREPARATION'),
    },
    {
      value: MatchProcessStepEnum.RECOMMENDATION,
      label: i18next.t('MatchProcessStepEnum.RECOMMENDATION'),
    },
    {
      value: MatchProcessStepEnum.EXAMINATION,
      label: i18next.t('MatchProcessStepEnum.EXAMINATION'),
    },
    {
      value: MatchProcessStepEnum.CONCLUSION,
      label: i18next.t('MatchProcessStepEnum.CONCLUSION'),
    },
  ];
};
