<unijob-sheet
  [id]="sheetId"
  size="l"
  (sheetScrolled)="triggerAnchorScroll()"
  (sheetClosed)="handleSheetClosed(sheetId)"
  contentFlex>
  <ng-container
    slot="overlay"
    *ngIf="openPreviewOverlay() && !isLgDown()">
    <ng-container *ngTemplateOutlet="vacancyPreviewOverlay"></ng-container>
  </ng-container>
  <ng-container slot="header">
    <div *ngIf="!vacancy"><app-loading-spinner></app-loading-spinner></div>
    <div *ngIf="vacancy">
      <div class="uj-flex uj-items-center uj-justify-between">
        <div
          class="uj-flex uj-flex-2 uj-w-0 uj-flex-1 uj-min-h-[36px] uj-items-center">
          <h2
            class="uj-truncate title-m"
            data-cy="vacancy-sheet-title">
            {{
              vacancy?.uniBaseX?.publicTitle
                ? vacancy?.uniBaseX?.publicTitle
                : ("emptyVacancy" | i18next)
            }}
          </h2>
        </div>
        <div
          class="uj-items-center uj-justify-end uj-hidden uj-ml-d md:uj-flex">
          <div>
            <app-current-user-avatar
              [loggedInUserBranchesNames]="loggedInUserBranchesNames" />
          </div>
          <ng-container *ngIf="responsibleEmployeeBranch">
            <span
              class="unijob-vertical-divider uj-h-d uj-mx-[6px] md:uj-mx-c"></span>
            <ng-container>
              <ui-library-avatar
                content="letters"
                [letters]="responsibleEmployeeBranch.name.slice(0, 3)"
                size="s"
                theme="outline"
                interactive
                [overflowEllipsis]="false"
                [contextMenu]="branchTemplate"
                horizontalAlignment="center"
                contextMenuBgColor="bg-white"></ui-library-avatar>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="uj-flex uj-mt-b uj-center md:uj-justify-between">
        <div class="uj-flex uj-items-center">
          <app-vacancies-status-indicator
            [vacancy]="vacancy"
            showDate
            class="uj-mr-b"></app-vacancies-status-indicator>
          <ng-container
            *ngIf="
              vacancy.uniBaseX &&
              vacancy.uniBaseX.vacancyNumbersWithDates?.length
            ">
            <span class="uj-h-c uj-ml-c unijob-vertical-divider"></span>
            <span class="uj-ml-c text-s">
              {{ vacancy.uniBaseX.vacancyNumbersWithDates[0].vacancyNumber }}
            </span>
          </ng-container>
        </div>
        <div
          *ngIf="vacancy?.uniBaseX?.updatedAtForUser"
          class="uj-flex uj-flex-row-reverse uj-items-center uj-ml-c md:uj-flex-row">
          <div
            class="uj-flex uj-flex-row-reverse uj-items-center md:uj-flex-row">
            @if (isSavingIndicator()) {
              <span
                class="uj-hidden text-s sm:uj-block"
                data-cy="vacancy-saving-indicator">
                {{ "saving" | i18next }}
              </span>
              <span
                class="uj-block text-s sm:uj-hidden"
                data-cy="vacancy-saving-indicator">
                {{ "saving" | i18next }}
              </span>
              <i
                ui-library-icon-tag
                icon="menu_dots_horizontal"
                class="uj-mr-[6px] md:uj-mr-0 md:uj-ml-[6px]"
                color="warning-1"></i>
            } @else {
              <span
                class="uj-hidden text-s sm:uj-block"
                data-cy="vacancy-saved-indicator">
                {{ lastEditTime }}
              </span>
              <span
                class="uj-block text-s sm:uj-hidden"
                data-cy="vacancy-saved-indicator">
                {{ lastEditTimeMobile }}
              </span>
              <i
                ui-library-icon-tag
                icon="check"
                class="uj-mr-[6px] md:uj-mr-0 md:uj-ml-[6px]"
                color="positive"></i>
            }
            <span class="uj-h-c uj-mx-c unijob-vertical-divider"></span>
            <button
              (click)="openChangelogSheet()"
              ui-library-button-link
              size="S"
              color="dark"
              fontWeight="bold">
              {{ "history" | i18next | firstUppercase }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uj-z-10 uj-w-full uj-pt-e uj-bg-surface-main">
      <div
        scrollOverflowGradient
        class="uj-tab-group">
        <unijob-tab
          [name]="'vacancy' | i18next | firstUppercase"
          [active]="activeTab === tabEnum.VACANCY"
          (tabSelected)="switchActiveTabTo(tabEnum.VACANCY)"></unijob-tab>
        <unijob-tab
          [name]="'promote' | i18next | firstUppercase"
          [active]="activeTab === tabEnum.PROMOTE"
          (tabSelected)="switchActiveTabTo(tabEnum.PROMOTE)"></unijob-tab>
        <unijob-tab
          [name]="'stats' | i18next | firstUppercase"
          [active]="activeTab === tabEnum.STATS"
          (tabSelected)="switchActiveTabTo(tabEnum.STATS)"></unijob-tab>
      </div>
    </div>
  </ng-container>
  <ng-container slot="content">
    <div
      class="lg:uj-max-w-[550px] uj-grow uj-h-fit"
      [class.lg:uj-max-w-none]="activeTab === tabEnum.STATS"
      #scrollContainerChild>
      <div *ngIf="activeTab === tabEnum.VACANCY">
        <ng-container *ngIf="baseFormData">
          <app-vacancy-responsibility
            *ngIf="baseFormData"
            [responsibleUserInfoMessage]="
              this.getResponsibleUserInfoMessage(vacancy)
            "
            (triggerAutoSave)="autoSave($event)"
            [formIsDisabled]="formsAreDisabled"
            [responsibilityData]="baseFormData"
            [formRef]="vacancyForm"></app-vacancy-responsibility>
        </ng-container>
        <ui-library-accordion
          id="customerAccordion"
          class="uj-block uj-w-full uj-my-g"
          [label]="'customer' | i18next"
          [indicatorColor]="'primary'"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'"
          [indicator]="!!(changesCustomerFormData | keyvalue)?.length">
          <div *ngIf="customerFormData">
            <app-vacancy-detail-customer-form
              [unibaseXVacancy]="vacancy?.uniBaseX || undefined"
              (triggerAutoSave)="autoSave($event)"
              [customerData]="customerFormData"
              [formIsDisabled]="formsAreDisabled"
              [formRef]="vacancyForm"></app-vacancy-detail-customer-form>
          </div>
        </ui-library-accordion>
        <ui-library-accordion
          id="advertisementAccordion"
          class="uj-block uj-w-full uj-mb-g"
          [label]="'advertisement' | i18next"
          [indicatorColor]="'primary'"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'"
          [indicator]="!!(changesAdvertisementFormData | keyvalue)?.length">
          <div *ngIf="advertisementFormData">
            <app-vacancy-detail-advertisement
              (triggerAutoSave)="autoSave($event)"
              [formRef]="vacancyForm"
              [formIsDisabled]="formsAreDisabled"
              [uniBaseXVacancy]="vacancy?.uniBaseX || undefined"
              [advertisementData]="
                advertisementFormData
              "></app-vacancy-detail-advertisement>
          </div>
        </ui-library-accordion>
        <ui-library-accordion
          id="criteriaAccordion"
          class="uj-block uj-w-full uj-mb-g"
          [label]="'criteria' | i18next"
          [indicatorColor]="'primary'"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'"
          [indicator]="!!(changesCriteriaFormData | keyvalue)?.length">
          <div *ngIf="criteriaFormData">
            <app-vacancy-detail-criteria
              [vacancy]="vacancy?.uniBaseX || undefined"
              [formRef]="vacancyForm"
              (triggerAutoSave)="autoSave($event)"
              [formIsDisabled]="formsAreDisabled"
              [criteriaData]="criteriaFormData"></app-vacancy-detail-criteria>
          </div>
        </ui-library-accordion>
        <ui-library-accordion
          id="internalAccordion"
          class="uj-block uj-w-full uj-mb-g"
          [label]="'intern' | i18next"
          [indicatorColor]="'primary'"
          [open]="true"
          [type]="!(isSmDown$ | async) ? 'left-outside' : 'left'"
          [indicator]="!!(changesInternalFormData | keyvalue)?.length">
          <div *ngIf="internalFormData && vacancy">
            <app-vacancy-detail-intern
              [formRef]="vacancyForm"
              (triggerAutoSave)="autoSave($event)"
              [vacancy]="vacancy"
              [formIsDisabled]="formsAreDisabled"
              [internData]="internalFormData"></app-vacancy-detail-intern>
          </div>
        </ui-library-accordion>
      </div>
      <div *ngIf="activeTab === tabEnum.PROMOTE">
        <app-vacancy-promote
          *ngIf="vacancy"
          [vacancy]="vacancy"></app-vacancy-promote>
      </div>
      <div *ngIf="activeTab === tabEnum.STATS">
        <app-vacancy-stats
          *ngIf="vacancy"
          [vacancy]="vacancy"></app-vacancy-stats>
      </div>
    </div>
    <aside
      *ngIf="activeTab !== tabEnum.STATS"
      class="uj-sticky uj-top-0 uj-hidden uj-w-0 lg:uj-block uj-ml-f uj-grow">
      <app-anchor-navigation
        [customScrollEventName]="onScrollEventName"
        [scrollContainer]="scrollContainerChild.parentElement"
        [thresholdNumber]="158"
        [thresholdMultiplier]="0"
        [anchorList]="
          activeTab === tabEnum.VACANCY
            ? [
                {
                  name: 'customer' | i18next,
                  id: 'customerAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },

                {
                  name: 'advertisement' | i18next,
                  id: 'advertisementAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
                {
                  name: 'criteria' | i18next,
                  id: 'criteriaAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
                {
                  name: 'intern' | i18next,
                  id: 'internalAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
              ]
            : [
                {
                  name: 'internalChannels' | i18next,
                  id: 'internalChannelsAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
                {
                  name: 'externalChannels' | i18next,
                  id: 'externalChannelsAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
                {
                  name: 'addOn' | i18next,
                  id: 'addOnAccordion',
                  beforeScrollFunction: openAccordionIfCollapsed,
                },
              ]
        ">
      </app-anchor-navigation>
    </aside>
    <div
      class="uj-absolute uj-flex uj-justify-center uj-gap-c sm:uj-mt-0 uj-right-e uj-left-e sm:uj-left-auto uj-bottom-e">
      <ng-container
        *ngIf="
          primaryAction &&
            (primaryAction.disabledReason$ | async) as disabledReason;
          else enabledPrimaryAction
        ">
        <div
          class="uj-flex uj-grow sm:uj-grow-0"
          [tooltip]="disabledReason"
          [tooltipTrigger]="'HOVER'"
          tooltipPosition="TOP"
          tooltipPadding="normal"
          bgColor="bg-pink">
          <button
            class="uj-grow"
            (click)="primaryAction.action()"
            ui-library-button-solid
            type="button"
            [iconLeft]="primaryAction.icon"
            disabled>
            {{ primaryAction.label | i18next | firstUppercase }}
          </button>
        </div>
      </ng-container>
      <ng-template #enabledPrimaryAction>
        <div
          class="uj-flex uj-grow sm:uj-grow-0"
          *ngIf="primaryAction">
          <button
            class="uj-grow"
            (click)="primaryAction.action()"
            ui-library-button-solid
            type="button"
            [iconLeft]="primaryAction.icon">
            {{ primaryAction.label | i18next | firstUppercase }}
          </button>
        </div>
      </ng-template>
      <button
        *ngIf="
          primaryAction &&
          (primaryActions.length > 0 || secondaryActions.length > 0)
        "
        ui-library-button-solid
        type="button"
        [contextMenu]="more"
        contextMenuBgColor="bg-pink"
        horizontalAlignment="center"
        iconOnly="menu_dots_vertical"></button>
      <button
        *ngIf="
          !primaryAction &&
          (primaryActions.length > 0 || secondaryActions.length > 0)
        "
        ui-library-button-solid
        [contextMenu]="more"
        contextMenuBgColor="bg-pink"
        horizontalAlignment="center"
        iconRight="menu_dots_vertical">
        {{ "actions" | i18next }}
      </button>
      <ng-template #more>
        <div class="uj-flex uj-flex-col uj-items-start">
          <button
            *ngFor="let option of primaryActions"
            (click)="option.action()"
            ui-library-button-solid
            color="accent"
            align="start"
            fullWidth
            [iconLeft]="option.icon">
            {{ option.shortLabel || option.label | i18next | firstUppercase }}
          </button>
          <hr
            class="uj-mx-[24px] uj-my-b uj-bg-border uj-h-[1px] uj-w-[calc(100%-48px)]"
            *ngIf="hasActionsSeperator" />
          <button
            *ngFor="let option of secondaryActions"
            (click)="option.action()"
            ui-library-button-solid
            color="accent"
            align="start"
            fullWidth
            [iconLeft]="option.icon">
            {{ option.shortLabel || option.label | i18next | firstUppercase }}
          </button>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-container slot="nav">
    @if (openPreviewOverlay()) {
      <button
        ui-library-button-solid
        iconOnly="preview_zoom"
        corner="round"
        (click)="this.closePreview()"></button>
    } @else {
      <button
        ui-library-button-outline
        iconOnly="preview_zoom"
        corner="round"
        (click)="this.openPreview()"></button>
    }
  </ng-container>
</unijob-sheet>

<app-vacancy-publish-sheet
  [sheetId]="publishVacancySheetId"
  [status]="vacancy?.status"
  (triggerAutoSave)="autoSave($event)"
  (sheetClosed)="clearSheetActionParam()"
  [invalidFields]="invalidFields"
  [vacancy]="vacancy">
</app-vacancy-publish-sheet>

<app-vacancy-archive-sheet
  [sheetId]="archiveVacancySheetId"
  (triggerAutoSave)="autoSave($event)"
  (sheetClosed)="clearSheetActionParam()"
  [vacancy]="vacancy">
</app-vacancy-archive-sheet>

<ng-template #branchTemplate>
  <div
    *ngIf="responsibleEmployeeBranch"
    class="uj-flex uj-flex-col uj-gap-c uj-p-e uj-w-[280px] sm:uj-w-[320px]">
    <div class="uj-flex uj-items-center uj-gap-c">
      <ui-library-avatar
        content="letters"
        [letters]="responsibleEmployeeBranch.name.slice(0, 3)"
        size="m"
        [overflowEllipsis]="false"
        theme="outline"
        backgroundColor="white"></ui-library-avatar>
    </div>
    <div class="uj-flex uj-flex-col uj-gap-a">
      <span class="text-m-b">{{ responsibleEmployeeBranch.name }}</span>
      <span class="text-m uj-text-text-grey">{{
        responsibleEmployeeBranch.contactInformation.address.street
      }}</span>
      <span class="text-m uj-text-text-grey"
        >{{ responsibleEmployeeBranch.contactInformation.address.zip }}
        {{
          responsibleEmployeeBranch.contactInformation.address.location
        }}</span
      >
    </div>
    <div class="uj-flex uj-gap-c">
      <a
        ui-library-button-outline
        target="_blank"
        [href]="'tel:' + responsibleEmployeeBranch.contactInformation.phone"
        class="uj-pointer-events-auto"
        iconOnly="phone"
        color="dark">
      </a>
      <a
        ui-library-button-outline
        target="_blank"
        [href]="'mailto:' + responsibleEmployeeBranch.contactInformation.email"
        class="uj-pointer-events-auto"
        iconOnly="mail"
        color="dark">
      </a>
      <a
        ui-library-button-outline
        target="_blank"
        [href]="
          responsibleEmployeeBranch.contactInformation.address
            | addressToGoogleMapUrl
        "
        class="uj-pointer-events-auto"
        iconOnly="map"
        color="dark"
        (click)="$event.stopPropagation()">
      </a>
    </div>
  </div>
</ng-template>

<ui-library-modal
  [id]="deleteConfirmationModalId"
  (closeClicked)="clearSheetActionParam()"
  size="S">
  <ng-container slot="header">
    {{ "deleteVacancy" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "deleteVacancyConfirmationHint" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col uj-justify-end uj-w-full uj-gap-c sm:uj-flex-row">
      <button
        (click)="
          modals.close(deleteConfirmationModalId); clearSheetActionParam()
        "
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="dark">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteVacancy()"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>

<app-overlay-wrapper
  [open]="openOverlayOnSmallScreen()"
  (close)="closePreview()">
  <ng-container
    *ngIf="vacancy"
    content>
    <div class="uj-flex uj-justify-center uj-w-full uj-h-full uj-pb-g">
      <app-vacancy-website-preview
        [vacancy]="vacancy"></app-vacancy-website-preview>
    </div>
  </ng-container>
</app-overlay-wrapper>

<ng-template #vacancyPreviewOverlay>
  <div
    *ngIf="vacancy"
    class="uj-flex uj-justify-center uj-w-full uj-h-full">
    <app-vacancy-website-preview
      [vacancy]="vacancy"></app-vacancy-website-preview>
  </div>
</ng-template>
