import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerComponent } from './img-loader-spinner.component';

@Directive({
  selector: 'img[appImgLoader]',
  standalone: true,
})
export class ImgLoaderDirective implements OnInit, OnDestroy {
  private _src = '';
  @Input() set src(value: string) {
    if (this._src !== value) {
      this._src = value;
      if (this.initialized) {
        this.loadImage(value);
      }
    }
  }
  get src(): string {
    return this._src;
  }

  private spinnerRef: ComponentRef<SpinnerComponent> | null = null;
  private eventListeners: (() => void)[] = [];
  private initialized = false;
  private imageLoaded = false;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
    private vcr: ViewContainerRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.setupEventListeners();
    this.initialized = true;
    if (this.src) {
      this.loadImage(this.src);
    }
  }

  ngOnDestroy() {
    this.removeEventListeners();
    this.removeSpinner();
  }

  private setupEventListeners() {
    const imgElement = this.el.nativeElement;
    const events = ['load', 'error', 'abort'];

    events.forEach((eventName) => {
      const listener = this.renderer.listen(imgElement, eventName, (event) => {
        this.ngZone.run(() => this.handleImageEvent(eventName, event));
      });
      this.eventListeners.push(listener);
    });
  }

  private removeEventListeners() {
    this.eventListeners.forEach((removeListener) => removeListener());
    this.eventListeners = [];
  }

  private loadImage(src: string) {
    this.imageLoaded = false;
    this.showSpinner();

    // Use renderer to set src to ensure change detection
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);

    // Check if the image is already cached
    if (this.el.nativeElement.complete) {
      this.onImageLoad();
    } else {
      // Set a timeout to check if the image has loaded after a short delay
      // setTimeout(() => {
      //   if (!this.imageLoaded) {
      //     this.onImageLoad();
      //   }
      // }, 50); // Adjust this delay as needed
    }
  }

  private handleImageEvent(eventName: string, event: Event) {
    switch (eventName) {
      case 'load':
        this.onImageLoad();
        break;
      case 'error':
        this.onImageError();
        break;
      case 'abort':
        this.onImageAbort();
        break;
    }
  }

  private onImageLoad() {
    this.imageLoaded = true;
    this.removeSpinner();
    this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
  }

  private onImageError() {
    this.imageLoaded = true;
    this.removeSpinner();
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    this.renderer.setAttribute(
      this.el.nativeElement,
      'alt',
      'Failed to load image',
    );
  }

  private onImageAbort() {
    this.imageLoaded = true;
    this.removeSpinner();
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
  }

  private showSpinner() {
    if (!this.spinnerRef) {
      this.spinnerRef = this.vcr.createComponent(SpinnerComponent);
    }
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
  }

  private removeSpinner() {
    if (this.spinnerRef) {
      this.spinnerRef.destroy();
      this.spinnerRef = null;
    }
  }
}
