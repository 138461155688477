<ng-container *ngIf="match().source as source">
  <div
    [ngClass]="{
      'uj-pr-d': source.platform,
      'uj-pr-b': !source.platform,
    }"
    class="uj-flex uj-items-center uj-flex-1 uj-py-c uj-pl-d uj-border uj-rounded-b uj-border-border">
    <div class="uj-flex uj-flex-col uj-flex-grow">
      <span class="text-m uj-flex uj-gap-b">
        @if (source.platform) {
          <img
            [src]="platformsIconMap[source.platform]"
            alt="Icon Description"
            class="uj-w-e uj-h-e" />
          {{ "JobApplicationSourcePlatformEnum." + source.platform | i18next }}
        } @else {
          {{ "MatchSourceTypeEnum." + source.sourceType | i18next }}
        }
      </span>
    </div>
    <div>
      <span class="text-m uj-text-grey-300">
        {{ match().createdAt | date: "dd.MM.yyyy - HH:mm" }}</span
      >
    </div>
  </div>
</ng-container>
