import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChildren,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ContextMenuModule,
  ResponsiveService,
  setTimeout$,
  SheetModule,
  TooltipModule,
} from '@intemp/unijob-ui';
import {
  AccordionComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
} from '@intemp/unijob-ui2';
import { environment } from '../../../../environments/environment';
import { randomId } from '../../helpers/functions/randomId';
import { logCustomError } from '../../helpers/functions/logError';
import { TalentSheetService } from './services/talent-sheet.service';
import { TalentSheetHeaderComponent } from './talent-sheet-header/talent-sheet-header.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { TalentPersonalDataComponent } from './talent-personal-data/talent-personal-data.component';
import { TalentFormEditService } from './services/talentFormEdit.service';
import { AnchorNavigationModule } from '../anchor-navigation/anchor-navigation.module';
import { TalentTabEnum } from '../../../pages/talents/talent.types';
import { AddSpacesToIbanPipe } from '../../helpers/pipes/addSpacesToIban.pipe';
import { AnchorPoint } from '../anchor-navigation/anchor-navigation.component';
import { GlobalSheetsService } from '../global-sheets/global-sheets.service';
import { TalentEmailPhoneService } from './services/talent-email-phone.service';
import { TalentPrivacyPolicyService } from './services/talent-privacy-policy.service';
import { TalentSharedService } from './services/talent-shared.service';
import { TalentAvailabilityComponent } from './talent-availability/talent-availability.component';
import { TalentContactComponent } from './talent-contact/talent-contact.component';
import { TalentDataProtectionComponent } from './talent-data-protection/talent-data-protection.component';
import { TalentDocumentPreviewComponent } from './talent-document-preview/talent-document-preview.component';
import { TalentDocumentsComponent } from './talent-documents/talent-documents.component';
import { TalentInternComponent } from './talent-intern/talent-intern.component';
import { TalentPreferencesComponent } from './talent-preferences/talent-preferences.component';
import { sleep } from '@libs/shared/helpers/sleep';
import { TalentActionMenuComponent } from '../../../pages/talents/talent-action-menu/talent-action-menu.component';
import { OverlayWrapperComponent } from '../overlay-wrapper/overlay-wrapper.component';
import { VacancyWebsitePreviewComponent } from '../vacancy-detail/vacancy-website-preview/vacancy-website-preview.component';

@Component({
  standalone: true,
  selector: 'app-talent-sheet',
  templateUrl: './talent-sheet.component.html',
  imports: [
    SheetModule,
    AsyncPipe,
    TalentSheetHeaderComponent,
    NgIf,
    SharedDefaultModule,
    TalentPersonalDataComponent,
    AnchorNavigationModule,
    TalentContactComponent,
    TalentAvailabilityComponent,
    TalentPreferencesComponent,
    TalentDataProtectionComponent,
    TalentInternComponent,
    TalentDocumentsComponent,
    TalentDocumentPreviewComponent,
    ButtonOutlineComponent,
    ButtonSolidComponent,
    AccordionComponent,
    TooltipModule,
    ContextMenuModule,
    TalentActionMenuComponent,
    OverlayWrapperComponent,
    VacancyWebsitePreviewComponent,
  ],
  providers: [
    TalentSheetService,
    TalentFormEditService,
    AddSpacesToIbanPipe,
    TalentEmailPhoneService,
    TalentSharedService,
    TalentPrivacyPolicyService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentSheetComponent implements AfterViewInit {
  @Input() sheetId = 'talent-edit' + randomId();
  @Input() set talentId(value: string) {
    if (value) {
      this.service.talentId$.next(value);
    }
  }

  @Output() sheetClosed = new EventEmitter<string>();
  @ViewChildren(AccordionComponent) accordions?: AccordionComponent[];

  openPreview() {
    this.globalSheetsService.updateParam(this.sheetId, 'action', 'preview');
  }

  closePreview() {
    this.globalSheetsService.updateParam(this.sheetId, 'action', null);
  }

  openSheetAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId,
    );
    return sheet?.action;
  });

  activeTab = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId,
    );
    return sheet?.tab ?? TalentTabEnum.PROFILE;
  });

  openTalentPreview = computed(() => {
    return this.openSheetAction() === 'preview';
  });

  openSheets = toSignal(this.globalSheetsService.openSheets$, {
    initialValue: [],
  });
  openSheetOverlayAction = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId,
    );
    return sheet?.overlayAction;
  });

  openSheetOverlayUuid = computed(() => {
    const sheet = this.openSheets().find(
      (sheet) => sheet.sheetId === this.sheetId,
    );
    return sheet?.overlayUuid;
  });

  isSmDown$ = this.responsiveService.isSmDown$;
  isLgDown = toSignal(this.responsiveService.isLgDown$, {
    initialValue: false,
  });

  openOverlayOnSmallScreen = computed<boolean>(() => {
    return this.isLgDown() && this.openTalentPreview();
  });

  constructor(
    public service: TalentSheetService,
    private responsiveService: ResponsiveService,
    private globalSheetsService: GlobalSheetsService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    if (!this.service.talentId$.value) {
      logCustomError('missing VacancyId on vacancy-edit sheet');
    }
    setTimeout$(() => {
      this.openSheet();
    });
  }

  openSheet() {
    this.service.open(this.sheetId);
    this.service.subscribeTalent();
  }

  handleSheetClosed(slideId: string): void {
    this.sheetClosed.emit(slideId);
  }

  public openAccordions = signal<{ [key: string]: boolean }>({
    personalDataAccordion: true,
    contactAccordion: true,
    availabilityAccordion: true,
    preferencesAccordion: true,
    dataProtectionAccordion: true,
    internAccordion: true,
  });

  setAccordionOpen(anchor: string, open: boolean) {
    this.openAccordions.set({
      ...this.openAccordions(),
      [anchor]: open,
    });
  }

  openAccordionIfCollapsed = async (anchor: AnchorPoint) => {
    const openAccordions = this.openAccordions();
    if (!openAccordions[anchor.id]) {
      this.setAccordionOpen(anchor.id, true);
      await sleep(150);
    }
  };

  protected readonly tabEnum = TalentTabEnum;
  protected readonly environment = environment;
}
