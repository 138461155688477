<div
  [formGroup]="personalDataForm"
  class="uj-flex uj-flex-col uj-w-full uj-gap-f">
  <div class="uj-flex uj-w-full uj-gap-f">
    <div *ngIf="formService.talent() as talent">
      <app-talent-avatar-input
        [talentUuid]="talent.uuid"
        formControlName="avatar"></app-talent-avatar-input>
      <div
        *ngIf="talent.avatar?.mediaObject?.createdAt as createdAt"
        class="uj-text-center uj-text-text-s uj-text-text-grey">
        {{ createdAt | date: "dd.MM.yyyy" }}
      </div>
    </div>
    <div class="uj-grow">
      <div class="uj-flex uj-flex-col uj-gap-c">
        <!-- sex -->
        <ui-library-form-field
          [label]="'speech' | i18next | firstUppercase"
          [labelFor]="'sex' + componentId"
          required
          fullWidth>
          <unijob-select
            [id]="'sex' + componentId"
            showInvalidState="true"
            [options]="salutationOptions"
            size="size-m"
            [placeholder]="'choose' | i18next"
            elevation="box-outline"
            formControlName="sex"
            focusTracker="sex"
            bgColor="bg-white">
          </unijob-select>
        </ui-library-form-field>
        <!-- firstName -->
        <ui-library-form-field
          [label]="'firstName' | i18next | firstUppercase"
          [labelFor]="'firstName-' + componentId"
          required
          fullWidth>
          <input
            ui-library-text-input
            [id]="'firstName' + componentId"
            focusTracker="firstName"
            formControlName="firstName"
            [placeholder]="'firstName' | i18next"
            type="text" />
        </ui-library-form-field>
        <!-- lastName -->
        <ui-library-form-field
          [label]="'lastName' | i18next | firstUppercase"
          [labelFor]="'lastName-' + componentId"
          required
          fullWidth>
          <input
            ui-library-text-input
            [id]="'lastName' + componentId"
            focusTracker="lastName"
            formControlName="lastName"
            [placeholder]="'lastName' | i18next"
            type="text" />
        </ui-library-form-field>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!environment.hideBetaTalentFields">
    <div class="uj-flex uj-w-full uj-gap-c">
      <ui-library-form-field
        fullWidth
        [label]="'dateOfBirth' | i18next | firstUppercase"
        [labelFor]="'dateOfBirth' + componentId">
        <unijob-date-picker
          [id]="'dateOfBirth' + componentId"
          formControlName="dateOfBirth"
          focusTracker="dateOfBirth"
          mode="single"
          format="d.m.Y"
          [placeholder]="'dateOfBirth' | i18next"
          [showInvalidState]="true">
        </unijob-date-picker>
      </ui-library-form-field>
      <ui-library-form-field
        [label]="'placeOfBirth' | i18next | firstUppercase"
        [labelFor]="'placeOfBirth' + componentId"
        fullWidth>
        <input
          ui-library-text-input
          [id]="'placeOfBirth' + componentId"
          focusTracker="placeOfBirth"
          formControlName="placeOfBirth"
          [placeholder]="'placeOfBirth' | i18next"
          type="text" />
      </ui-library-form-field>
    </div>

    <ui-library-form-field [label]="'children' | i18next | firstUppercase">
      <app-talent-children />
    </ui-library-form-field>
    <!-- nationality -->
    <app-talent-nationality />

    <!-- driversLicense -->
    <div class="uj-flex uj-flex-col uj-gap-c">
      <ui-library-form-field
        fullWidth
        [label]="'driversLicense' | i18next | firstUppercase"
        [labelFor]="'driversLicense' + componentId">
        <unijob-select
          size="size-m"
          multiselect
          [options]="driversLicenseOptions"
          [placeholder]="'chooseCategory' | i18next"
          elevation="box-outline"
          [formControl]="formService.driversLicense"
          bgColor="bg-white">
        </unijob-select>
      </ui-library-form-field>
      <unijob-checkbox
        formControlName="hasVehicle"
        [label]="'vehicleAvailable' | i18next"></unijob-checkbox>
    </div>

    <!-- religiousDenomination -->
    <div class="uj-flex uj-flex-col uj-gap-c">
      <ui-library-form-field
        fullWidth
        [label]="'religiousDenomination' | i18next | firstUppercase"
        [labelFor]="'religiousDenomination' + componentId">
        <unijob-select
          size="size-m"
          [options]="religiousDenominationOptions"
          [placeholder]="'chooseReligiousDenomination' | i18next"
          elevation="box-outline"
          formControlName="religiousDenomination"
          bgColor="bg-white">
        </unijob-select>
      </ui-library-form-field>
      <ui-library-form-field
        fullWidth
        [label]="'maritalStatus' | i18next | firstUppercase"
        [labelFor]="'maritalStatus' + componentId">
        <unijob-select
          formControlName="maritalStatus"
          size="size-m"
          [options]="maritalStatusOptions"
          [placeholder]="'chooseMaritalStatus' | i18next"
          elevation="box-outline"
          bgColor="bg-white">
        </unijob-select>
      </ui-library-form-field>
    </div>

    <!-- ahvNumber -->
    <app-talent-ahv-number />

    <!-- iban -->
    <div class="uj-flex uj-flex-col uj-gap-d">
      <ui-library-form-field
        fullWidth
        [hint]="ibanControl.errors?.message.key | i18next | firstUppercase"
        [label]="'iban' | i18next | firstUppercase"
        [labelFor]="'iban' + componentId">
        <input
          ui-library-text-input
          [id]="'iban' + componentId"
          [imask]="ibanMask"
          [formControl]="ibanControl"
          [feedback]="ibanControl.errors ? 'invalid' : 'none'"
          focusTracker="iban"
          placeholder="CHXX XXXX XXXX XXXX XXXX X"
          type="text" />
      </ui-library-form-field>
    </div>
  </ng-container>
</div>
