import { Component, computed, inject, input, OnInit } from '@angular/core';
import { MatchFragment, MatchStatusEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';
import { UserService } from '../../../../models/shared/user/user.service';
import { GlobalSheetsService } from '../../global-sheets/global-sheets.service';
import { MatchSheetService } from '../services/match-sheet.service';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { CurrentUserAvatarComponent } from '../../../components/current-user-avatar/current-user-avatar.component';
import {
  ButtonLinkComponent,
  DotComponent,
  IconTagComponent,
} from '@intemp/unijob-ui2';
import { getLastEditTime } from '../../../helpers/functions/getLastEditTime';
import { MatchesListActions } from '../../../../state/matches-list/matches-list.actions';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'app-match-sheet-header',
  templateUrl: './match-sheet-header.component.html',
  imports: [
    SharedDefaultModule,
    CurrentUserAvatarComponent,
    DotComponent,
    ButtonLinkComponent,
    IconTagComponent,
  ],
  providers: [],
})
export class MatchSheetHeaderComponent implements OnInit {
  service = inject(MatchSheetService);
  userService = inject(UserService);
  i18nPipe = inject(I18NextPipe);
  globalSheetsService = inject(GlobalSheetsService);
  store = inject(Store);

  match = input.required<MatchFragment>();
  talentName = computed(() => {
    const match = this.match();
    return (
      'Match' +
      ' ' +
      match.talent.firstName +
      ' ' +
      match.talent.lastName +
      ' + ' +
      match.company?.companyLocation?.companyReference.legalName
    );
  });

  lastUpdatedForUserAt = computed(() => {
    return getLastEditTime(this.match().updatedForUserAt);
  });

  protected readonly MatchStatusEnum = MatchStatusEnum;

  ngOnInit() {
    this.store.dispatch(
      MatchesListActions.updateMatchStatusIfUnread({
        match: this.match(),
      }),
    );
  }
}
