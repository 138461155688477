<div
  class="uj-flex uj-flex-col uj-gap-f"
  *ngIf="matchSheetService.match$ | async as match">
  <ui-library-form-field
    fullWidth
    [label]="'rating' | i18next">
    @if (match.rating) {
      <app-rating-stars [rating]="match.rating"></app-rating-stars>
    } @else {
      <span class="title-m-b font-bold uj-text-text-secondary-light">{{
        "noRating" | i18next
      }}</span>
    }
  </ui-library-form-field>
  <div>
    <app-match-parties [match]="match" />
  </div>
  <div>
    <ui-library-form-field
      fullWidth
      [label]="'source' | i18next">
      <app-match-source [match]="match"></app-match-source>
    </ui-library-form-field>
  </div>
</div>
