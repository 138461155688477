import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  QueryList,
  signal,
  ViewChildren,
} from '@angular/core';
import { InfoBoxModule } from '@intemp/unijob-ui';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  IconComponent,
  TagComponent,
} from '@intemp/unijob-ui2';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { environment } from '../../../../../environments/environment';
import {
  ArrayActionEnum,
  TalentFragment,
  TalentUpdateGQL,
} from '../../../../graphql/generated';
import { GlobalSheetsService } from '../../global-sheets/global-sheets.service';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { VisibilityCheckDirective } from './visibility-check.directive';
@Component({
  standalone: true,
  selector: 'app-talent-document-preview',
  templateUrl: './talent-document-preview.component.html',
  styleUrls: ['./talent-document-preview.component.scss'],
  imports: [
    I18NextModule,
    SharedDefaultModule,
    IconComponent,
    ButtonTextComponent,
    InfoBoxModule,
    ButtonSolidComponent,
    ButtonOutlineComponent,
    PdfViewerModule,
    TagComponent,
    VisibilityCheckDirective,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentDocumentPreviewComponent implements OnDestroy, OnInit {
  globalSheetsService = inject(GlobalSheetsService);

  private elementRef = inject(ElementRef);
  private viewPortCheckInterval: any;
  constructor(
    private talentUpdateGql: TalentUpdateGQL,
    private cdr: ChangeDetectorRef,
  ) {
    effect(() => {
      const uuid = this.uuid();
      if (uuid) {
        this.scrollToImage(uuid);
      }
    });
  }

  ngOnInit(): void {
    this.viewPortCheckInterval = setInterval(() => {
      this.checkViewport();
    }, 250);
  }

  ngOnDestroy(): void {
    this.globalSheetsService.documentsPreviewActivePage.set('');
    clearInterval(this.viewPortCheckInterval);
  }

  isCovered = signal<boolean>(false);

  handleCoverageChange(isCovered: boolean) {
    this.isCovered.set(isCovered);

    // became covered -> reset active page
    if (isCovered) {
      this.globalSheetsService.documentsPreviewActivePage.set('');
    }
  }

  readonly showHeader = input<boolean>(true);
  readonly uuid = input<string | undefined>(undefined);
  readonly mode = input<string | undefined>(undefined);
  readonly talent = input.required<TalentFragment>();
  readonly shownPages = computed(() => {
    switch (this.mode()) {
      case 'all':
        return this.talent().pages;
      case 'favorite':
        return this.talent().pages.filter((page) => page.favorite);
      case 'inactive':
        return this.talent().pages.filter((page) => !page.active);
      case 'active':
      default:
        return this.talent().pages.filter((page) => page.active);
    }
  });
  loadedPdfs = signal(new Set<string>());
  loadedAndReadyPdfs = signal(new Set<string>());

  @ViewChildren('lazyImage') imageElements!: QueryList<ElementRef>;

  private scrollToImage(pageUuid: string) {
    const imageElement = this.imageElements.find((element) => {
      return element.nativeElement.getAttribute('data-page-uuid') === pageUuid;
    });
    if (!imageElement) {
      return;
    }
    const farToScroll =
      Math.abs(
        imageElement.nativeElement.getBoundingClientRect().top -
          window.innerHeight / 2,
      ) > 4000;
    imageElement.nativeElement.scrollIntoView({
      behavior: farToScroll ? 'instant' : 'smooth',
    });
  }

  i18next = inject(I18NextPipe);
  public getDocumentPreviewSrc(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    const avatarSrcPath = page.mediaObject.sizes
      ?.find((size) => size.name === 'proxy')
      ?.src.replace(':talentUuid', this.talent().uuid);
    return avatarSrcPath ? environment.mediaUrl + avatarSrcPath : '';
  }

  public getDocumentDownloadSrc(
    page: TalentFragment['pages'][number] | TalentFragment['files'][number],
  ) {
    return (
      environment.mediaUrl +
        page.mediaObject.downloadUrl?.replace(
          ':talentUuid',
          this.talent().uuid,
        ) || ''
    );
  }

  public loadPdf(page: TalentFragment['pages'][number]) {
    const updatedSet = this.loadedPdfs();
    updatedSet.add(page.uuid);
    this.loadedPdfs.set(updatedSet);
    this.cdr.markForCheck();
  }

  public markLoadedPdfReady(page: TalentFragment['pages'][number]) {
    // Loading may take longer than the pdf-preview component indicates, because things such as image tags from the pdf load separately afterwards
    setTimeout(() => {
      const updatedSet = this.loadedAndReadyPdfs();
      updatedSet.add(page.uuid);
      this.loadedAndReadyPdfs.set(updatedSet);
      this.cdr.markForCheck();
    }, 500);
  }

  public downloadStatus = signal<{
    progress: number;
    errorMessage?: string;
    src: string;
  }>({
    progress: 0,
    src: '',
    errorMessage: undefined,
  });

  markRead(page: TalentFragment['pages'][number]) {
    this.talentUpdateGql
      .mutate({
        input: {
          uuid: this.talent().uuid,
          pages: [
            {
              uuid: page.uuid,
              type: ArrayActionEnum.CHANGED,
              read: true,
            },
          ],
        },
      })
      .subscribe();
  }

  checkViewport = () => {
    if (this.isCovered() === true) {
      return false;
    }

    const pagesElements = this.elementRef.nativeElement.querySelectorAll(
      '.pages > .page',
    ) as Element[];

    //find the page that is most visible in the viewport
    let maxVisibleArea = 0;
    let mostVisiblePage: Element | null = null;
    pagesElements.forEach((pageElement) => {
      const rect = pageElement.getBoundingClientRect();
      const visibleArea =
        Math.min(rect.bottom, window.outerHeight) - Math.max(rect.top, 0);
      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = pageElement;
      }
    });

    if (!mostVisiblePage) {
      return;
    }

    const pageUuid = (mostVisiblePage as Element).id;
    this.globalSheetsService.documentsPreviewActivePage.set(pageUuid);
    const page = this.talent().pages.find((page) => page.uuid === pageUuid);

    // mark page as read
    if (page && page.read === false) {
      this.markRead(page);
    }

    // load the pdf
    if (page && !this.loadedPdfs().has(page.uuid)) {
      this.loadPdf(page);
    }
  };
}
