import { Component, inject, input, OnInit } from '@angular/core';
import {
  ContextMenuModule,
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  ResponsiveService,
  SwitchModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import {
  ArrayActionEnum,
  ContactEmailTypeEnum,
} from '../../../../../../graphql/generated';
import { YearToAgePipe } from '../../../../../helpers/pipes/yearToAge.pipe';
import { randomId } from '../../../../../helpers/functions/randomId';
import { getEmailOptions } from '../../../helpers/getEmailOptions';
import { TalentEmailPhoneService } from '../../../services/talent-email-phone.service';
import { isValidEmail } from '../../../../shared-forms/customValidators';
import { EmailFormGroup } from '../../../talent-sheet.types';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import { filter } from 'rxjs/operators';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ButtonTextComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';
import { ConfirmationModalModule } from '../../../../confirmation-modal/confirmation-modal.module';

@Component({
  standalone: true,
  selector: 'app-talent-emails-entry',
  templateUrl: './talent-emails-entry.component.html',
  imports: [
    AvatarComponent,
    DatePickerModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    YearToAgePipe,
    UjSelectInputModule,
    ContextMenuModule,
    InfoBoxModule,
    ButtonOutlineComponent,
    ButtonTextComponent,
    ButtonSolidComponent,
    ModalComponent,
    FormFieldComponent,
    TextInputComponent,
    SimpleSelectComponent,
    ConfirmationModalModule,
  ],
})
export class TalentEmailsEntryComponent implements OnInit {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentEmailPhoneService);
  i18next = inject(I18NextPipe);
  toastService = inject(ToastService);
  responsiveService = inject(ResponsiveService);
  modals = inject(ModalService);

  emailsFormArray = this.formService.emailsFormArray;

  isFirstEntry = input(false);
  isNewEntry = input(false);
  emailGroup = input.required<FormGroup<EmailFormGroup>>();

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();

  emailTypeOptions = getEmailOptions();

  isSmDown$ = this.responsiveService.isSmDown$;

  showInputError = false;
  componentId = randomId();

  ngOnInit() {
    this.emailGroup()
      .controls.emailType.valueChanges.pipe(
        filter(() => {
          return this.emailGroup().controls.emailType.dirty;
        }),
      )
      .subscribe(() => {
        this.updateEmail(this.emailGroup(), ArrayActionEnum.CHANGED);
      });
  }

  setPrimaryEmail() {
    this.sharedService.setPrimaryEntry(
      this.emailGroup(),
      this.emailsFormArray,
      'emails',
      (newPrimaryUuid) => {
        this.sharedService.createToast(ArrayActionEnum.CHANGED);
        this.toastService.makeToast({
          type: 'INFO',
          message: this.i18next.transform('primaryEmailUpdated'),
          duration: 6000,
        });
        this.formService.setPrimaryEmailFirst(newPrimaryUuid);
      },
    );
  }

  deletePrimaryEmail() {
    this.sharedService.deletePrimaryEntry(
      this.emailGroup(),
      this.emailsFormArray.at(1),
      this.emailsFormArray,
      'emails',
      () => {
        this.sharedService.createToast(ArrayActionEnum.REMOVED);
        this.toastService.makeToast({
          type: 'INFO',
          message: this.i18next.transform('primaryEmailUpdated'),
          duration: 6000,
        });
      },
    );

    this.modals.close(this.deleteConfirmationModalId);
  }

  openDeleteConfirmationModal() {
    this.modals.open(this.deleteConfirmationModalId);
  }

  deleteEmail() {
    const isPrimary = this.emailGroup().controls.isPrimary.value;
    if (isPrimary && this.emailsFormArray.length > 1) {
      this.deletePrimaryEmail();
    } else {
      this.updateEmail(this.emailGroup(), ArrayActionEnum.REMOVED);
      this.emailsFormArray.removeAt(
        this.emailsFormArray.controls.indexOf(this.emailGroup()),
      );
    }
  }

  addNewEmail() {
    this.updateEmail(this.emailGroup(), ArrayActionEnum.ADDED);
    this.emailGroup().reset({
      uuid: randomId(),
      type: ArrayActionEnum.ADDED,
      email: null,
      isPrimary: false,
      emailType: ContactEmailTypeEnum.PRIVATE,
    });
  }

  onEmailChange(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(/\s/g, '');
    this.emailGroup().controls.email.setErrors(null);

    const isValid = isValidEmail(value);
    const alreadyExists = this.formService
      .talent()
      ?.emails.some((v) => v.email === value);
    if (!isValid) {
      this.emailGroup().controls.email.setErrors({
        message: {
          key: 'invalidEmail',
        },
      });
      this.showInputError = true;
    } else if (alreadyExists) {
      this.emailGroup().controls.email.setErrors({
        message: {
          key: 'emailAlreadyExists',
        },
      });
      this.showInputError = true;
    } else {
      this.showInputError = false;
    }

    if (!isValid || alreadyExists) return;

    if (this.isNewEntry()) {
      this.addNewEmail();
    } else {
      this.updateEmail(this.emailGroup(), ArrayActionEnum.CHANGED);
    }
  }

  updateEmail(
    childControl: FormGroup<EmailFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const emailsData = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !emailsData) {
      console.error('Talent uuid is not valid');
      return;
    }
    emailsData.email = emailsData.email.replace(/\s/g, '');
    emailsData.type = arrayAction;

    if (arrayAction === ArrayActionEnum.ADDED) {
      const currentEmails = this.formService.talent()?.emails ?? [];
      if (currentEmails.length === 0) {
        emailsData.isPrimary = true;
      }

      this.formService.addNewEmail({ ...emailsData });
    }

    this.formService
      .updateTalentApi({
        emails: [emailsData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
