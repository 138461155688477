import { effect, inject, Injectable, signal } from '@angular/core';
import {
  MatchArchiveReasonEnum,
  MatchFragment,
  MatchProcessStepEnum,
  MatchUpdateGQL,
  MatchUpdateInput,
} from '../../../../graphql/generated';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { filter } from 'rxjs/operators';
import { getProcessStepOptions } from '../helpers/getProcessStepOptions';
import { getMatchArchiveReasonsTextOptions } from '../helpers/getMatchArchiveReasonsTextOptions';

@Injectable()
export class MatchSheetFormService {
  toastService = inject(ToastService);
  i18next = inject(I18NextPipe);
  matchUpdateGQL = inject(MatchUpdateGQL);

  responsibleUserId = new FormControl<string | null>(null);
  internalRemarks = new FormControl<string | null>(null);
  processStep = new FormControl<MatchProcessStepEnum | null>(
    MatchProcessStepEnum.ENTRY,
  );
  processStepOptions = getProcessStepOptions();

  archiveReason = new FormControl<string | null>(
    MatchArchiveReasonEnum.WE_CANCELLED,
  );
  archiveReasonsOptions = getMatchArchiveReasonsTextOptions();

  match = signal<MatchFragment | null>(null);

  focusedFields = new BehaviorSubject<Partial<Record<string, boolean>>>({});

  constructor() {
    this.subscribeToFormChanges();
    effect(() => {
      if (!this.match()?.permissions?.canEdit) {
        this.responsibleUserId.disable();
        this.internalRemarks.disable();
        this.processStep.disable();
      } else {
        this.responsibleUserId.enable();
        this.internalRemarks.enable();
        this.processStep.enable();
      }
    });
  }

  subscribeToFormChanges() {
    this.responsibleUserId.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(
          () =>
            this.responsibleUserId.dirty &&
            this.responsibleUserId.value !==
              this.match()?.responsibleUser.userId,
        ),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('responsibleUserId', value);
      });

    this.internalRemarks.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(
          () =>
            this.internalRemarks.dirty &&
            this.internalRemarks.value !== this.match()?.internalRemarks,
        ),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('internalRemarks', value);
        this.internalRemarks.markAsPristine();
      });

    this.processStep.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(
          () =>
            this.processStep.dirty &&
            this.processStep.value !== this.match()?.processStep,
        ),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('processStep', value);
      });

    this.archiveReason.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(
          () =>
            this.archiveReason.dirty &&
            this.archiveReason.value !== this.match()?.archiveReason,
        ),
      )
      .subscribe((value) => {
        this.submitFieldApiUpdate('archiveReason', value);
      });
  }

  patchForm(match: MatchFragment) {
    this.responsibleUserId.patchValue(match.responsibleUser.userId, {
      emitEvent: false,
    });

    if (!this.focusedFields.value['internalRemarks']) {
      this.internalRemarks.patchValue(match.internalRemarks, {
        emitEvent: false,
      });
    }
    this.processStep.patchValue(match.processStep, {
      emitEvent: false,
    });
    this.archiveReason.patchValue(
      match.archiveReason ?? MatchArchiveReasonEnum.WE_CANCELLED,
      {
        emitEvent: false,
      },
    );
    this.match.set(match);
  }

  submitFieldApiUpdate(key: keyof MatchUpdateInput, value: any) {
    const uuid = this.match()?.uuid;
    if (!uuid) throw new Error('Match UUID is missing');
    this.matchUpdateGQL
      .mutate({
        input: {
          [key]: value,
          uuid,
        },
      })
      .subscribe({
        next: () => {
          if (key === 'processStep') {
            this.toastService.makeToast({
              type: 'SUCCESS',
              message: this.i18next.transform('movedToProcessStep', {
                processStep: this.i18next.transform(
                  'MatchProcessStepEnum.' + value,
                ),
              }),
            });
          }
        },
      });
  }
}
